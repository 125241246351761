import React from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { listSshKeys, deleteSshKey } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { sshKeysSelector } from './selectors'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { routes } from 'core/utils/routes'
import ImportSshKeyModal from './ImportSshKeyModal'
import DeleteSshKey from './DeleteSshKey'

type ModelDataKey = DataKeys.SshKeys
type SelectorModel = ArrayElement<ReturnType<typeof sshKeysSelector>>
type ActionParams = InferActionParams<typeof listSshKeys>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('SshKeys', listTablePrefs)

const searchTargets = ['name', 'fingerprint']

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'fingerprint',
    label: 'Fingerprint',
  },
]

export default function FlavorsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listSshKeys, {
    params,
  })
  const data = useAppSelector(sshKeysSelector)

  return (
    <>
      <DocumentMeta title="SSH Keys" />
      <ImportSshKeyModal addRoute={routes.openstack.importSshKey} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.SshKeys}
        searchTargets={searchTargets}
        uniqueIdentifier="name"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.openstack.importSshKey.path()}
        addText="Import SSH Key"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteSshKey}
        DeleteDialogComponent={DeleteSshKey}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
