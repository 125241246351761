import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'

const { kaapi } = ApiClient.getInstance()

export const kaapiKubeadmConfigTemplatesActions = ActionsSet.make<DataKeys.KubeadmConfigTemplates>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KubeadmConfigTemplates],
  cacheKey: DataKeys.KubeadmConfigTemplates,
})

export const listkaapiKubeadmConfigTemplates = kaapiKubeadmConfigTemplatesActions.add(
  new ListAction<DataKeys.KubeadmConfigTemplates, { namespace: string }>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to list Kaapi KubeadmConfigTemplates', { namespace })
    return kaapi.getKubeadmConfigTemplate({ namespace })
  }),
)

export const deleteKaapiKudeadmConfigTemplates = kaapiKubeadmConfigTemplatesActions.add(
  new DeleteAction<DataKeys.KubeadmConfigTemplates, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Kaapi KubeadmConfigTemplates', {
        namespace,
        name,
      })
      await kaapi.deleteKubeadmConfigTemplate({ namespace, name })
    },
  ),
)

export const createKaapiKubeadmConfigTemplate = kaapiKubeadmConfigTemplatesActions.add(
  new CreateAction<DataKeys.KubeadmConfigTemplates, { namespace: string; body: any }>(
    async ({ namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Kaapi KubeadmConfigTemplate', {
        namespace,
        body,
      })
      const created = await kaapi.createKubeadmConfigTemplate(namespace, body)
      trackEvent('Create New Kaapi KubeadmConfigTemplate', {
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)
