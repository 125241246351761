import { makeStyles } from '@material-ui/styles'
import UserMenu from 'core/components/UserMenu'
import { GlobalParams } from 'core/hooks/useGlobalParams'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { Route } from 'core/plugins/route'
import FrameContext from 'core/providers/frame-provider'
import Theme from 'core/themes/model'
import React, { useEffect } from 'react'
import GlobalTenantDropdown from './GlobalTenantDropdown'
import RegionDropdown from 'core/components/RegionDropdown'

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    backgroundColor: theme.components.header.background,
    height: 64,
    gap: 16,
    padding: '0px 32px',
    display: 'grid',
    gridTemplateColumns: 'minmax(200px, 1fr) max-content max-content',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    gap: 16,
  },
  leftMargin: {
    marginLeft: 28,
  },
  listActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const headerTitleRef = React.createRef<HTMLDivElement>()
// const headerActionRef = React.createRef<HTMLDivElement>()
const headerDefaultToolsRef = React.createRef<HTMLDivElement>()

const useGlobalParams = createUsePrefParamsHook<GlobalParams>('globalParams', [
  'clusterId',
  'namespace',
  'tenant',
])

export default function Header() {
  const classes = useStyles({})
  const { params, getParamsUpdater } = useGlobalParams({
    clusterId: [],
    namespace: [],
    tenant: [],
  })
  const route = Route.getCurrentRoute()
  const { setFrameContainerRef } = React.useContext(FrameContext)
  const { currentPluginId } = usePluginRouter()

  useEffect(() => {
    setFrameContainerRef({
      headerTitleContainer: headerTitleRef.current,
      // headerPrimaryActionContainer: headerActionRef.current,
      headerSharedToolsContainer: headerDefaultToolsRef.current,
    })
  }, [])

  return (
    <div>
      <header className={classes.header}>
        <div className={classes.content} ref={headerTitleRef} />
        {/* <div className={classes.content} ref={headerActionRef} /> */}
        <div className={classes.content} ref={headerDefaultToolsRef}>
          <RegionDropdown />
          <GlobalTenantDropdown />
          {/* <NotificationsMenu hideDropdown={false} /> */}
          {/* {route?.metadata?.showGlobalFilters ? (
            // Todo: Add route metadata to add parameters to GlobalMultiClusterDropdown
            // to only get luigi clusters for example
            <>
              <GlobalMultiClusterDropdown
                onChange={getParamsUpdater('clusterId')}
                value={params.clusterId}
                onlyKubevirtClusters={currentPluginId === 'kubevirt'}
              />
              <GlobalMultiNamespaceDropdown
                clusters={params.clusterId}
                onChange={getParamsUpdater('namespace')}
                value={params.namespace}
              />
            </>
          ) : null} */}
          <UserMenu />
        </div>
      </header>
      {/* <div className={classes.listActions}>
        <div className={classes.content} ref={headerActionRef} />
      </div> */}
    </div>
  )
}
