import ApiService from 'api-client/ApiService'
import { Host } from './resmgr.model'
import { trackApiMethodMetadata } from './helpers'
import { HostNetworkConfiguration } from 'openstack/components/infrastructure/blueprint/model'

class ResMgr extends ApiService {
  hasEndpoint

  public getClassName() {
    return 'resmgr'
  }

  static apiMethodsMetadata = []

  protected async getEndpoint() {
    const endpoint = await this.client.keystone.getServiceEndpoint('resmgr', 'public')
    this.hasEndpoint = endpoint ? true : false
    return `${endpoint}/v1`
  }

  endpointPresent() {
    return this.hasEndpoint
  }

  @trackApiMethodMetadata({ url: '/hosts', type: 'GET' })
  async getHosts() {
    const url = `/hosts`
    return this.client.basicGet<Host[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHosts',
      },
    })
  }

  @trackApiMethodMetadata({ url: '/hosts', type: 'GET' })
  async getResmgrHosts() {
    return this.client.basicGet<Host[]>({
      url: '/hosts',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHosts',
      },
    })
  }

  @trackApiMethodMetadata({ url: '/hosts/:id', type: 'GET' })
  async getResmgrHost(id) {
    return this.client.basicGet<Host>({
      url: `/hosts/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHost',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/hosts/:hostId/roles/:role',
    type: 'PUT',
    params: ['hostId', 'role'],
  })
  async addRole(hostId, role, body) {
    const url = `/hosts/${hostId}/roles/${role}`
    return this.client.basicPut({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'addRole',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/hosts/:hostId/roles/:role',
    type: 'DELETE',
    params: ['hostId', 'role'],
  })
  async removeRole(hostId, role): Promise<void> {
    const url = `/hosts/${hostId}/roles/${role}`
    await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'removeRole',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/hosts/:hostId/roles/:role',
    type: 'GET',
    params: ['hostId', 'role'],
  })
  async getRole<T>(hostId, role) {
    const url = `/hosts/${hostId}/roles/${role}`
    return this.client.basicGet<T>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getRole',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/hosts/:hostId',
    type: 'DELETE',
    params: ['hostId'],
  })
  async unauthorizeHost(id) {
    const url = `/hosts/${id}`
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'unauthorizeHost',
      },
    })
  }

  @trackApiMethodMetadata({ url: '/services/:service', type: 'GET', params: ['service'] })
  async getService(service) {
    const url = `/services/${service}`
    return this.client.basicGet({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getService',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/services/:service',
    type: 'PUT',
    params: ['service'],
  })
  async updateService(service, body) {
    const url = `/services/${service}`
    return this.client.basicPut({
      url,
      version: 'v2',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateService',
      },
    })
  }

  async getBlueprints() {
    const url = `/blueprint`
    return this.client.basicGet({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getBlueprints',
      },
    })
  }

  async getBlueprint({ name }) {
    const url = `/blueprint/${name}`
    return this.client.basicGet({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getBlueprint',
      },
    })
  }

  async createBlueprint({ body }) {
    const url = `/blueprint`
    return this.client.basicPost({
      url,
      version: 'v2',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createBlueprint',
      },
    })
  }

  async updateBlueprint({ name, body }) {
    const url = `/blueprint/${name}`
    return this.client.basicPut({
      url,
      version: 'v2',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateBlueprint',
      },
    })
  }

  async getHostNetworkConfigs() {
    const url = `/hostconfigs`
    return this.client.basicGet<HostNetworkConfiguration[]>({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHostNetworkConfigs',
      },
    })
  }

  async createHostNetworkConfigs(body) {
    const url = `/hostconfigs`
    return this.client.basicPost({
      url,
      version: 'v2',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createHostNetworkConfigs',
      },
    })
  }

  async updateHostNetworkConfig({ id, body }) {
    const url = `/hostconfigs/${id}`
    return this.client.basicPut({
      url,
      version: 'v2',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateHostNetworkConfig',
      },
    })
  }

  async deleteHostNetworkConfig(id) {
    const url = `/hostconfigs/${id}`
    await this.client.basicDelete({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteHostNetworkConfig',
      },
    })
  }

  async assignHypervisorRole({ id }) {
    const url = `/hosts/${id}/roles/hypervisor`
    return this.client.basicPut({
      url,
      version: 'v2',
      body: {},
      options: {
        clsName: this.getClassName(),
        mthdName: 'assignHypervisorRole',
      },
    })
  }

  async assignImageLibraryRole({ id }) {
    const url = `/hosts/${id}/roles/image-library`
    return this.client.basicPut({
      url,
      version: 'v2',
      body: {},
      options: {
        clsName: this.getClassName(),
        mthdName: 'assignImageLibraryRole',
      },
    })
  }

  async assignPersistentStorageRole({ id, body }) {
    const url = `/hosts/${id}/roles/persistent-storage`
    return this.client.basicPut({
      url,
      version: 'v2',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'assignImageLibraryRole',
      },
    })
  }

  async deauthorizeHypervisorRole({ id }) {
    const url = `/hosts/${id}/roles/hypervisor`
    await this.client.basicDelete({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'removeHypervisorRole',
      },
    })
  }

  async deauthorizeImageLibraryRole({ id }) {
    const url = `/hosts/${id}/roles/image-library`
    await this.client.basicDelete({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'removeImageLibraryRole',
      },
    })
  }

  async deauthorizePersistentStorageRole({ id }) {
    const url = `/hosts/${id}/roles/persistent-storage`
    await this.client.basicDelete({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'removePersistentStorageRole',
      },
    })
  }

  async getHostsv2() {
    const url = `/hosts`
    return this.client.basicGet<any[]>({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHostsv2',
      },
    })
  }

  async assignHostConfig({ hostId, configId }) {
    const url = `/hosts/${hostId}/hostconfig/${configId}`
    return this.client.basicPut({
      url,
      version: 'v2',
      body: {},
      options: {
        clsName: this.getClassName(),
        mthdName: 'assignHostConfiguration',
      },
    })
  }

  async unassignHostConfig({ hostId, configId }) {
    const url = `/hosts/${hostId}/hostconfig/${configId}`
    return this.client.basicDelete({
      url,
      version: 'v2',
      options: {
        clsName: this.getClassName(),
        mthdName: 'unassignHostConfiguration',
      },
    })
  }
}

export default ResMgr
