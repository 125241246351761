import React, { useCallback, useMemo, useState } from 'react'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { AddonManagerAddon, AddonTogglers } from './cluster-addons/cluster-addon-manager'
import Text from 'core/elements/Text'
import DocumentMeta from 'core/components/DocumentMeta'
import RadioFields from 'core/components/validatedForm/radio-fields'
import { isTransientStatus } from './ClusterStatusUtils'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ContainerRuntimePicklist from './form-components/ContainerRuntimePicklist'
import ModalForm from 'core/elements/modal/ModalForm'
import { compareVersions } from 'k8s/util/helpers'
import { listClusters, upgradeClusterNodes } from './newActions'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { clustersSelector } from './selectors'
import { useSelector } from 'react-redux'
import { UpgradeTypes } from 'app/plugins/infrastructure/components/clusters/model'
import { IconInfo } from 'core/components/validatedForm/Info'
import ExternalLink from 'core/components/ExternalLink'
import { runtimeMigrationArticleLink } from 'k8s/links'
import { Route } from 'core/plugins/route'
import { capitalizeString } from 'utils/misc'
import { listClusterVersions } from './cluster-addons/new-actions'
import { clusterVersionsSelector } from './cluster-addons/selectors'
import ClusterVersionIcon from './cluster-cells/ClusterVersionIcon'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  text: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function getUpgradeTarget(cluster) {
  if (cluster?.upgradingTo) {
    const isPatch = compareVersions(cluster?.upgradingTo, cluster?.patchUpgradeRoleVersion)
    return isPatch === 0 ? UpgradeTypes.Patch : UpgradeTypes.Minor
  }
  if (cluster?.canMinorUpgrade) {
    return UpgradeTypes.Minor
  }
  return UpgradeTypes.Patch
}

function getUpgradeVersion(cluster, upgradeType) {
  return cluster?.upgradingTo ? cluster?.upgradingTo : cluster?.[`${upgradeType}UpgradeRoleVersion`]
}

const clusterAddons: AddonManagerAddon[] = [
  { addon: 'sequentialClusterUpgrade' },
  { addon: 'percentageClusterUpgrade' },
  //  { addon: 'advancedBatchUpgrade' }, Commenting out as this is not supported for the release 5.5
]

const defaultAddonValues = {
  sequentialClusterUpgrade: false,
  percentageClusterUpgrade: false,
  advancedBatchUpgrade: false,
}

export const handleSetUpgradeStrategy = (field: any, id: any) => {
  const ret: any = {
    ...defaultAddonValues,
    [id]: field,
  }
  if (!ret.advancedBatchUpgrade) {
    ret.upgradedNodes = null
    ret.toBeUpgradedNodes = null
    ret.batchUpgradeNodes = null
  }
  return ret
}

const defaultRoute = routes.cluster.managed.qbert.upgrade

interface Props {
  route?: Route
  onClose?: () => void
}

export default function UpgradeClusterPage({ route = defaultRoute, onClose }: Props) {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const { loading: loadingClusters, message } = useListAction(listClusters)
  const { update: upgradeNodes, updating: upgradingClusterNodes, error } = useUpdateAction(
    upgradeClusterNodes,
  )
  const clusters = useSelector(clustersSelector)
  const cluster = clusters?.find((x) => x.uuid === match.params.id)

  const defaultUpgradeType = getUpgradeTarget(cluster)
  const defaultUpgradeVersion = getUpgradeVersion(cluster, defaultUpgradeType)

  const { loading: loadingClusterVersion } = useListAction(listClusterVersions)
  const clusterVersions = useSelector(clusterVersionsSelector)

  const initialState = {
    ...defaultAddonValues,
    sequentialClusterUpgrade: true,
    clusterId: cluster?.uuid || match.params.id,
    name: cluster?.name,
    upgradeVersion: defaultUpgradeVersion,
    upgradeType: defaultUpgradeType,
    masterNodes: cluster?.masterNodes || [],
    workerNodes: cluster?.workerNodes || [],
    upgradedNodes: null,
    toBeUpgradedNodes: null,
    batchUpgradeNodes: null,
    containerRuntime: cluster?.containerRuntime || 'docker',
  }

  const clusterVersionToType: Record<string, UpgradeTypes> = {}
  if (cluster?.minorUpgradeRoleVersion) {
    clusterVersionToType[cluster?.minorUpgradeRoleVersion] = UpgradeTypes.Minor
  }
  if (cluster?.patchUpgradeRoleVersion) {
    clusterVersionToType[cluster?.patchUpgradeRoleVersion] = UpgradeTypes.Patch
  }

  const isWorking = isTransientStatus(cluster?.taskStatus)

  const handleSubmit = useCallback(
    async (values) => {
      const chosenUpgradeType = clusterVersionToType[values.upgradeVersion]
      const body = {
        ...values,
        upgradeType: chosenUpgradeType,
        clusterId: cluster?.uuid,
      }
      if (body.percentageClusterUpgrade || body.sequentialClusterUpgrade) {
        const { success } = await upgradeNodes(body)
        if (success) handleClose()
      }
    },
    [cluster, clusterVersionToType],
  )
  const handleClose = () => {
    return onClose ? onClose() : history.push(routes.cluster.managed.list.path())
  }

  const renderLabel = (version, upgradeType, clusterVersions) => (
    <div className={classes.label}>
      <Text variant="body2">{capitalizeString(`${upgradeType} - (${version})`)}&nbsp;</Text>
      <ClusterVersionIcon version={version} clusterVersions={clusterVersions} showIconText={true} />
    </div>
  )

  const possibleVersions = useMemo(() => {
    if (!cluster) return []

    const ret = []
    if (cluster.minorUpgradeRoleVersion) {
      ret.push({
        key: UpgradeTypes.Minor,
        value: cluster.minorUpgradeRoleVersion,
        label: renderLabel(cluster.minorUpgradeRoleVersion, UpgradeTypes.Minor, clusterVersions),
      })
    }
    if (cluster.patchUpgradeRoleVersion) {
      ret.push({
        key: UpgradeTypes.Patch,
        value: cluster.patchUpgradeRoleVersion,
        label: renderLabel(cluster.patchUpgradeRoleVersion, UpgradeTypes.Patch, clusterVersions),
      })
    }
    return ret
  }, [cluster])

  return (
    <>
      <DocumentMeta title="Upgrade Cluster" />
      <ModalForm
        route={route}
        title="Upgrade Cluster"
        initialValues={initialState}
        onClose={handleClose}
        loading={loadingClusters || loadingClusterVersion}
        loadingMessage={message}
        error={error}
        onSubmit={handleSubmit}
        submitting={upgradingClusterNodes}
        submitTitle="Upgrade Now"
        withAddonManager
      >
        {({ setValues, setFieldValue, values }) => {
          return (
            <>
              <FormFieldSection step={1} title="Select the version to Upgrade the Cluster">
                {isWorking ? (
                  <Text className={classes.text} variant="caption1">
                    The cluster is busy ({cluster.taskStatus}), please come back to finish your
                    upgrade once the current task is complete.
                  </Text>
                ) : (
                  <RadioFields
                    id="upgradeVersion"
                    options={possibleVersions}
                    value={values?.upgradeVersion}
                    onChange={(version) => {
                      setFieldValue('upgradeVersion')(version)
                    }}
                  />
                )}
              </FormFieldSection>
              {/* )} */}
              {!isWorking && cluster?.containerRuntime !== 'containerd' && (
                <FormFieldSection step={2} title="Select a Container Runtime">
                  <Text variant="body2">
                    As of Kubernetes 1.24, Docker Container Runtime is not supported.
                  </Text>
                  <IconInfo
                    spacer={false}
                    icon="info-circle"
                    title="The following requirements must be met to migrate to containerd runtime"
                  >
                    <Text variant="body2">
                      Please reference this article:{' '}
                      <ExternalLink url={runtimeMigrationArticleLink}>
                        Migrate Container Runtime From Docker to Containerd During Upgrade
                      </ExternalLink>
                    </Text>
                  </IconInfo>
                  <PicklistField
                    DropdownComponent={ContainerRuntimePicklist}
                    id="containerRuntime"
                    label="Container Runtime"
                    tooltip="The container runtime for the cluster"
                    onChange={setFieldValue('containerRuntime')}
                    value={values?.containerRuntime}
                    kubeRoleVersion={values?.upgradeVersion}
                    disabled={clusterVersionToType[values?.upgradeVersion] === UpgradeTypes.Patch}
                    required
                  />
                </FormFieldSection>
              )}
              {!isWorking && (
                <FormFieldSection step={3} title="Upgrade Strategy">
                  <AddonTogglers
                    addons={clusterAddons}
                    wizardContext={values}
                    setWizardContext={setValues}
                  />
                </FormFieldSection>
              )}
            </>
          )
        }}
      </ModalForm>
    </>
  )
}
