import { makeStyles } from '@material-ui/styles'
import { listTablePrefs } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import Theme from 'core/themes/model'
import React, { useMemo } from 'react'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import { pick, prop } from 'ramda'
import { listSubnets, listNetworkAvailability, deleteSubnet } from '../actions'
import { subnetsSelector } from '../selectors'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import useListAction from 'core/hooks/useListAction'
import CreateSubnetModal from './CreateSubnetModal'
import { routes } from 'core/utils/routes'
import EditSubnetModal from './EditSubnetModal'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import DeleteSubnet from './DeleteSubnet'

type ModelDataKey = DataKeys.OpenstackSubnets
type SelectorModel = ArrayElement<ReturnType<typeof subnetsSelector>>

const useStyles = makeStyles<Theme>((theme) => ({
  subnets: {
    marginTop: '16px',
  },
}))

const AllocationPoolsCell = ({ value }) => {
  return (
    <>
      {value.map((pool) => (
        <div>
          {pool.start} - {pool.end}
        </div>
      ))}
    </>
  )
}

const DnsNameServersCell = ({ value }) => {
  return (
    <>
      {value.map((dnsNameServer) => (
        <div>{dnsNameServer}</div>
      ))}
    </>
  )
}

const HostRoutesCell = ({ value }) => {
  return (
    <>
      {value.map((hostRoute) => (
        <div>
          {hostRoute.destination} to {hostRoute.nexthop}
        </div>
      ))}
    </>
  )
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'cidr',
    label: 'CIDR',
  },
  {
    key: 'ip_version',
    label: 'IP Version',
    render: (version) => `IPv${version}`,
  },
  {
    key: 'gateway_ip',
    label: 'Gateway IP',
  },
  {
    key: 'allocation_pools',
    label: 'Allocation Pools',
    CellComponent: AllocationPoolsCell,
  },
  {
    key: 'availableIps',
    label: 'Available IPs',
  },
  {
    key: 'enable_dhcp',
    label: 'DHCP',
    render: (enabled) => (enabled ? 'Enabled' : 'Disabled'),
  },
  {
    key: 'dns_nameservers',
    label: 'DNS Name Servers',
    CellComponent: DnsNameServersCell,
  },
  {
    key: 'host_routes',
    label: 'Host Routes',
    CellComponent: HostRoutesCell,
  },
]

const usePrefParams = createUsePrefParamsHook('Subnets', listTablePrefs)

export default function SubnetsPage({ network, loading, allowCrud = true }) {
  const classes = useStyles()
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'
  const isVirtualNetwork = network?.type === 'virtual'

  const { params, getParamsUpdater } = usePrefParams({})

  const { message, loading: loadingSubnets, reload: reloadSubnets } = useListAction(listSubnets, {
    params,
  })
  const { loading: loadingIpAvailability, reload: reloadIpAvailability } = useListAction(
    listNetworkAvailability,
    {
      params,
    },
  )

  const subnets = useMemo(() => {
    return network?.subnetDetails || []
  }, [network?.subnetDetails])

  const addRoute = isVmware
    ? routes.openstack.createVmwSubnet
    : isVirtualNetwork
    ? routes.openstack.createVirtualNetworkSubnet
    : routes.openstack.createPhysicalNetworkSubnet

  return (
    <article className={classes.subnets}>
      <CreateSubnetModal addRoute={addRoute} isVirtualNetwork={isVirtualNetwork} />
      <ListContainer<ModelDataKey, SelectorModel>
        showBreadcrumbs={false}
        dataKey={DataKeys.OpenstackSubnets}
        searchTargets={['name']}
        uniqueIdentifier="id"
        loading={loading || loadingSubnets}
        loadingMessage={message}
        onRefresh={() => {
          reloadSubnets(true, true)
          reloadIpAvailability(true, true)
        }}
        data={subnets}
        columns={columns}
        DeleteDialogComponent={DeleteSubnet}
        addUrl={allowCrud ? addRoute.path({ id: network?.id }) : undefined}
        addText={allowCrud ? 'Create Subnet' : undefined}
        getParamsUpdater={getParamsUpdater}
        deleteAction={allowCrud ? deleteSubnet : undefined}
        EditDialogComponent={allowCrud ? EditSubnetModal : undefined}
        {...pick(listTablePrefs, params)}
      />
    </article>
  )
}
