import { makeStyles } from '@material-ui/styles'
import {
  getCinderQuotaUsage,
  getComputeQuotaUsage,
  listUserTenants,
} from 'app/plugins/account/components/userManagement/tenants/new-actions'
import { userTenantsSelector } from 'app/plugins/account/components/userManagement/tenants/selectors'
import { listFlavors } from 'app/plugins/openstack/components/flavors/actions'
import { flavorsSelector } from 'app/plugins/openstack/components/flavors/selectors'
import { listNetworks } from 'app/plugins/openstack/components/networks/actions'
import { networksSelector } from 'app/plugins/openstack/components/networks/selectors'
import { listStoragePools } from 'app/plugins/openstack/components/storage/pools/actions'
import { storagePoolsSelector } from 'app/plugins/openstack/components/storage/pools/selectors'
import { RootState, useAppSelector } from 'app/store'
import clsx from 'clsx'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import { createKaapiClusterAddonBody } from 'k8s/components/kaapi/cluster-addons/action-helpers'
import { createKaapiClusterAddon } from 'k8s/components/kaapi/cluster-addons/actions'
import { createKaapiClusterBody } from 'k8s/components/kaapi/clusters/action-helpers'
import { createKaapiCluster } from 'k8s/components/kaapi/clusters/actions'
import { listKaapiConfigMaps } from 'k8s/components/kaapi/config-maps/actions'
import { kaapiConfigMapsSelector } from 'k8s/components/kaapi/config-maps/selectors'
import { createHostedControlPlaneBody } from 'k8s/components/kaapi/hosted-control-plane/action-helpers'
import { createKaapiHostedControlPlane } from 'k8s/components/kaapi/hosted-control-plane/actions'
import { createKaapiKubeadmConfigTemplateBody } from 'k8s/components/kaapi/kubeadm-config-templates/action-helpers'
import { createKaapiKubeadmConfigTemplate } from 'k8s/components/kaapi/kubeadm-config-templates/actions'
import { createKaapiMachineDeploymentBody } from 'k8s/components/kaapi/machine-deployment/action-helpers'
import { createKaapiMachineDeployment } from 'k8s/components/kaapi/machine-deployment/actions'
import { createKaapiOpenStackClusterBody } from 'k8s/components/kaapi/openstack-clusters/action-helpers'
import { createKaapiOpenStackCluster } from 'k8s/components/kaapi/openstack-clusters/actions'
import { createKaapiOpenStackMachineTemplateBody } from 'k8s/components/kaapi/openstack-machine-templates/action-helpers'
import { createKaapiOpenStackMachineTemplate } from 'k8s/components/kaapi/openstack-machine-templates/actions'
import FormFieldSection from 'pf9-ui-components/built/components/validatedForm/FormFieldSection'
import TextField from 'pf9-ui-components/built/components/validatedForm/TextField'
import ModalForm from 'pf9-ui-components/built/elements/modal/ModalForm'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import { prop } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { isNilOrEmpty } from 'utils/fp'
import ClusterArchitectureFields from './form-components/ClusterArchitectureFields'
import ClusterConfigFields from './form-components/ClusterConfigFields'
import ServerConfigurationFields, {
  NodePoolErrors,
} from './form-components/ServerConfigurationFields'
import { extractSupportedK8sVersions } from './form-components/helpers'
import { v4 as uuidv4 } from 'uuid'
import {
  ApplicationCredentialType,
  createKaapiApplicationCredentials,
} from 'k8s/components/kaapi/secrets/actions'
import { FieldValidator } from 'core/utils/fieldValidators'

const CLUSTER_NAME_MAX_LENGTH = 22

const validateNodePools = (nodePools) => {
  const errors = {}
  nodePools.forEach(({ id, flavor, replicas }) => {
    if (!flavor || !replicas) {
      errors[id] = errors[id] || {}
      if (!flavor) {
        errors[id]['flavor'] = 'A VM flavor selection is required.'
      }
      if (!replicas) {
        errors[id]['replicas'] = 'The value must be greater or equal than 1'
      }
    }
  })
  return errors
}

const initialValues = {
  nodePools: [{ id: 1 }],
  calico: true,
  coreDns: true,
  enableAddons: true,
  controlPlaneType: 'managed',
  apiServerFlags: '',
  schedulerFlags: '',
  controllerManagerFlags: '',
  metallb: false,
}

interface TenantsInfo {
  name: string
  cores: { in_use: number; limit: number }
  ram: { in_use: number; limit: number }
  storage: { in_use: number; limit: number }
}

export const clusterNameValidator = new FieldValidator(
  (clsueterName) => /^[a-z0-9-]+$/.test(clsueterName),
  'Cluster name is invalid',
)

const ClusterDeploymentForm = (props) => {
  const classes = useStyles()
  const { history } = useReactRouter()
  const { params, getParamsUpdater } = useParams<Record<string, any>>(initialValues)
  const {
    prefs: { currentTenant },
  } = useScopedPreferences()
  const { updatePrefs: updateK8sPluginGlobalParams } = useScopedPreferences('k8sPluginGlobalParams')
  const { activeKaapiTenant } = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const namespace = activeKaapiTenant
  const [nodePoolErrors, setNodePoolErrors] = useState<NodePoolErrors>({})
  const [error, setError] = useState(null)

  // Tenants
  const { loading: loadingTenants } = useListAction(listUserTenants)
  const tenants = useAppSelector(userTenantsSelector)
  const tenantName = useMemo(() => tenants?.find((tenant) => tenant?.id === currentTenant)?.name, [
    tenants,
    currentTenant,
  ])
  const [tenantsInfo, setTenantsInfo] = useState<TenantsInfo[]>([])
  const [loadingTenantsInfo, setLoadingTenantsInfo] = useState(false)

  // VM Flavors
  const { loading: loadingVmFlavors } = useListAction(listFlavors, {
    params,
  })
  const vmFlavors = useAppSelector(flavorsSelector)

  // Storage Pools
  const { loading: loadingStoragePools } = useListAction(listStoragePools)
  const storagePools = useAppSelector(storagePoolsSelector)

  // Networks
  const { loading: loadingNetworks } = useListAction(listNetworks)
  const networks = useAppSelector(networksSelector)

  // Config Maps
  const { loading: loadingConfigMaps } = useListAction(listKaapiConfigMaps, {
    params: {
      namespace,
    },
    requiredParams: ['namespace'],
  })
  const configMaps = useSelector(kaapiConfigMapsSelector)
  const supportedK8sVersions = useMemo(() => extractSupportedK8sVersions(configMaps), [configMaps])

  // Create Actions
  const {
    update: createOpenStackCluster,
    updating: creatingOpenStackCluster,
    error: createOpenStackClusterError,
  } = useUpdateAction(createKaapiOpenStackCluster)
  const {
    update: createHostedControlPlane,
    updating: creatingHostedControlPlane,
    error: createHostedControlPlaneError,
  } = useUpdateAction(createKaapiHostedControlPlane)
  const {
    update: createCluster,
    updating: creatingCluster,
    error: createClusterError,
  } = useUpdateAction(createKaapiCluster)
  const {
    update: createKubeadmConfigTemplate,
    updating: creatingKubeadmConfigTemplate,
    error: createKubeadmConfigTemplateError,
  } = useUpdateAction(createKaapiKubeadmConfigTemplate)
  const {
    update: createOpenStackMachineTemplate,
    updating: creatingOpenStackMachineTemplate,
    error: createOpenStackMachineTemplateError,
  } = useUpdateAction(createKaapiOpenStackMachineTemplate)
  const {
    update: createMachineDeployment,
    updating: creatingMachindeDeployment,
    error: createMachineDeploymentError,
  } = useUpdateAction(createKaapiMachineDeployment)
  const {
    update: createClusterAddon,
    updating: creatingClusterAddon,
    error: createClusterAddonError,
  } = useUpdateAction(createKaapiClusterAddon)

  const hasError =
    !!createHostedControlPlaneError ||
    !!createOpenStackClusterError ||
    !!createClusterError ||
    !!createKubeadmConfigTemplateError ||
    !!createOpenStackMachineTemplateError ||
    !!createMachineDeploymentError ||
    !!createClusterAddonError

  useEffect(() => {
    if (!hasError) return
    // Show first error
    const error =
      createHostedControlPlaneError ||
      createOpenStackClusterError ||
      createClusterError ||
      createKubeadmConfigTemplateError ||
      createOpenStackMachineTemplateError ||
      createMachineDeploymentError ||
      createClusterAddonError

    setError({
      title: error?.title || 'Error creating cluster',
      message: error?.message || 'An error occurred while creating the cluster. Please try again.',
    })
  }, [
    hasError,
    createHostedControlPlaneError,
    createOpenStackClusterError,
    createClusterError,
    createKubeadmConfigTemplateError,
    createOpenStackMachineTemplateError,
    createMachineDeploymentError,
    createClusterAddonError,
  ])

  useEffect(() => {
    if (!currentTenant) {
      return
    }
    const fetchComputeQuotas = async () => {
      setLoadingTenantsInfo(true)
      try {
        const computeQuotas = await getComputeQuotaUsage({ tenantId: currentTenant })
        const cinderQuotas = await getCinderQuotaUsage({ tenantId: currentTenant })
        setTenantsInfo([
          {
            name: tenantName,
            cores: computeQuotas?.cores,
            ram: computeQuotas?.ram,
            storage: cinderQuotas?.gigabytes,
          },
        ])
      } catch (err) {
        setError({ title: 'Error fetching tenant quotas', message: err?.message })
      }
      setLoadingTenantsInfo(false)
    }
    fetchComputeQuotas()
  }, [currentTenant, tenants, tenantName])

  useEffect(() => {
    if (tenantName) {
      getParamsUpdater('tenant')(tenantName)
    }
  }, [tenantName])

  const handleSubmit = async () => {
    setError(null)

    // TODO: Before calling the APIs, validate the node pools. Ensure that all fields are filled out
    const nodePoolErrors = validateNodePools(params.nodePools)
    setNodePoolErrors(nodePoolErrors)
    if (!isNilOrEmpty(nodePoolErrors)) return

    // Create cluster secret
    await createKaapiApplicationCredentials(ApplicationCredentialType.Cluster, params.clusterName)

    const network = networks?.find((n) => n?.id === params?.nodePools[0]?.network)
    // Create OpenStackCluster
    const openstackCreateBody = createKaapiOpenStackClusterBody({
      name: params?.clusterName,
      namespace,
      network,
    })
    await createOpenStackCluster({
      namespace,
      body: openstackCreateBody,
    })

    // Create HostedControlPlane
    const hostedControlPlaneCreateBody = createHostedControlPlaneBody({
      name: `${params?.clusterName}cp`,
      namespace,
      version: params?.k8sVersion,
      apiServerFlags: params?.apiServerFlags ? params?.apiServerFlags?.split(',') : [],
      schedulerFlags: params?.schedulerFlags ? params?.schedulerFlags?.split(',') : [],
      controllerManagerFlags: params?.controllerManagerFlags
        ? params?.controllerManagerFlags?.split(',')
        : [],
    })
    await createHostedControlPlane({
      namespace,
      body: hostedControlPlaneCreateBody,
    })

    // Create Cluster
    const clusterCreateBody = createKaapiClusterBody({
      name: params?.clusterName,
      namespace,
      hostedControlPlaneRefName: `${params?.clusterName}cp`,
      infrastructureRefName: params?.clusterName,
      version: params?.k8sVersion,
    })
    await createCluster({
      namespace,
      body: clusterCreateBody,
    })

    // For each nodepool, create KubeadmConfigTemplate, MachineDeployment and OpenStackMachineTemplate
    for (const [idx, nodePool] of params.nodePools.entries()) {
      // Generate a short UUID
      const shortUUID = uuidv4().slice(0, 8)
      const name = `${params?.clusterName}-${shortUUID}`

      // Create KubeadmConfigTemplate
      const kubeadmConfigTemplateBody = createKaapiKubeadmConfigTemplateBody({
        name,
        namespace,
      })
      await createKubeadmConfigTemplate({ namespace, body: kubeadmConfigTemplateBody })

      // Create OpenStackMachineTemplate
      const openStackMachineTemplateBody = createKaapiOpenStackMachineTemplateBody({
        name,
        namespace,
        flavor: nodePool?.flavor,
        clusterName: params?.clusterName,
        storage: nodePool?.storage,
        network: nodePool?.network,
      })
      await createOpenStackMachineTemplate({ namespace, body: openStackMachineTemplateBody })

      // Create MachineDeployment
      const machineDeploymentCreateBody = createKaapiMachineDeploymentBody({
        name,
        namespace,
        clusterName: params?.clusterName,
        replicas: nodePool?.replicas,
        kubeadmConfigTemplateRefName: name,
        openStackMachineTemplateRefName: name,
        k8sVersion: params?.k8sVersion,
        autoscaling: nodePool?.autoscaling,
      })
      await createMachineDeployment({ namespace, body: machineDeploymentCreateBody })
    }

    // Create ClusterAddon
    if (params?.metallb) {
      const metallbBody = createKaapiClusterAddonBody({
        clusterName: params?.clusterName,
        namespace,
        addonType: 'metallb',
        version: '0.14.2',
      })
      await createClusterAddon({ namespace, body: metallbBody })
    }

    if (!hasError) {
      updateK8sPluginGlobalParams({ cluster: params?.clusterName })
      props.onClose()
      history.push(routes.manage.overview.path({ cluster: params.clusterName }))
    }
  }

  const submitting =
    creatingOpenStackCluster ||
    creatingHostedControlPlane ||
    creatingCluster ||
    creatingKubeadmConfigTemplate ||
    creatingOpenStackMachineTemplate ||
    creatingMachindeDeployment ||
    creatingClusterAddon

  return (
    <ModalForm
      {...props}
      open={true}
      title="Deploy A New Cluster"
      maxWidth={820}
      className={classes.modal}
      onSubmit={handleSubmit}
      submitTitle="Deploy Cluster"
      error={error}
      submitting={submitting}
    >
      {/* Step 1 */}
      <FormFieldSection title="Cluster Architecture" step={1}>
        <div className={classes.formSection}>
          <ClusterArchitectureFields
            tenants={tenantsInfo}
            params={params}
            getParamsUpdater={getParamsUpdater}
            loading={loadingTenantsInfo || loadingTenants}
          />
        </div>
      </FormFieldSection>

      {/* Step 2 */}
      <FormFieldSection title="Servers, Storage, and Network" step={2}>
        <div className={classes.formSection}>
          <ServerConfigurationFields
            id="nodePools"
            vmFlavors={vmFlavors}
            params={params}
            getParamsUpdater={getParamsUpdater}
            storagePools={storagePools}
            networks={networks}
            nodePoolErrors={nodePoolErrors}
          />
        </div>
      </FormFieldSection>

      {/* Step 3 */}
      <FormFieldSection title="Configure your Cluster" step={3}>
        <div className={classes.formSection}>
          <ClusterConfigFields
            params={params}
            getParamsUpdater={getParamsUpdater}
            k8sVersions={supportedK8sVersions}
          />
        </div>
      </FormFieldSection>

      {/* Step 4 */}
      <FormFieldSection title="Final Touches" step={4}>
        <div className={clsx(classes.formSection, classes.finalTouchesSection)}>
          <TextField
            id="clusterName"
            label="Cluster Name"
            placeholder={`Cluster Name (Max ${CLUSTER_NAME_MAX_LENGTH} characters)`}
            value={params?.clusterName}
            onChange={getParamsUpdater('clusterName')}
            required
            // Todo: Fix these typings in ui-components library so that we are not required to pass these props
            enterKeyHint={undefined}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            maxLength={CLUSTER_NAME_MAX_LENGTH}
            validations={[clusterNameValidator]}
            info={
              <>
                Cluster name can be 22 characters long and can not contain capital letters or
                special characters other that hyphen (-).
              </>
            }
          />
        </div>
      </FormFieldSection>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  modal: {
    '& .modal-body': {
      padding: '32px 24px 24px 32px',
    },
    '& .modal-body .progress-root .progressContent > form': {
      marginBottom: 0,
    },
  },
  formSection: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
    marginLeft: '12px',
    marginTop: theme.spacing(2),
  },
  finalTouchesSection: {
    marginTop: theme.spacing(2),
  },
}))

export default ClusterDeploymentForm
