import { RootState } from 'app/store'
import { Selector } from '@reduxjs/toolkit'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import { assocPath } from 'ramda'
import {
  ClusterUpgradeSteps,
  IClusterUpgradeJobSelector,
} from 'k8s/components/kaapi/cluster-upgrade/model'

const upgradeStepsMap = {
  [ClusterUpgradeSteps.ControlPlane]: 1,
  [ClusterUpgradeSteps.WorkerNodeGroups]: 2,
  // [ClusterUpgradeSteps.Addons]: 3, /* Might need this in future for Addons */
}

const isInProgress = (condition) => {
  if (isNilOrEmpty(condition)) return false
  const { status, reason, severity } = condition
  return status === 'False' && (reason === 'InProgress' || severity === 'Info')
}

const getUpgradeStepId = (phase, updateConditionsMap) => {
  if (phase === 'Completed') {
    /* Might need this in future for Addons */
    // return ClusterUpgradeSteps.Addons
    return ClusterUpgradeSteps.WorkerNodeGroups
  }

  const nodeGroupsCondition = updateConditionsMap.UpgradedNodeGroups
  if (isInProgress(nodeGroupsCondition)) {
    return ClusterUpgradeSteps.WorkerNodeGroups
  }

  /* Might need this in future for Addons */
  // const addonsCondition = updateConditionsMap.Addons
  // if (isInProgress(addonsCondition)) {
  //   return ClusterUpgradeSteps.Addons
  // }

  return ClusterUpgradeSteps.ControlPlane
}

//TODO: ============ Update this selector and model once API is ready ============
export const clusterUpgradeJobsSelector: Selector<
  RootState,
  IClusterUpgradeJobSelector[]
> = createSharedSelector(
  getDataSelector<DataKeys.KaapiClusterUpgradeJobs>(DataKeys.KaapiClusterUpgradeJobs),
  (rawClusterUpdateJobs) => {
    return rawClusterUpdateJobs.map((job) => {
      // Conditions contains disaggregated statuses of the various upgrade stages
      const updateConditions = job?.status?.conditions || []
      // Create a map of the update conditions where key = upgrade type
      const updateConditionsMap = updateConditions.reduce((accum, cond) => {
        const type = cond?.type
        if (!type) return accum
        accum[type] = cond
        return accum
      }, {})

      const phase = job.status?.phase
      const currentUpgradeStepId = getUpgradeStepId(phase, updateConditionsMap)
      const completedSteps =
        phase === 'Completed'
          ? Object.keys(ClusterUpgradeSteps).length
          : upgradeStepsMap[currentUpgradeStepId] - 1

      return {
        ...job,
        id: job?.metadata?.uid,
        name: job?.metadata?.name,
        namespace: job?.metadata?.namespace,
        clusterName: job?.spec?.clusterName,
        targetVersion: job?.spec?.targetVersion,
        sourceVersion: job?.spec?.sourceVersion,
        conditions: updateConditions,
        upgradeCondition: updateConditionsMap.Ready,
        controlPlaneCondition: updateConditionsMap.UpgradedControlPlane,
        nodeGroupsCondition: updateConditionsMap.UpgradedNodeGroups,
        addonsCondition: updateConditionsMap.UpgradedClusterAddons,
        nodeGroupStatuses: job.status?.nodeGroupStatuses || [],
        phase,
        reason: job?.status?.reason,
        message: job?.status?.message,
        startTime: job?.status?.startTime,
        completionTime: job?.status?.completionTime,
        currentUpgradeStepId,
        completedSteps,
        nodeGroups: job?.status?.nodeGroups || job?.spec?.nodeGroups,
        expectedCompletionTime: job?.status?.expectedCompletionTime,
      }
    })
  },
)

const compareByCreationTimestamp = (j1, j2) => {
  const j1StartTime = new Date(j1.metadata?.creationTimestamp)
  const j2StartTime = new Date(j2.metadata?.creationTimestamp)
  return j1StartTime > j2StartTime ? 1 : -1
}

export const kaapiClusterUpgradeJobsByClusterSelector = createSharedSelector(
  clusterUpgradeJobsSelector,
  (upgradeJobs) =>
    upgradeJobs.reduce((accum, job) => {
      const clusterName = job?.spec?.clusterName || ''
      const existingUpgradeJobs = accum[clusterName] || []
      // Sort by job creation timestamp in descending order
      const allUpgradeJobs = [...existingUpgradeJobs, job]
        .sort(compareByCreationTimestamp)
        .reverse()
      return assocPath([clusterName], allUpgradeJobs, accum)
    }, emptyObj),
)
