import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useSelector } from 'react-redux'
import { addFlavorTenant, removeFlavorTenant, getFlavorTenants } from './actions'
import useListAction from 'core/hooks/useListAction'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  sameLine: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
}))

const tenantColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'description',
    label: 'Description',
  },
]

interface Props {
  rows: any[]
  onClose: () => void
}

export default function EditFlavorTenantsModal({ rows: [flavor], onClose }: Props) {
  const classes = useStyles()

  const [submitting, setSubmitting] = useState(false)
  const [originalTenants, setOriginalTenants] = useState([])
  const defaultParams = {
    tenants: [],
  }

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenantsList = useSelector(tenantsSelector)

  useEffect(() => {
    if (loadingTenants || !tenantsList.length) {
      return
    }
    const makeCalls = async () => {
      const response = await getFlavorTenants({ id: flavor.id })
      const flavorTenants = response.map((pair) => pair.tenant_id)
      setOriginalTenants(flavorTenants)
      updateParams({
        tenants: tenantsList.filter((tenant) => flavorTenants.includes(tenant.id)),
      })
    }
    makeCalls()
  }, [flavor.id, loadingTenants, tenantsList])

  const { update: addTenantFn, reset: resetAddTenant, error } = useUpdateAction(addFlavorTenant)
  const { update: removeTenantFn, reset: resetRemoveTenant } = useUpdateAction(removeFlavorTenant)

  const submitForm = useCallback(async () => {
    setSubmitting(true)
    const tenantsToAdd = params.tenants
      .filter((tenant) => {
        return !originalTenants.includes(tenant.id)
      })
      .map((tenant) => tenant.id)
    const newTenantIds = params.tenants.map((tenant) => tenant.id)
    const tenantsToRemove = originalTenants.filter((tenant) => {
      return !newTenantIds.includes(tenant)
    })
    const promises = [
      ...tenantsToAdd.map((tenant) => {
        return addTenantFn({
          id: flavor.id,
          body: {
            addTenantAccess: {
              tenant,
            },
          },
        })
      }),
      ...tenantsToRemove.map((tenant) => {
        return removeTenantFn({
          id: flavor.id,
          body: {
            removeTenantAccess: {
              tenant,
            },
          },
        })
      }),
    ]
    Promise.allSettled(promises).then(()=>handleClose())    
    
  }, [flavor?.id, originalTenants, params])

  const handleClose = () => {
    setParams(defaultParams)
    resetAddTenant()
    resetRemoveTenant()
    setOriginalTenants([])
    onClose()
  }

  return (
    <ModalForm
      title={`Edit Flavor Tenants`}
      onSubmit={submitForm}
      onClose={handleClose}
      loading={loadingTenants}
      submitting={submitting}
      error={error}
      submitTitle={`Update Flavor Tenants`}
      open
    >
      <>
        <FormFieldSection title="Tenant Access">
          <ListTableField
            id="tenants"
            data={tenantsList}
            loading={loadingTenants}
            columns={tenantColumns}
            onChange={getParamsUpdater('tenants')}
            value={params.tenants}
            uniqueIdentifier="id"
            searchTargets={['name']}
            multiSelection
          />
        </FormFieldSection>
      </>
    </ModalForm>
  )
}
