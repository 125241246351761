import { routes } from 'core/utils/routes'
import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'

export default function HomePage() {
  const { history } = useReactRouter()

  // If a cluster is not already specified in the URL, choose the first cluster in the list
  useEffect(() => {
    history.push(routes.dashboard.root.path())
  }, [])

  return <></>
}
