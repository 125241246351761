import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import DeleteAction from 'core/actions/DeleteAction'
import { createClusterUpgradeJobBody } from 'app/plugins/infrastructure/components/clusters/capi/upgrade/action-helpers'
import { IClusterAddon } from 'app/plugins/infrastructure/components/clusters/cluster-addons/model'
import { EksAddons } from 'app/plugins/infrastructure/components/clusters/capi/control-plane/aws-managed-control-plane/model'
import { IClusterUpgradeJobSelector } from 'app/plugins/infrastructure/components/clusters/capi/upgrade/model'
import { cleanupKaapiSecrets } from '../secrets/actions'

const { kaapi } = ApiClient.getInstance()

export const kaapiClusterActions = ActionsSet.make<DataKeys.KaapiClusters>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiClusters],
  cacheKey: DataKeys.KaapiClusters,
})

export const listKaapiClusters = kaapiClusterActions.add(
  new ListAction<DataKeys.KaapiClusters>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Clusters ', { namespace })
    return kaapi.getKaapiClusters({ namespace })
  })
    .addDependency(DataKeys.KaapiHostedControlPlanes)
    .addDependency(DataKeys.KaapiOpenStackClusters)
    .addDependency(DataKeys.KaapiMachineDeployments)
    .addDependency(DataKeys.KaapiOpenStackMachineTemplates)
    .addDependency(DataKeys.KaapiMachines)
    .addDependency(DataKeys.KaapiClusterUpgradeJobs),
)

export const deleteKaapiCluster = kaapiClusterActions.add(
  new DeleteAction<DataKeys.KaapiClusters, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Kaapi cluster', { namespace, name })
      // TODO - Delete all secrets associated with the cluster
      // await cleanupKaapiSecrets(name)
      await kaapi.deleteKaapiCluster({ namespace, name })
    },
  ),
)

export const createKaapiCluster = kaapiClusterActions.add(
  new CreateAction<DataKeys.KaapiClusters, { namespace: string; body: any }>(
    async ({ namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Kaapi Cluster', { namespace, body })
      const created = await kaapi.createCluster(namespace, body)
      trackEvent('Create New Kaapi Cluster', {
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const k8sClusterUpgradeJobActions = ActionsSet.make<DataKeys.KaapiK8sClusterUpgrades>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiK8sClusterUpgrades],
  cacheKey: DataKeys.KaapiK8sClusterUpgrades,
})

export const createKaapiClusterUpgradeJob = k8sClusterUpgradeJobActions.add(
  new CreateAction<
    DataKeys.KaapiK8sClusterUpgrades,
    {
      namespace: string
      clusterName: string
      // addons?: any[]
      // eksAddons?: any[]
      // nodeGroups: Array<
      //   IMachineDeploymentSelector | IAwsMachinePoolSelector | IAwsMachinePoolSelector
      // >
      nodeGroups?: any[]
      upgradeJobs?: any[]
      targetVersion: string
      sourceVersion: string
    }
  >(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to create Cluster Upgrade Job', params)
    const body = createClusterUpgradeJobBody(params)
    const resource = await kaapi.createKaapiClusterUpgradeJob(body, params.clusterName)
    trackEvent('Create Cluster Update Job', {
      name: pathStr('metadata.name', resource),
      namespace: pathStr('metadata.namespace', resource),
      clusterName: pathStr('spec.clusterName', resource),
    })
    return resource
  }),
)
