import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listNetworks } from 'openstack/components/networks/actions'
import { useSelector } from 'react-redux'
import { networksByIdSelector, subnetsSelector } from 'openstack/components/networks/selectors'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function SubnetPickListForRouterInterface({
  disabled = false,
  name = 'subnet',
  label = 'Subnet',
  networkId = null,
  includeNoneOption = false,
  ...rest
}) {
  const { loading } = useListAction(listNetworks)
  const networksById = useSelector(networksByIdSelector)
  const subnets = useSelector(subnetsSelector)

  // Add networkObj to each subnet
  const subnetsWithNetworks = useMemo(() => {
    return subnets.map((subnet) => {
      return {
        ...subnet,
        networkObj: networksById[subnet.network_id],
      }
    })
  }, [subnets, networksById])

  const networkSubnets = useMemo(() => {
    const subnets = subnetsWithNetworks.filter((subnet) => {
      return (
        subnet?.networkObj?.['router:external'] !== true &&
        subnet?.networkObj?.['provider:physical_network'] !== null
      )
    })

    // If no networkId is provided, return all subnets
    if (!networkId) {
      return subnets
    }

    // Otherwise, filter by networkId
    return subnets.filter((subnet) => subnet.network_id === networkId)
  }, [networkId, subnetsWithNetworks])

  const options = useMemo(() => {
    // will need to filter out taken private IPs
    const subnetOptions = networkSubnets.map((subnet) => {
      return {
        label: `${subnet?.networkObj?.name || subnet?.networkObj?.id}: ${
          subnet?.cidr
        } (${subnet?.name || subnet?.id})`,
        value: subnet,
      }
    })
    return includeNoneOption ? [{ label: 'None', value: null }, ...subnetOptions] : subnetOptions
  }, [networkSubnets])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
