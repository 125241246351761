import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

import StatusCard from 'core/components/statusCard/StatusCard'
import Text from 'core/elements/Text'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import {
  generateClustersStatus,
  generateNodeGroupsStatus,
  generateUpgradesStatus,
} from '../dashboard/helpers'
import StatusIconText from '../dashboard/StatusIconText'
import { clusterUpgradeJobsSelector } from 'k8s/components/kaapi/cluster-upgrade/selectors'

const ClustersDashboardHeader = () => {
  const classes = useStyles({})
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')

  const clusters: any[] = useSelector(kaapiClustersOverviewSelector)
  const upgradeJobs: any[] = useSelector(clusterUpgradeJobsSelector)
  const clusterData = clusters?.find(
    (cluster) => cluster.metadata.name === k8sPluginGlobalPerfs?.cluster,
  )
  const machineDeployments = clusterData?.machineDeployments || []
  const machines = clusterData?.machines || []

  const clusterHealthStatus = useMemo(() => {
    return generateClustersStatus(clusters)
  }, [clusters])
  const upgradesStatus = useMemo(() => {
    return generateUpgradesStatus(upgradeJobs)
  }, [clusters])

  // Map the machine deployments status to get the node groups status
  const nodeGroupsStatus = useMemo(() => {
    return generateNodeGroupsStatus(machineDeployments)
  }, [machineDeployments])

  return (
    <div className={clsx(classes.cardsRow)}>
      <StatusCard title="Cluster Health Summary" count={clusters.length}>
        {clusterHealthStatus.length ? (
          clusterHealthStatus?.map((item, index) => (
            <StatusIconText
              key={index}
              label={item?.label || ''}
              icon={item?.icon}
              variant={item?.variant}
              rightBorder={index !== clusterHealthStatus?.length - 1}
              margin={false}
            />
          ))
        ) : (
          <Text variant="body2" style={{ paddingTop: '8px', paddingBottom: '12px' }}>
            No Clusters
          </Text>
        )}
      </StatusCard>
      {/* This might require in future */}
      {/* <StatusCard title="Node Groups" count={machineDeployments.length}>
        {nodeGroupsStatus.length ? (
          nodeGroupsStatus?.map((item, index) => (
            <StatusIconText
              key={index}
              label={item?.label || ''}
              icon={item?.icon}
              variant={item?.variant}
              rightBorder={index !== nodeGroupsStatus?.length - 1}
              margin={false}
            />
          ))
        ) : (
          <Text variant="body2" style={{ paddingTop: '8px', paddingBottom: '12px' }}>
            No Node Groups
          </Text>
        )}
      </StatusCard> */}

      <StatusCard title={'Upgrades'} count={upgradeJobs?.length}>
        {upgradesStatus.length ? (
          upgradesStatus?.map((item, index) => (
            <StatusIconText
              key={index}
              label={item?.label || ''}
              icon={item?.icon}
              variant={item?.variant}
              rightBorder={index !== upgradesStatus?.length - 1}
              margin={false}
            />
          ))
        ) : (
          <Text variant="body2" style={{ paddingTop: '8px', paddingBottom: '12px' }}>
            No Upgrades In Progress
          </Text>
        )}
      </StatusCard>
    </div>
  )
}

export default ClustersDashboardHeader

const useStyles = makeStyles((theme: Theme) => ({
  cardsRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& > :nth-child(2n+1)': {
      // First card in each row
      borderRadius: '4px 0px 0px 4px',
    },
    '& > :nth-child(2n)': {
      // Last card in each row
      borderRadius: '0px 4px 4px 0px',
    },
  },
  card: {
    backgroundColor: theme.components.card.background,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: '16px 24px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  cardHeaderBtn: {
    cursor: 'pointer',
  },
  cardBody: {
    display: 'flex',
  },
  icon: {
    paddingLeft: '4px',
    fontSize: '12px',
  },
}))
