export enum BlueprintTypes {
  Consolidated = 'consolidated',
  MultiTenant = 'multiTenant',
  Custom = 'custom',
}

export interface HostNetworkConfiguration {
  id: string
  name: string
  mgmtInterface: string
  vmConsoleInterface: string
  hostLivenessInterface: string
  tunnelingInterface: string
  imagelibInterface: string
  networkLabels: NetworkLabels
  clusterName: string
}

export interface NetworkLabels {
  [key: string]: string
}
