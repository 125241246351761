import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
// import { Checkbox, FormControlLabel } from '@material-ui/core'
import Checkbox from 'core/elements/input/Checkbox'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Alert from 'core/components/Alert'
import ExternalLink from 'core/components/ExternalLink'
import {
  CustomerTiers,
  dashboardUrl,
  DOMAIN_NAME,
  forgotPasswordUrl,
  loginWithSsoUrl,
  setCookieUrl,
} from 'app/constants'
import moment from 'moment'
import { trackEvent } from 'utils/tracking'
import { MFAHelpLink } from 'k8s/links'
import Input from 'core/elements/input/Input'
import Button from 'core/elements/button'
import { LoginMethodTypes } from 'app/plugins/account/components/userManagement/users/helpers'
import { authenticateUser } from 'app/plugins/account/components/userManagement/users/new-actions'
import Bugsnag from 'utils/bugsnag'
import FormPageContainer from 'core/containers/FormPageContainer'
import generateTestId from 'utils/test-helpers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import useReactRouter from 'use-react-router'
import Tooltip from 'core/elements/tooltip'
import { topMiddle } from 'core/elements/menu/defaults'
import Cookies from 'js-cookie'
import { getSSORedirectUrl, isDefaultDomain } from 'core/utils/helpers'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { useSelector } from 'react-redux'
import { prop, isEmpty } from 'ramda'
import { loadFeatures } from 'core/containers/AppController'

const useStyles = makeStyles((theme: Theme) => ({
  methodToggle: {
    position: 'absolute',
    top: 17,
    right: 26,
    color: theme.palette.blue[500],
    cursor: 'pointer',
  },
  form: {
    maxWidth: 400,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 24,
  },
  paragraph: {
    textAlign: 'center',
  },
  signinButton: {
    marginTop: 16,
    width: '100%',
  },
  forgotPwd: {
    alignSelf: 'end',
    lineHeight: '18px',
    marginTop: 8,
  },
  formTitle: {
    textAlign: 'center',
  },
  passwordInputField: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .input[type=password]::-ms-reveal, & .input[type=password]::-ms-clear': {
      display: 'none',
    },
  },
  mfa: {
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'start',
    gap: 4,
  },
  mfaInfo: {
    '& > span': {
      lineHeight: '16px',
    },
  },
  mfaContainer: {
    marginBottom: theme.spacing(),
    textAlign: 'center',
  },
  fields: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    gap: 16,
  },
  loginMethods: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  loginMethod: {
    flexGrow: 1,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.palette.grey['000'],
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey['000'],
    '&.active': {
      borderBottomWidth: 3,
    },
    '&.inactive': {
      borderBottomWidth: 1,
      color: theme.palette.grey[500],
      marginBottom: 1,
      cursor: 'pointer',
    },
  },
}))

interface Props {
  onAuthSuccess: any
  ssoEnabled: boolean
  customerTier: string
}

const defaultParams = {
  username: '',
  password: '',
  domain: 'default',
  loginMethod: LoginMethodTypes.Local,
  MFAcheckbox: false,
  mfa: '',
  loginFailed: false,
  loading: false,
  ssoEnabled: true,
}

const LoginPage: React.FC<Props> = (props) => {
  const classes = useStyles({})
  // const { ssoEnabled, onAuthSuccess, customerTier } = props
  const { onAuthSuccess, customerTier } = props
  const ssoEnabled = true
  const { history } = useReactRouter()
  const { getUserPrefs } = useScopedPreferences()
  const [params, setParams] = useState(defaultParams)
  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { features } = session
  const infraHost = `https://${features?.sso_infra_url}`

  const updateParams = (p) => {
    setParams((params) => {
      return { ...params, ...p }
    })
  }
  const {
    username: loginUsername,
    password,
    loginMethod,
    MFAcheckbox,
    mfa,
    loginFailed,
    loading,
    domain,
  } = params

  useEffect(() => {
    if (ssoEnabled) {
      updateParams({ loginMethod: LoginMethodTypes.SSO })
    }

    // Check if features are loaded in the session
    if (isEmpty(features)) {
      const getFeatures = async () => {
        await loadFeatures()
      }

      getFeatures()
    }
  }, [])

  const setDomainNameCookie = (domain: string) => {
    Cookies.remove(DOMAIN_NAME) // Clear any existing cookie
    Cookies.set(DOMAIN_NAME, domain) // Set new cookie
  }

  // Redirect to SSO login
  const redirectToSsoLogin = () => {
    const ssoRedirectUrl = getSSORedirectUrl(domain)
    const originHost = window.location.origin
    if (originHost === infraHost) {
      if (!isDefaultDomain(domain)) setDomainNameCookie(domain)
      window.location.href = ssoRedirectUrl
    } else {
      const url = new URL(
        `${infraHost}${setCookieUrl}?redirectUrl=${originHost}${loginWithSsoUrl}&domain=${domain}`,
      )
      window.location.replace(url)
    }
  }

  const updateValue = (key) => (event) => {
    updateParams({ [key]: event.target.value })
  }

  const handleLoginError = () => {
    // If SSO login fails, user is redirected so don't show the alert
    if (loginMethod === LoginMethodTypes.SSO) {
      return
    }
    return updateParams({ loading: false, loginFailed: true })
  }

  const performLogin = useCallback(
    async (event) => {
      event.preventDefault()
      updateParams({ loginFailed: false, loading: true })
      const isDefaultDomainName = isDefaultDomain(domain)

      // If user enters a domain other than defaul, set it in the cookie
      if (!isDefaultDomainName) {
        setDomainNameCookie(domain)
      }

      Bugsnag.leaveBreadcrumb('Attempting PF9 Sign In', {
        loginUsername,
        loginMethod,
        MFAcheckbox,
        mfa,
      })

      const { username, unscopedToken, expiresAt, issuedAt, isSsoToken } = await authenticateUser({
        loginUsername,
        password,
        loginMethod,
        MFAcheckbox,
        mfa,
        ...(!isDefaultDomainName && { domain }),
      })

      // If login fails, show error message
      if (!unscopedToken) {
        return handleLoginError()
      }

      // Track successful login
      trackEvent('PF9 Signed In', {
        username,
        duDomain: window.location.origin,
      })
      const { currentTenant, currentRegion } = getUserPrefs(username)

      await onAuthSuccess({
        currentTenant,
        currentRegion,
        username,
        password,
        unscopedToken,
        expiresAt,
        issuedAt,
        isSsoToken,
        ...(!isDefaultDomainName && { domain }),
      })

      updateParams({ loading: false })
      history.push(dashboardUrl)
    },
    [onAuthSuccess, loginUsername, password, loginMethod, MFAcheckbox, mfa, domain],
  )

  const handleChangeBox = (name) => (checked) => {
    updateParams({ [name]: checked })
  }

  const handleForgotPassword = useCallback(
    () => (e) => {
      history.push(forgotPasswordUrl)
    },
    [],
  )

  return (
    <>
      <FormPageContainer
        footer={
          customerTier !== CustomerTiers.OEM ? (
            <Text className={classes.paragraph} variant="caption3" color="textSecondary">
              By signing in, you agree to our{' '}
              <ExternalLink url="https://platform9.com/terms-conditions/" textVariant="caption3">
                Terms of Service
              </ExternalLink>
              . © 2014-{moment().year()} Platform9 Systems, Inc.
            </Text>
          ) : null
        }
      >
        <form
          data-testid={generateTestId('login', 'form')}
          className={classes.form}
          onSubmit={performLogin}
        >
          {ssoEnabled && (
            <Text
              data-testid={generateTestId('login', 'local', 'credentials')}
              className={classes.methodToggle}
              variant="caption1"
              onClick={() =>
                updateParams({
                  loginMethod:
                    loginMethod === LoginMethodTypes.Local
                      ? LoginMethodTypes.SSO
                      : LoginMethodTypes.Local,
                })
              }
            >
              {loginMethod === LoginMethodTypes.Local ? 'Use SSO' : 'Use local credentials'}
            </Text>
          )}
          <Text variant="h3" className={classes.formTitle}>
            Sign In
          </Text>
          <div className={classes.fields}>
            {loginMethod === LoginMethodTypes.Local && (
              <>
                <Input
                  required
                  variant="dark"
                  id="email"
                  label="Email"
                  placeholder="Email"
                  onChange={updateValue('username')}
                />
                <div className={classes.passwordInputField}>
                  <Input
                    required
                    variant="dark"
                    id="password"
                    label="Password"
                    type="password"
                    onChange={updateValue('password')}
                  />
                </div>
                <div className={classes.passwordInputField}>
                  <Input
                    variant="dark"
                    id="domain"
                    label="Domain"
                    type="text"
                    value={params.domain}
                    onChange={updateValue('domain')}
                  />
                  {/* Forgot Password to be enabled in later*/}
                  {/* <Text className={classes.forgotPwd}>
                    <SimpleLink
                      onClick={handleForgotPassword()}
                      textVariant="caption2"
                      src={forgotPasswordUrl}
                    >
                      Forgot password?
                    </SimpleLink>
                  </Text> */}
                </div>
                {MFAcheckbox && (
                  <Input
                    required={MFAcheckbox}
                    variant="dark"
                    id="mfa"
                    label="MFA Code"
                    placeholder="MFA Code"
                    onChange={updateValue('mfa')}
                  />
                )}
                <Checkbox
                  checked={MFAcheckbox}
                  onChange={handleChangeBox('MFAcheckbox')}
                  label={
                    <Text component="div" variant="body2" className={classes.mfa}>
                      I have a
                      <Tooltip
                        align={topMiddle.align}
                        offset={topMiddle.offset}
                        origin="right center"
                        message="Multi-Factor Authentication"
                      >
                        MFA
                      </Tooltip>
                      token.
                      <ExternalLink
                        className={classes.mfaInfo}
                        textVariant="body2"
                        url={MFAHelpLink}
                      >
                        More info
                      </ExternalLink>
                    </Text>
                  }
                />
              </>
            )}
            {loginFailed && (
              <Alert
                id="login-failed"
                variant="error"
                title="Login Failed."
                message={
                  loginMethod !== LoginMethodTypes.SSO
                    ? 'Please check your username, password, domain and try again'
                    : ''
                }
              />
            )}
            {loginMethod === LoginMethodTypes.SSO ? (
              <>
                <Input
                  variant="dark"
                  id="domain"
                  label="Domain"
                  type="text"
                  value={params.domain}
                  onChange={updateValue('domain')}
                />
                <Button
                  id="login-submit"
                  type="button"
                  loading={loading}
                  className={classes.signinButton}
                  onClick={redirectToSsoLogin}
                >
                  Sign In With SSO
                </Button>
              </>
            ) : (
              <Button
                id="login-submit"
                type="submit"
                loading={loading}
                className={classes.signinButton}
              >
                {loading ? 'Attempting login...' : 'Sign In'}
              </Button>
            )}
          </div>
        </form>
      </FormPageContainer>
    </>
  )
}

export default LoginPage
