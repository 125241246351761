import React, { useCallback, useEffect, useState } from 'react'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import { addFixedIpToVm } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import NetworkPicklist from 'openstack/components/networks/NetworkPicklist'
import PortPicklist from 'openstack/components/networks/network-details/PortPicklist'
import PicklistField from 'core/components/validatedForm/DropdownField'
import Text from 'core/elements/Text'

export default function AddFixedIpModal({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const defaultParams = {
    network: null,
    port: null,
  }

  const { params, getParamsUpdater, updateParams, setParams } = useParams<{
    network: any
    port: any
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(addFixedIpToVm)

  const submitForm = useCallback(async () => {
    const body = params.port
      ? {
          interfaceAttachment: {
            port_id: params.port?.id,
          },
        }
      : {
          interfaceAttachment: {
            net_id: params.network?.id,
          },
        }
    const { success } = await update({
      id: vm.id,
      body,
    })
    if (success) {
      handleClose()
    }
  }, [vm, params])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  return (
    <ModalForm
      open
      title={`Add Private IP to ${vm.name || vm.id}`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Add Private IP`}
      loading={!vm}
    >
      <FormFieldSection title="Interface Selection">
        <Text variant="body2">
          Select a network and optionally a port to assign a new private IP from. If no port is
          specified, a random subnet and port will be selected to allocate an IP address from.
        </Text>
        <PicklistField
          DropdownComponent={NetworkPicklist}
          id="network"
          label="Network"
          onChange={getParamsUpdater('network')}
          value={params.network}
          required
        />
        {!!params.network && (
          <PicklistField
            DropdownComponent={PortPicklist}
            id="port"
            label="Port"
            onChange={getParamsUpdater('port')}
            value={params.port}
            networkId={params.network?.id}
            includeNoneOption
          />
        )}
      </FormFieldSection>
    </ModalForm>
  )
}
