import React from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import colors from 'core/themes/base/colors'
import Theme from 'core/themes/model'

function CircularStepBubble({ active, completed, failed, icon, first }) {
  const classes = useStepIconStyles()
  return (
    <Text
      variant="subtitle2"
      component="figure"
      className={clsx(classes.stepBubble, {
        first,
        active,
        completed,
        failed,
      })}
    >
      {failed ? (
        <FontAwesomeIcon size="md" className={classes.stepIcon}>
          times
        </FontAwesomeIcon>
      ) : completed ? (
        <FontAwesomeIcon size="md" className={classes.stepIcon}>
          check
        </FontAwesomeIcon>
      ) : (
        icon
      )}
    </Text>
  )
}

interface Props {
  steps: Array<{ stepId: string; content?: React.ReactNode; failed?: boolean }>
  completedSteps: number
}

export default function Stepper({ steps, completedSteps }: Props) {
  const classes = useStepperStyles()
  const numSteps = steps.length
  const activeStepIdx = completedSteps + 1

  return (
    <ul className={classes.stepperContainer}>
      {steps.map((step, idx) => {
        const { stepId, content = null, failed = false } = step
        const active = activeStepIdx === idx + 1
        const completed = idx < completedSteps
        const isLastStep = idx === numSteps - 1
        return (
          <li className={classes.stepItem} key={stepId}>
            <div className={classes.stepper}>
              <CircularStepBubble
                first={idx === 0}
                active={active}
                completed={completed}
                failed={failed}
                icon={idx + 1}
              />
              {!isLastStep && <div className={clsx(classes.line, { completed })}></div>}
            </div>
            <div className={classes.content}>{content}</div>
          </li>
        )
      })}
    </ul>
  )
}

const useStepIconStyles = makeStyles<Theme>((theme) => ({
  stepBubble: {
    position: 'relative',
    width: 32,
    height: 32,
    fontWeight: 400,
    borderRadius: 100,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.components.stepper.bubbleBorder}`,

    '&.active, &.completed, &.active i, &.completed i': {
      color: theme.components.wizard.step.bubbleActiveText,
    },
    '&.active, &.completed': {
      backgroundColor: theme.palette.blue[700],
      borderColor: theme.palette.blue[700],
    },
    '&.completed': {
      backgroundColor: colors.teal[100],
      borderColor: colors.teal[100],
    },
    '&.failed': {
      backgroundColor: colors.pink[800],
      borderColor: colors.pink[800],
    },
  },
  stepIcon: {
    color: theme.palette.common.white,
    marginTop: 2,
    fontWeight: 500,
  },
}))

const useStepperStyles = makeStyles<Theme>((theme) => ({
  stepperContainer: {
    padding: '0px',
  },
  stepItem: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  stepper: {
    position: 'relative',
  },
  line: {
    left: '16px',
    height: '100%',
    position: 'absolute',
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    zIndex: -1,
    '&.completed': {
      borderLeft: `1px solid ${colors.teal[100]}`,
    },
    '&.failed': {
      borderLeft: `1px solid ${theme.palette.red[800]}`,
    },
  },
  content: {
    marginLeft: theme.spacing(2),
  },
}))
