import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ClusterStatusSpan from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import { IClusterStatus } from 'app/plugins/infrastructure/components/clusters/model'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import StatusDetails from 'core/components/status-bar/StatusDetails'
import Button from 'core/elements/button/Button'
import Text from 'core/elements/Text'
import colors from 'core/themes/base/colors'
import Theme from 'core/themes/model'
import { hexToRgbaCss } from 'core/utils/colorHelpers'
import {
  ClusterUpgradeStatus,
  clusterUpgradeStatusMap,
} from 'k8s/components/kaapi/cluster-upgrade/model'

interface StyleProps {
  status: IClusterStatus
}

export enum Status {
  InProgress = 'inProgress',
  Success = 'success',
  Failed = 'failed',
  NotStarted = 'notStarted',
  Warning = 'warning',
}

const ErrorMessage = ({ text }) => {
  return (
    <Text variant="caption1">
      Error:{' '}
      <Text component="span" variant="body2">
        {text}
      </Text>
    </Text>
  )
}
interface Error {
  reason: string
  message: string
}
interface StatusDetails {
  status: IClusterStatus
  title: string
  error?: Error
  subtitles: { label: string | ReactNode; value: string | ReactNode }[]
}
interface StatusBarPorps {
  statusDetails: StatusDetails
  onSeeDetailsClick: () => void
  onRetryClick?: () => void
  onClose: () => void
}

export const getClusterUpgradeTitle = (status) => {
  switch (status) {
    case ClusterUpgradeStatus.Completed:
      return 'Kubernetes version upgrade completed'
    case ClusterUpgradeStatus.Failed:
      return 'Kubernetes version upgrade failed'
    case ClusterUpgradeStatus.InProgress:
      return 'Kubernetes version upgrade in progress...'
    default:
      return 'Kubernetes version upgrade in progress...'
  }
}

const StatusBar = ({ statusDetails, onSeeDetailsClick, onClose, onRetryClick }: StatusBarPorps) => {
  const { status, title, error } = statusDetails
  const classes = useStyles({ status: status })

  return (
    <div className={classes.statusBar}>
      <div className={classes.statusBarContainer} data-testid="emp-creation-progress-bar">
        <div className={classes.icon}>
          <ClusterStatusSpan iconStatus showCircleIcon status={status} />
        </div>
        <div className={classes.statusBarContent}>
          <Text className={classes.stepTitle} variant="subtitle2">
            {title}
          </Text>
          <div className={classes.statusDetails}>
            {status === clusterUpgradeStatusMap.Failed ? (
              <ErrorMessage text={error?.reason} />
            ) : (
              <StatusDetails statusDetails={statusDetails} />
            )}
          </div>
        </div>
        <div className={classes.customButton}>
          <Button variant="secondary" onClick={onSeeDetailsClick}>
            See Details
          </Button>
          {status === clusterUpgradeStatusMap.Failed && (
            <Button
              className={classes.retryButton}
              icon="refresh"
              variant="secondary"
              onClick={onRetryClick}
            >
              Retry Upgrade
            </Button>
          )}
        </div>

        {status === clusterUpgradeStatusMap.Completed && (
          <div className={classes.closeIcon}>
            <FontAwesomeIcon solid onClick={onClose}>
              xmark
            </FontAwesomeIcon>
          </div>
        )}
      </div>
    </div>
  )
}

export default StatusBar

const getBackgroundColor = (status: IClusterStatus, theme: Theme) =>
  ({
    ok: hexToRgbaCss(colors.teal[100], 0.05),
    pause: hexToRgbaCss(theme.palette.yellow.main, 0.05),
    fail: hexToRgbaCss(theme.palette.pink[800], 0.05),
    error: hexToRgbaCss(theme.palette.pink[800], 0.05),
    loading: hexToRgbaCss(theme.palette.blue[700], 0.05),
  }[status] || hexToRgbaCss(theme.palette.blue[700], 0.05))

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  statusBar: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    background: ({ status }) => getBackgroundColor(status, theme),
    margin: `${theme.spacing(2)}px 0px`,
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  statusBarContainer: {
    display: 'flex',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(
      2,
    )}px`,
  },
  statusBarContent: {
    marginLeft: theme.spacing(2),
  },
  statusDetails: {
    display: 'flex',
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1)}px 0px`,
  },
  icon: {
    '& i': {
      fontSize: theme.spacing(3),
    },
    marginTop: theme.spacing(1.5),
  },
  customButton: {
    alignSelf: 'center',
    '& .button-text, & .button-icon': {
      color: theme.palette.grey[600],
    },
    marginLeft: 'auto',
  },
  closeIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    '& i': {
      width: '100%',
      height: '100%',
    },
    '& i::before': {
      display: 'block',
      width: '100%',
      height: '100%',
    },
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(0.5),
  },
  stepTitle: {
    marginTop: '0.5px',
    marginLeft: '0.5px',
  },
  retryButton: {
    marginLeft: theme.spacing(2),
  },
}))
