import ApiClient from 'api-client/ApiClient'
import { encodeStr } from 'utils/misc'
import jsYaml from 'js-yaml'
import { createKaapiSecretBody } from 'k8s/components/kaapi/secrets/action-helpers'
import store from 'app/store'
import ActionsSet from 'core/actions/ActionsSet'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'

const { kaapi, keystone, options: apiClientOptions } = ApiClient.getInstance()

export enum ApplicationCredentialType {
  Cluster = 'cluster',
  IdentityRef = 'identityRef',
}

export const kaapiSecretActions = ActionsSet.make<DataKeys.KaapiSecrets>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiSecrets],
  cacheKey: DataKeys.KaapiSecrets,
})

export const listKaapiSecrets = kaapiSecretActions.add(
  new ListAction<DataKeys.KaapiSecrets>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Machines ', { namespace })
    return kaapi.getKaapiSecrets({ namespace })
  }),
)

export const deleteKaapiSecret = kaapiSecretActions.add(
  new DeleteAction<DataKeys.KaapiSecrets, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Kaapi Secret', { namespace, name })
      await kaapi.deleteKaapiSecret({ name })
    },
  ),
)

// Cleanup kaapi application credentials and secrets
export const cleanupKaapiSecrets = async (clusterName) => {
  const { session } = store.getState()
  const {
    userDetails: { userId },
  } = session
  const applicationCredentialKey = `${clusterName}-cloud-config`

  // Get all application credentials and find matching application credential
  const applicationCredentials = await keystone.getAllApplicationCredentials({
    userId,
  })
  const matchingApplicationCredential = applicationCredentials?.find(
    (ac) => ac.name === applicationCredentialKey,
  )

  // Delete application credential and kaapi secret
  await keystone.deleteApplicationCredential({ userId, id: matchingApplicationCredential.id })
  await kaapi.deleteKaapiSecret({ name: applicationCredentialKey })
}

// Create application credential for user-tenant and generate identityRef for kaapi
export const createKaapiApplicationCredentials = async (
  type: ApplicationCredentialType = ApplicationCredentialType.IdentityRef,
  clusterName: string = '',
) => {
  const { session } = store.getState()
  const {
    userDetails: { email, userId },
    activeTenant,
    activeKaapiTenant,
  } = session

  // Create application credential key
  let applicationCredentialKey
  if (type === ApplicationCredentialType.Cluster) {
    applicationCredentialKey = `${clusterName}-cloud-config`
  } else {
    const username = email.split('@')[0]
    applicationCredentialKey = `pf9-${username}-${activeTenant}`
  }

  // Get all application credentials
  const applicationCredentials = await keystone.getAllApplicationCredentials({
    userId,
  })
  const matchingApplicationCredential = applicationCredentials?.find(
    (ac) => ac.name === applicationCredentialKey,
  )

  // Create new application credential if not available in Keystone
  if (!matchingApplicationCredential) {
    const body = {
      application_credential: {
        unrestricted: false,
        name: applicationCredentialKey,
        secret: null,
        roles: [],
        expires_at: null,
        access_rules: [],
        description: '',
      },
    }

    // Create new application credential for openstack
    const applicationCredentials = await keystone.createApplicationCredentials({
      userId,
      body,
    })

    // If application credentials are not available, return
    if (applicationCredentials) {
      // Create new kubernetes secret for kaapi
      await createKaapiSecrets({
        type,
        applicationCredentials,
        activeKaapiTenant,
      })
    }
  }
}

// Create IdentityRef secret for kaapi
export const createKaapiSecrets = async ({ type, applicationCredentials, activeKaapiTenant }) => {
  const keystoneEndpoint = await apiClientOptions.keystoneEndpoint
  const auth_url = `${keystoneEndpoint}/v3/auth/tokens?nocatalog`
  const { name, id, secret } = applicationCredentials

  const cloudYaml =
    type === ApplicationCredentialType.IdentityRef
      ? encodeStr(
          jsYaml.dump({
            cloudConfig: {
              global: {
                auth: {
                  'auth-url': auth_url,
                  'application-credential-id': id,
                  'application-credential-secret': secret,
                },
              },
            },
          }),
        )
      : encodeStr(
          jsYaml.dump({
            clouds: {
              openstack: {
                auth: {
                  auth_url,
                  application_credential_id: id,
                  application_credential_secret: secret,
                },
                identity_api_version: 3,
              },
            },
          }),
        )

  await kaapi.createKaapiSecret({
    body: createKaapiSecretBody({
      name,
      cloudYaml,
      namespace: activeKaapiTenant,
      type:
        type === ApplicationCredentialType.IdentityRef
          ? 'Opaque'
          : 'addons.projectsveltos.io/cluster-profile',
    }),
  })
}
