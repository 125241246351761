import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
// import { dummyData } from './dummyData'

const { kaapi } = ApiClient.getInstance()

export const kaapiConfigMapActions = ActionsSet.make<DataKeys.KaapiConfigMaps>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiConfigMaps],
  cacheKey: DataKeys.KaapiConfigMaps,
})

export const listKaapiConfigMaps = kaapiConfigMapActions.add(
  new ListAction<DataKeys.KaapiConfigMaps, { namespace: string }>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Config Maps', { namespace })
    return kaapi.getConfigMaps({ namespace })
    // return dummyData
  }),
)
