import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { detachFixedIpFromVm } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useListAction from 'core/hooks/useListAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import PicklistField from 'core/components/validatedForm/DropdownField'
import { refreshVirtualMachine } from './actions'
import { waitSeconds } from 'openstack/components/infrastructure/blueprint/helpers'
import FixedIpsPicklist from './FixedIpsPicklist'
import { listPorts } from '../networks/actions'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

export default function RemoveFixedIpDialog({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)

  const defaultParams = {
    port: '',
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(detachFixedIpFromVm)
  const { update: refreshVm, updating: refreshingVm } = useUpdateAction(refreshVirtualMachine)
  const { reload: refreshPorts } = useListAction(listPorts)

  const handleSubmit = useCallback(() => {
    setSubmitting(true)
    const asyncCalls = async () => {
      const { success } = await update({ id: vm.id, portId: params.port })
      await waitSeconds(3) // wait 3 seconds for VM private IP to be updated first
      await refreshVm({ id: vm.id })
      refreshPorts(true, true)
      setSubmitting(false)
      if (success) handleClose()
    }
    asyncCalls()
  }, [params, vm])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose(true)
  }

  return (
    <ModalForm
      title="Remove Private IP"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={submitting}
      submitting={submitting}
      error={error}
      submitTitle={`Remove private IP`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">Select a private IP to remove from the selected VM.</Text>
        <PicklistField
          DropdownComponent={FixedIpsPicklist}
          id="port"
          label="Private IP to Remove"
          tooltip="List of private IPs attached to the selected VM"
          onChange={getParamsUpdater('port')}
          value={params.port}
          vmId={vm?.id}
          required
        />
      </div>
    </ModalForm>
  )
}
