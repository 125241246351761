import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const kaapiNodesSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiNodes>(DataKeys.KaapiNodes),
  (kaapiNodes): any[] => {
    return kaapiNodes.map((kaapiNode) => {
      return kaapiNode
    })
  },
)
