import yaml from 'js-yaml'

/**
 * Extracts the latest version for each minor version from the given config maps.
 */
export const extractSupportedK8sVersions = (configMaps: any[]): string[] => {
  if (!Array.isArray(configMaps) || configMaps.length === 0) return []

  const allVersions = new Set<string>()

  for (const configMap of configMaps) {
    // Check if the config map is a cluster version config map
    const name = configMap.metadata?.name || ''
    if (!name.includes('cluster-version')) continue

    // Check if the config map has supportedVersion label and it is set to true
    const labels = configMap.metadata?.labels
    if (
      !Object.keys(labels).includes('kaapi.pf9.io/supportedVersion') ||
      !configMap.metadata?.labels['kaapi.pf9.io/supportedVersion']
    )
      continue

    const data = configMap.data || {}
    if (!data.supportedVersions) continue

    let versions: string[]
    try {
      // Parse supportedVersions into an array, ensuring it handles multiple versions correctly
      versions = data.supportedVersions
        .split('\n')
        .map((version) => version.replace(/^-\s*/, '').trim()) // Remove the "- " part
        .filter(Boolean)
    } catch (error) {
      console.error('Error parsing supported versions in config map:', error)
      continue
    }

    // Add all versions to the Set to avoid duplicates
    versions.forEach((version) => {
      allVersions.add(version)
    })
  }

  // Convert the Set back to an array, sort it and return in descending order
  return Array.from(allVersions)
    .sort(compareVersions)
    .reverse() // Sort in descending order
}
/**
 * Compares two semantic versions.
 * Returns:
 * - 1 if versionA > versionB
 * - -1 if versionA < versionB
 * - 0 if equal
 */
// const compareVersions = (versionA, versionB) => {
//   const [majorA, minorA, patchA] = versionA.split('.').map(Number)
//   const [majorB, minorB, patchB] = versionB.split('.').map(Number)

//   if (majorA !== majorB) return majorA - majorB
//   if (minorA !== minorB) return minorA - minorB
//   return patchA - patchB
// }
const compareVersions = (versionA: string, versionB: string): number => {
  const [majorA, minorA, patchA] = versionA.split('.').map(Number)
  const [majorB, minorB, patchB] = versionB.split('.').map(Number)

  if (majorA !== majorB) return majorA - majorB
  if (minorA !== minorB) return minorA - minorB
  return patchA - patchB
}
