import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { getDeleteConfirmText } from 'core/components/CRUDListContainer'
import React, { useState, useMemo } from 'react'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useListAction from 'core/hooks/useListAction'
import { deleteKaapiCluster, listKaapiClusters } from '../kaapi/clusters/actions'

const DeleteK8sClustersDialog = ({ rows, onClose }) => {
  const [error, setError] = useState(null)
  const { reload } = useListAction(listKaapiClusters)

  const { update: deleteKaapiClusterFn, updating: deletingKaapiCluster } = useUpdateAction(
    deleteKaapiCluster,
  )

  const deleteConfirmText = useMemo(
    () => getDeleteConfirmText(rows.flatMap((row) => row?.metadata)),
    [rows],
  )

  const handleSubmit = async () => {
    const promises = []
    for (const cluster of rows) {
      if (!cluster?.kind) return
      promises.push(
        deleteKaapiClusterFn({
          name: cluster?.metadata?.name,
        }),
      )
    }

    Promise.all(promises)
      .then(() => {
        reload(true)
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({ title: 'Error Deleting Cluster', message: err?.message })
      })
  }
  return (
    <ConfirmationDialog
      text={deleteConfirmText}
      onConfirm={handleSubmit}
      onCancel={onClose}
      error={error}
      loading={deletingKaapiCluster}
      open
    />
  )
}

export default DeleteK8sClustersDialog
