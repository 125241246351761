import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import MenuItem from 'core/elements/menu/MenuItem'
import clsx from 'clsx'

const useStyles = makeStyles<Theme>((theme) => ({
  control: {
    display: 'grid',
    gridTemplateColumns: 'auto max-content',
    alignItems: 'center',
    padding: '0px 12px',
    minHeight: 36,
    cursor: 'pointer',
    '&:hover': {
      background: theme.components.checkbox.hoverBackground,
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 12,
  },
  iconLeft: {
    marginRight: 8,
  },
}))

export default function GridRowMenuItemsSection({ label, items, item }) {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const toggleDropdown = useCallback(
    (e) => {
      e.stopPropagation()
      setOpen(!open)
    },
    [open],
  )

  return (
    <>
      <div className={classes.control} onClick={toggleDropdown}>
        <Text variant="body2" className={classes.label}>
          {open && (
            <FontAwesomeIcon solid className={clsx(classes.icon, classes.iconLeft)}>
              chevron-left
            </FontAwesomeIcon>
          )}
          {label}
        </Text>
        {!open && (
          <FontAwesomeIcon solid className={classes.icon}>
            chevron-right
          </FontAwesomeIcon>
        )}
      </div>
      {open &&
        items.map(({ key, icon, label, getIsDisabled, triggerAction }) => (
          <MenuItem
            key={key}
            readonly={getIsDisabled(item)}
            icon={icon}
            onClick={() => triggerAction(item)}
          >
            {label}
          </MenuItem>
        ))}
    </>
  )
}
