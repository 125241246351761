import React, { useCallback } from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Modal from 'core/elements/modal'
import { deletePort } from '../actions'

export default function DeletePort({ rows: [items], onClose }) {
  const { update: deleteFn, updating: deletingItems, error } = useUpdateAction(deletePort)

  const handleDelete = useCallback(async () => {
    const { success } = await deleteFn(items)
    if (success) onClose(true)
  }, [items])

  return (
    <Modal
      panel="dialog"
      title="Delete Port"
      onClose={onClose}
      open
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingItems}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingItems}>
            Yes, delete
          </Button>
        </>
      }
    >
      <>
        <Text variant="body2">This will permanently delete {items?.name}</Text>
        {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
      </>
    </Modal>
  )
}
