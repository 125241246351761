import React, { useCallback, useMemo, useEffect } from 'react'
import ApiClient from 'api-client/ApiClient'
import { listUserTenants } from 'account/components/userManagement/tenants/new-actions'
import { useDispatch, useSelector } from 'react-redux'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { userTenantsSelector } from 'account/components/userManagement/tenants/selectors'
import useListAction from 'core/hooks/useListAction'
import Dropdown from 'core/elements/dropdown/Dropdown'
import { head, propEq, prop } from 'ramda'
import { sessionActions, sessionStoreKey, SessionState } from 'core/session/sessionReducers'
import { cacheActions } from 'core/caching/cacheReducers'
import { isKaapiEnabled } from 'core/utils/helpers'
import { createKaapiApplicationCredentials } from 'k8s/components/kaapi/secrets/actions'

const GlobalTenantDropdown = () => {
  const { keystone } = ApiClient.getInstance()
  const { prefs, updatePrefs } = useScopedPreferences()
  const { currentTenant } = prefs

  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { isSsoToken, domain } = session
  const dispatch = useDispatch()

  const { loading: loadingTenants, reload: reloadTenants } = useListAction(listUserTenants)
  const tenants = useSelector(userTenantsSelector)

  const dropdownOptions = useMemo(() => {
    return tenants.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
    }))
  }, [tenants])

  useEffect(() => {
    if (tenants?.length) {
      // Set currentTenant in prefs on first-ever load
      const tenant =
        tenants.find(propEq('id', currentTenant)) ||
        tenants.find(propEq('name', 'service')) ||
        head(tenants)
      updatePrefs({ currentTenant: tenant.id, currentTenantName: tenant.name })
    }
  }, [tenants])

  const updateCurrentTenant = async (tenantName) => {
    const tenant = tenants.find(propEq('name', tenantName))
    if (!tenant) {
      return
    }

    const { user, role, scopedToken } = await keystone.changeProjectScopeWithToken(
      tenant.id,
      isSsoToken,
    )

    // Set the kaapi tenant for the selected PCD tenant
    if (isKaapiEnabled()) {
      await createKaapiApplicationCredentials()
    }

    dispatch(cacheActions.clearCache())
    dispatch(
      sessionActions.updateSession({
        userDetails: { ...user, role },
        scopedToken,
      }),
    )
  }

  const handleTenantSelect = useCallback(
    async (currentTenantId) => {
      const tenant = tenants.find(propEq('id', currentTenantId))
      if (!tenant) {
        return
      }
      await updateCurrentTenant(tenant.name)
      updatePrefs({ currentTenant: tenant.id, currentTenantName: tenant.name })
    },
    [tenants],
  )

  return (
    <div>
      <Dropdown
        label="Tenant:"
        items={dropdownOptions}
        value={currentTenant}
        onChange={handleTenantSelect}
        compact
      />
    </div>
  )
}

export default GlobalTenantDropdown
