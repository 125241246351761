import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { kaapiMachineDeploymentsSelector } from '../machine-deployment/selectors'
import { kaapiNodesSelector } from '../workload-clusters/nodes/selectors'

export const kaapiMachinesSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiMachines>(DataKeys.KaapiMachines),
  kaapiMachineDeploymentsSelector,
  kaapiNodesSelector,
  (kaapiMachines, machineDeployments, kaapiNodes): any[] => {
    return kaapiMachines.map((kaapiMachine) => {
      // Find the openstack machine template for the machine deployment
      const machineDeployment = machineDeployments?.find((md) => {
        return (
          md?.metadata?.name === kaapiMachine?.metadata?.labels['cluster.x-k8s.io/deployment-name']
        )
      })

      // Find the k8s node for the machine
      const k8sNode = kaapiNodes?.find((node) => {
        return node?.metadata?.name === kaapiMachine.metadata.name
      })

      return {
        ...kaapiMachine,
        uid: kaapiMachine?.metadata?.uid,
        machineDeployment,
        k8sNodeStatus: k8sNode?.status?.conditions?.find((c) => c.status === 'True'),
        cpuUsage: { current: 1, total: 2 },
        memoryUsage: { current: 1.5, total: 4 },
        storageUsage: { current: 5.5, total: 40 },
      }
    })
  },
)

export const kaapiMachinesForClusterSelector = ({ clusterName }): AppSelector<any[]> =>
  createSharedSelector(kaapiMachinesSelector, (kaapiMachinesSelector): any[] => {
    return kaapiMachinesSelector.filter((md) => md.spec.clusterName === clusterName)
  })
