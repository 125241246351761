import { IGenericResource } from 'api-client/qbert.model'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { ConfigMapItem } from 'k8s/components/config-maps/models'
import DataKeys from 'k8s/DataKeys'

export interface IConfigMapSelector extends IGenericResource<ConfigMapItem> {}

export const kaapiConfigMapsSelector = createSharedSelector(
  getDataSelector<DataKeys.KaapiConfigMaps>(DataKeys.KaapiConfigMaps),
  (rawConfigMaps): IConfigMapSelector[] => {
    return rawConfigMaps.map((rawConfigMap) => {
      return rawConfigMap
    })
  },
)

// Selector to get the supported k8s versions from the config maps
export const kaapiSupportedK8sVersionConfigMaps = createSharedSelector(
  getDataSelector<DataKeys.KaapiConfigMaps>(DataKeys.KaapiConfigMaps),
  (rawConfigMaps): String[] => {
    const configMapsForSupportedK8sVersion = rawConfigMaps.filter(
      (configMap) => configMap.metadata.labels?.['kaapi.pf9.io/supportedVersion'],
    )
    console.log('configMapsForSupportedK8sVersion', configMapsForSupportedK8sVersion)
    const supportedK8sVersion = configMapsForSupportedK8sVersion.map(
      (configMap) => configMap?.data?.supportedVersion,
    )
    console.log('supportedK8sVersion', supportedK8sVersion)
    return supportedK8sVersion
  },
)
