import { AppPlugins } from 'app/constants'
import Plugin from 'core/plugins/plugin'

import ClusterDetails from './components/dashboard/ClusterDetails'
import ComingSoonPage from './ComingSoonPage'
import { kubernetesLogo } from './logo'
import HomePage from './pages/HomePage'
import ClusterCapacityAndHealth from './components/capacity-and-health/ClusterCapacityAndHealth'
import ClustersDashboardPage from './components/clusters-dashboard/ClustersDashboardPage'
import ClustersUpgradePage from './components/clusters-dashboard/ClustersUpgradePage'

const kubernetesPlugin = new Plugin(
  AppPlugins.Kubernetes,
  'Kubernetes Clusters',
  '/ui/kubernetes',
  kubernetesLogo,
  'Kubernetes clusters for modern workloads',
)

kubernetesPlugin.registerRoutes([
  {
    name: 'Home',
    link: { path: '/', exact: true, default: true },
    component: HomePage,
  },
  {
    name: 'Dashboard',
    link: { path: '/dashboard/clusters', exact: true, default: true },
    component: ClustersDashboardPage,
  },
  {
    name: 'Overview',
    link: { path: '/clusters/:cluster/manage/overview', exact: true },
    component: ClusterDetails,
  },
  {
    name: 'Upgrade Status Modal',
    link: { path: '/clusters/:cluster/manage/overview/upgrade-status', exact: true },
    component: ClustersUpgradePage,
  },
  {
    name: 'Upgrade',
    link: { path: '/clusters/:cluster/manage/upgrade', exact: true },
    component: ClustersUpgradePage,
  },
  {
    name: 'Capacity & Health',
    link: { path: '/clusters/:cluster/manage/capacity-and-health', exact: true },
    component: ClusterCapacityAndHealth,
  },
  {
    name: 'Alerts',
    link: { path: '/clusters/:cluster/manage/alerts', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Kubernetes Configuration',
    link: { path: '/clusters/:cluster/manage/kubernetes-configuration', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Kubernetes Add-ons',
    link: { path: '/clusters/:cluster/manage/kuberntes-addons', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Load Balancers',
    link: { path: '/clusters/:cluster/deploy/load-balancers', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Apps',
    link: { path: '/clusters/:cluster/deploy/apps', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'API',
    link: { path: '/api', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Docs',
    link: { path: '/docs', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Support',
    link: { path: '/support', exact: true },
    component: ComingSoonPage,
  },
])

// These nav items are in active development but not shown in production.
const navItems = [
  {
    name: 'Dashboard',
    link: { path: '/dashboard/clusters' },
    icon: 'gauge-circle-bolt',
    nestedLinks: [{ name: 'Clusters', link: { path: '/dashboard/clusters' } }],
    showAtTop: true,
  },
  {
    name: 'Manage',
    link: { path: '/clusters/:cluster/manage/overview' },
    icon: 'cloud-word',
    nestedLinks: [
      { name: 'Overview', link: { path: '/clusters/:cluster/manage/overview' } },
      //will add in the future
      // {
      //   name: 'Upgrade',
      //   link: { path: '/clusters/:cluster/manage/upgrade' },
      // },
      {
        name: 'Capacity & Health',
        link: { path: '/clusters/:cluster/manage/capacity-and-health' },
      },
      {
        name: 'Alerts',
        link: { path: '/clusters/:cluster/manage/alerts' },
      },
      {
        name: 'Kubernetes Configuration',
        link: { path: '/clusters/:cluster/manage/kubernetes-configuration' },
      },
      { name: 'Kubernetes Add-ons', link: { path: '/clusters/:cluster/manage/kuberntes-addons' } },
    ],
  },
  {
    name: 'Deploy',
    link: {
      path: '/clusters/:cluster/deploy/load-balancers',
      definition: '/clusters/:cluster/deploy/load-balancers',
    },
    icon: 'rocket',
    nestedLinks: [
      {
        name: 'Load Balancers',
        link: {
          path: '/clusters/:cluster/deploy/load-balancers',
          definition: '/clusters/:cluster/deploy/load-balancers',
        },
      },
      {
        name: 'Apps',
        link: {
          path: '/clusters/:cluster/deploy/apps',
          definition: '/clusters/:cluster/deploy/apps',
        },
      },
    ],
  },
  {
    name: 'Resources',
    link: {
      path: '/api',
      definition: '/configuration/(clusters)?/:id?/:page?/(edit)?',
    },
    icon: 'box-open',
    nestedLinks: [
      {
        name: 'API',
        link: {
          path: '/api',
          definition: '/api',
        },
      },
      {
        name: 'Docs',
        link: {
          path: '/docs',
          definition: '/docs',
        },
      },
      {
        name: 'Support',
        link: {
          path: '/support',
          definition: '/support',
        },
      },
    ],
  },
]

kubernetesPlugin.registerNavItems(navItems)

export default kubernetesPlugin
