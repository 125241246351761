import React, { useEffect, useMemo, useState } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import { listHostNetworkConfigs } from '../actions'
import { hostNetworkConfigsSelector } from '../selectors'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function HostNetworkConfigPicklist({
  disabled = false,
  name = 'hostNetworkConfig',
  label = 'Host Network Config',
  ...rest
}) {
  const { loading } = useListAction(listHostNetworkConfigs)
  const data = useSelector(hostNetworkConfigsSelector)

  const options = useMemo(() => {
    return data.map((config) => {
      return {
        label: config.name || config.id,
        value: config.id,
      }
    })
  }, [data])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
