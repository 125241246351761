import { DashBoardStatusCardTypes } from './card-templates'

import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { omit } from 'ramda'
import { BadgeVariant } from './StatusIconText'
import { getNodeGroupStatuses } from 'app/plugins/infrastructure/components/clusters/capi/helpers'
import { isNilOrEmpty } from 'utils/fp'
import { Phase } from 'app/plugins/infrastructure/components/clusters/capi/machine-deployment/model'
import { CapiClusterPhases } from 'app/plugins/infrastructure/components/clusters/capi/model'
import { ClusterUpgradeStatus } from 'k8s/components/kaapi/cluster-upgrade/model'

export const useDashboardPageStyles = makeStyles<Theme>((theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',

    minWidth: 'max-content',
    maxWidth: 'max-content',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: theme.palette.grey['000'],
    padding: theme.spacing(2, 4),
    border: `solid 1px ${theme.palette.grey[300]}`,
    minHeight: 75,
  },
}))

export const filterReportsWithUserRole = (reports, role) => {
  const mappedReports = reports.map((report) => {
    // No permissions property means no restrictions
    if (!report?.permissions) {
      return report
    }
    // remove the add action when not permitted to
    return report.permissions.includes(role) ? report : { ...report, addRoute: '' }
  })
  const filteredReports = mappedReports.filter((report) => {
    if (!report.overallPermissions) {
      return report
    }
    return report.overallPermissions.includes(role)
  })
  return filteredReports.map(omit(['permissions', 'overallPermissions']))
}

export const baseCards = [
  DashBoardStatusCardTypes.Pod,
  DashBoardStatusCardTypes.Deployment,
  DashBoardStatusCardTypes.Service,
]
export const allReportCardsDefaultOrder = [
  DashBoardStatusCardTypes.Pod,
  DashBoardStatusCardTypes.Deployment,
  DashBoardStatusCardTypes.Service,
  DashBoardStatusCardTypes.User,
  DashBoardStatusCardTypes.Tenant,
]

export interface NodeGroupStatus {
  label: number
  icon: string
  variant: BadgeVariant
}

export const DEFAULT_NODE_GROUP_STATUS = {
  success: {
    label: 0,
    icon: 'circle-check',
    variant: 'success' as BadgeVariant,
  },
  error: {
    label: 0,
    icon: 'triangle-exclamation',
    variant: 'error' as BadgeVariant,
  },
  warning: {
    label: 0,
    icon: 'triangle-exclamation',
    variant: 'warning' as BadgeVariant,
  },
  unknown: {
    label: 0,
    icon: 'circle-question',
    variant: 'unknown' as BadgeVariant,
  },
}

// Generate Node Group status based on the machine deployments
export const generateNodeGroupsStatus = (machineDeployments): Array<NodeGroupStatus> => {
  const nodeGroupsStatus = JSON.parse(JSON.stringify(DEFAULT_NODE_GROUP_STATUS))

  machineDeployments?.forEach((machineDeployment) => {
    switch (machineDeployment?.status?.phase) {
      case 'Ready':
      case 'Running':
        nodeGroupsStatus.success.label++
        break
      case 'Failed':
      case 'Error':
        nodeGroupsStatus.error.label++
        break
      case 'ScalingUp':
      case 'ScalingDown':
        nodeGroupsStatus.warning.label++
        break
      default:
        nodeGroupsStatus.unknown.label++
        break
    }
  })

  return Object.values(nodeGroupsStatus).filter(
    (status: NodeGroupStatus) => status.label > 0,
  ) as Array<NodeGroupStatus>
}

export const generateWorkerNodesStatus = (machines): Array<NodeGroupStatus> => {
  const workerNodesStatus = JSON.parse(JSON.stringify(DEFAULT_NODE_GROUP_STATUS))

  machines?.forEach((machines) => {
    switch (machines?.status?.phase) {
      case 'Provisioned':
      case 'Running':
        workerNodesStatus.success.label++
        break
      case 'Provisioning':
      case 'SaclingUp':
      case 'SaclingDown':
        workerNodesStatus.warning.label++
        break
      case 'Failed':
      case 'Error':
        workerNodesStatus.error.label++
        break
      default:
        workerNodesStatus.unknown.label++
        break
    }
  })

  return Object.values(workerNodesStatus).filter(
    (status: NodeGroupStatus) => status.label > 0,
  ) as Array<NodeGroupStatus>
}

export const isClusterDeleting = (cluster) => {
  return cluster?.status?.phase?.toLowerCase() === 'deleting'
}

export const isKaapiClusterHealthy = (cluster) => {
  const nodeGroups = cluster?.machineDeployments
  if (isNilOrEmpty(nodeGroups)) return false

  const statuses = getNodeGroupStatuses(nodeGroups)
  const allNodesRunning = statuses[Phase.Running]?.count === nodeGroups.length
  return (
    cluster?.status?.phase === CapiClusterPhases.Provisioned &&
    cluster?.hostedControlPlane?.status?.ready &&
    allNodesRunning
  )
}

export const generateClustersStatus = (clusterDeployments): Array<NodeGroupStatus> => {
  const clustersStatus = JSON.parse(JSON.stringify(DEFAULT_NODE_GROUP_STATUS))

  clusterDeployments?.forEach((clusterDeployment) => {
    const isClusterHealthy = isKaapiClusterHealthy(clusterDeployment)
    if (isClusterHealthy) {
      clustersStatus.success.label++
    } else {
      clustersStatus.error.label++
    }
  })

  return Object.values(clustersStatus).filter(
    (status: NodeGroupStatus) => status.label > 0,
  ) as Array<NodeGroupStatus>
}

//TODO:: Need to update logic once upgrade feature is implemented
export const generateUpgradesStatus = (upgradeJobs): Array<NodeGroupStatus> => {
  const upgradesStatus = JSON.parse(JSON.stringify(DEFAULT_NODE_GROUP_STATUS))

  upgradeJobs?.forEach((upgradeJob) => {
    const isUpgradeCompleted = upgradeJob?.status?.phase === ClusterUpgradeStatus.Completed
    const isUpgradeInProgress = upgradeJob?.status?.phase === ClusterUpgradeStatus.InProgress
    const isUpgradeFailed = upgradeJob?.status?.phase === ClusterUpgradeStatus.Failed
    if (isUpgradeCompleted) {
      upgradesStatus.success.label++
    } else if (isUpgradeInProgress) {
      upgradesStatus.warning.label++
    } else if (isUpgradeFailed) {
      upgradesStatus.error.label++
    } else {
      upgradesStatus.unknown.label++
    }
  })

  return Object.values(upgradesStatus).filter(
    (status: NodeGroupStatus) => status.label > 0,
  ) as Array<NodeGroupStatus>
}

export const getBadgeStyles = (variant: BadgeVariant) => {
  switch (variant) {
    case 'success':
      return {
        badgeColor: 'teal',
        badgeColorShade: 100,
        badgeBgColor: 'teal',
        badgeBgColorShade: 50,
      }
    case 'error':
      return {
        badgeColor: 'pink',
        badgeColorShade: 800,
        badgeBgColor: 'peach',
        badgeBgColorShade: 50,
      }
    case 'warning':
      return {
        badgeColor: 'red',
        badgeColorShade: 400,
        badgeBgColor: 'peach',
        badgeBgColorShade: 100,
      }
    case 'unknown':
      return {
        badgeColor: 'grey',
        badgeColorShade: 500,
        badgeBgColor: 'grey',
        badgeBgColorShade: 100,
      }
    default:
      return {
        badgeColor: 'grey',
        badgeColorShade: 300,
        badgeBgColor: 'grey',
        badgeBgColorShade: 100,
      }
  }
}

export function isTimeExpired(timeString) {
  const givenTime = new Date(timeString)
  const currentTime = new Date()
  // Add two days to the given time
  const givenTimePlusTwoDays = new Date(givenTime.getTime() + 2 * 24 * 60 * 60 * 1000)
  return givenTimePlusTwoDays > currentTime
}
export const getClusterEndpoint = (endpoint) => (endpoint ? `https://${endpoint}` : '')
