import React, { useCallback, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createImage, uploadImage, importImage } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import RadioFields, { Orientation } from 'core/components/validatedForm/radio-fields'
import FileDrop from 'core/elements/FileDrop'
import LargeFileDrop from 'core/elements/LargeFileDrop'
import PicklistField from 'core/components/validatedForm/DropdownField'
import ContainerFormatPicklist from './ContainerFormatPicklist'
import DiskFormatPicklist from './DiskFormatPicklist'
// import VisibilityPicklist from './VisibilityPicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { useSelector } from 'react-redux'
import { isAdminRole } from 'k8s/util/helpers'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { prop } from 'ramda'
import Text from 'core/elements/Text'
import Progress from 'core/components/progress/Progress'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
}

export const imageUploadRadioOptions = [
  {
    value: 'file',
    label: 'Upload File',
    info: 'Select an image file to upload.',
  },
  {
    value: 'url',
    label: 'Upload via URL.',
    info: 'Select an image that is accessible for download via a URL.',
  },
]

export default function AddImageModal({ addRoute }: Props) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const defaultParams = {
    name: '',
    method: 'file',
    containerFormat: '',
    diskFormat: '',
    url: '',
    public: false,
    protected: false,
  }
  const [fileData, setFileData] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const isAdmin = useMemo(() => isAdminRole(session), [session])

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { update: create, updating: creating, error, reset: resetCreate } = useUpdateAction(
    createImage,
  )
  const {
    update: upload,
    updating: uploading,
    error: uploadError,
    reset: resetUpload,
  } = useUpdateAction(uploadImage)
  const {
    update: importFn,
    updating: importing,
    error: importError,
    reset: resetImport,
  } = useUpdateAction(importImage)

  const submitForm = useCallback(async () => {
    if (!fileData) {
      setFormSubmitted(true)
      return
    }

    const body = {
      name: params.name,
      container_format: params.containerFormat ? params.containerFormat : undefined,
      disk_format: params.diskFormat ? params.diskFormat : undefined,
      protected: params.protected,
      visibility: isAdmin ? (params.public ? 'public' : 'private') : undefined,
    }
    const importBody = {
      method: {
        name: 'web-download',
        uri: params.url,
      },
      all_stores: true,
      all_stores_must_succeed: true,
    }
    const { success, response } = await create({ body })
    const imageId = response?.id
    const { success: uploadSuccess } =
      params.method === 'file'
        ? await upload({ id: imageId, body: fileData })
        : await importFn({ id: imageId, body: importBody })
    if (success || uploadSuccess) {
      handleClose()
    }
  }, [params, fileData, isAdmin])

  const handleClose = () => {
    setParams(defaultParams)
    resetCreate()
    resetUpload()
    resetImport()
    history.push(routes.openstack.images.path())
  }

  return (
    <ModalForm
      route={addRoute}
      title={`Add Image`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={creating || uploading || importing}
      error={error || uploadError || importError}
      submitTitle={`Add Image`}
    >
      <>
        {uploading && <Progress loading message={'Uploading Image'} />}
        {!uploading && (
          <>
            <FormFieldSection title="Image Selection">
              {/* <RadioFields
            id="method"
            orientation={Orientation.Column}
            title="Image Upload Method"
            options={imageUploadRadioOptions}
            value={params.method}
            onChange={getParamsUpdater('method')}
          /> */}
              <Text variant="body2">
                <b>Note:</b> Images larger than <b>1GB</b> are recommended to be imported via CLI
                rather than through file upload.
              </Text>
              {params.method === 'file' && (
                <LargeFileDrop
                  onChange={(value) => {
                    setFileData(value)
                    setFormSubmitted(false)
                  }}
                  onChangeName={(name) => {
                    if (params.name) {
                      return
                    }
                    updateParams({ name })
                  }}
                  fileTypes={[
                    '.img',
                    '.iso',
                    '.bare',
                    '.ovf',
                    '.ova',
                    '.vhd',
                    '.vhdx',
                    '.vmdk',
                    '.raw',
                    '.qcow2',
                  ]}
                />
              )}
              {!fileData && formSubmitted && <ErrorMessage>Upload file</ErrorMessage>}
              {params.method === 'url' && (
                <TextField
                  id="url"
                  label="Image Download URL"
                  onChange={getParamsUpdater('url')}
                  value={params.url}
                  required
                />
              )}
            </FormFieldSection>
            <FormFieldSection title="Image Settings">
              <TextField
                id="name"
                label="Name"
                onChange={getParamsUpdater('name')}
                value={params.name}
                required
              />
              <PicklistField
                DropdownComponent={ContainerFormatPicklist}
                id="containerFormat"
                onChange={getParamsUpdater('containerFormat')}
                value={params.containerFormat}
                label="Container Format"
                required
              // tooltip=""
              />
              <PicklistField
                DropdownComponent={DiskFormatPicklist}
                id="diskFormat"
                onChange={getParamsUpdater('diskFormat')}
                value={params.diskFormat}
                label="Disk Format"
                required
              // tooltip=""
              />
              {/* <PicklistField
            DropdownComponent={VisibilityPicklist}
            id="visibility"
            onChange={getParamsUpdater('visibility')}
            value={params.visibility}
            label="Visibility"
            tooltip=""
            // required
          /> */}
              {!!isAdmin && (
                <CheckboxField
                  id="public"
                  label="Make Public"
                  onChange={getParamsUpdater('public')}
                  value={params.public}
                  info="Public images will be available across all tenants. Private images will only be available within the same tenant."
                />
              )}
              <CheckboxField
                id="protected"
                label="Protected"
                onChange={getParamsUpdater('protected')}
                value={params.protected}
                info="Protect images from deletion."
              />
            </FormFieldSection>
          </>
        )}
      </>
    </ModalForm>
  )
}
