import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BlueprintTypes } from 'app/plugins/openstack/components/infrastructure/blueprint/model'
import { mergeLeft } from 'ramda'

export interface SessionState {
  unscopedToken: string
  scopedToken: string
  username: string
  expiresAt: string
  userDetails: { [key: string]: any }
  features: { [key: string]: any }
  isSsoToken: boolean
  onboardingRedirectToUrl: string
  activeRegion: string
  loadingRegion: boolean
  blueprintType: BlueprintTypes
  virtualNetworking: boolean
  underlayNetwork: {
    type: string
    label: string
    ranges: string
  }
  domain: {
    id: string
    name: string
  }
  tokenData: {
    expiresAt: string
    issuedAt: string
  }
  jwtToken: string
  activeTenant: string
  activeKaapiTenant: string
  kaapiTenantList: Array<string>
}

export const initialState: SessionState = {
  unscopedToken: null,
  scopedToken: null,
  username: null,
  expiresAt: null,
  userDetails: {},
  features: {},
  isSsoToken: false,
  onboardingRedirectToUrl: null,
  activeRegion: null,
  loadingRegion: true,
  virtualNetworking: null,
  blueprintType: null,
  underlayNetwork: null,
  domain: null,
  tokenData: {
    expiresAt: null,
    issuedAt: null,
  },
  jwtToken: null,
  activeTenant: null,
  activeKaapiTenant: null,
  kaapiTenantList: [],
}

const { name: sessionStoreKey, reducer: sessionReducers, actions: sessionActions } = createSlice({
  name: 'session',
  initialState,
  reducers: {
    initSession: (state, { payload }: PayloadAction<Partial<SessionState>>) => {
      return mergeLeft(payload, initialState)
    },
    updateSession: (state, { payload }: PayloadAction<Partial<SessionState>>) => {
      return mergeLeft(payload, state)
    },
    destroySession: () => {
      return initialState
    },
  },
})

export { sessionStoreKey, sessionActions }
export default sessionReducers
