import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'

interface StyleProps {
  percent: number
  height?: number
}

const getBackgroundColor = ({ theme, percent }) => {
  if (percent < 50) {
    return theme.palette.graphGreen[100]
  } else if (percent < 80) {
    return theme.palette.graphYellow[100]
  } else {
    return theme.palette.graphRed[100]
  }
}

const getBackgroundBorderColor = ({ theme, percent }) => {
  if (percent < 50) {
    return theme.palette.graphGreen[500]
  } else if (percent < 80) {
    return theme.palette.graphYellow[500]
  } else {
    return theme.palette.graphRed[500]
  }
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    display: 'grid',
    gap: 8,
  },
  labelRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
  graphContainer: {
    height: ({ height }) => (height ? height : 16),
    width: '100%',
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  graph: {
    height: ({ height }) => (height ? height : 16),
    width: ({ percent }) => (percent > 100 ? '100%' : `${percent}%`),
    background: ({ percent }) => getBackgroundColor({ percent, theme }),
    border: ({ percent }) => `1px solid ${getBackgroundBorderColor({ percent, theme })}`,
    position: 'relative',
    top: -1,
    left: -1,
  },
}))

interface Props {
  label?: string
  unit?: string
  usage: number
  capacity: number
}

const getPercent = ({ usage, capacity }) => {
  if (!usage || !capacity) {
    return 0
  }
  if (capacity === -1) {
    return 0
  }
  const percent = usage / capacity
  return Math.round(percent * 10000) / 100
}

export default function ProgressGraph({ label, unit, usage, capacity }: Props) {
  const percent = getPercent({ usage, capacity })
  const classes = useStyles({ percent })

  return (
    <div className={classes.container}>
      {!!label && (
        <div className={classes.labelRow}>
          <div className={classes.label}>
            <Text variant="subtitle2">{label}</Text>
            {!!unit && <Text variant="body1">({unit})</Text>}
          </div>
          <div className={classes.label}>
            <Text variant="subtitle2">
              {usage}/{capacity === -1 ? 'unlimited' : capacity}
            </Text>
            <Text variant="body1">({percent}%) used</Text>
          </div>
        </div>
      )}
      <div className={classes.graphContainer}>
        {percent > 0 && <div className={classes.graph}></div>}
      </div>
    </div>
  )
}
