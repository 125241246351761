import Bugsnag from '@bugsnag/js'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import { dummyData } from 'k8s/components/kaapi/cluster-upgrade/dummyData'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { kaapi } = ApiClient.getInstance()

export const clusterUpgradeJobActions = ActionsSet.make<DataKeys.KaapiClusterUpgradeJobs>({
  uniqueIdentifier: 'metadata.name',
  entityName: entityNamesByKey[DataKeys.KaapiClusterUpgradeJobs],
  cacheKey: DataKeys.KaapiClusterUpgradeJobs,
})

export const listKaapiClusterUpgradeJobs = clusterUpgradeJobActions.add(
  new ListAction<DataKeys.KaapiClusterUpgradeJobs>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get all Cluster Upgrade Jobs')
    return kaapi.getAllClusterUpgradeJobs()
  }),
)
