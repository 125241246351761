import React from 'react'
import PercentPieGraph, { PercentPieGraphProps } from './PercentPieGraph'

export default function PercentSemiCircleGraph({
  sideLength = 216,
  ...props
}: PercentPieGraphProps) {
  const height = sideLength / 2 + 20
  return (
    <PercentPieGraph
      {...props}
      sideLength={sideLength}
      startAngle={0}
      endAngle={180}
      height={height}
    />
  )
}
