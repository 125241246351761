import React, { useCallback, useEffect, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listSubnets, updatePort } from '../actions'
import { subnetsSelector } from '../selectors'
import { listSecurityGroups } from '../security-groups/actions'
import { securityGroupsSelector } from '../security-groups/selectors'
import { useSelector } from 'react-redux'
import ModalForm from 'core/elements/modal/ModalForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import TextField from 'core/components/validatedForm/TextField'
import AdminStatePicklist from '../AdminStatePicklist'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import PicklistField from 'core/components/validatedForm/DropdownField'
import SubnetPicklist from './SubnetPicklist'
import {
  securityGroupColumns,
  getFixedIpValidator,
  getSubnetRequiredValidator,
  ipAddressValidator,
  macAddressValidator,
} from './CreatePortModal'
import { prop, remove, update } from 'ramda'
import VmPicklist from './VmPicklist'
import { addFixedIpToVm } from '../../vms/actions'

const useStyles = makeStyles<Theme>((theme) => ({
  operationLabel: {
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
  },
  icon: {
    color: theme.components.badge.primary.color,
  },
  pairFields: {
    display: 'grid',
    gap: 16,
    marginTop: 16,
    paddingLeft: 24,
  },
}))

export default function EditPortModal({ rows: [port], onClose }) {
  const [loaded, setLoaded] = useState(false)
  const classes = useStyles()

  const defaultParams = {}
  const { params, getParamsUpdater, setParams, updateParams } = useParams<{
    name?: string
    adminState?: string
    allowedAddressPairs?: any[]
    macAddress?: string
    applyPortSecurity?: boolean
    securityGroups?: any[]
    fixedIps?: any[]
    assignedVm?: null | string
  }>(defaultParams)

  const { loading: loadingSecurityGroups } = useListAction(listSecurityGroups, {
    params: {},
  })
  const securityGroupsList = useSelector(securityGroupsSelector)

  const { loading: loadingSubnets, reload } = useListAction(listSubnets, {})
  const subnetsList = useSelector(subnetsSelector)
  const { update: updateFn, updating, error, reset } = useUpdateAction(updatePort)
  const { update: assignPortToVm, updating: vmUpdating, error: vmError, reset: vmReset } =
    useUpdateAction(addFixedIpToVm)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded) {
      return
    }
    updateParams({
      name: port.name,
      adminState: port.admin_state_up ? 'Up' : 'Down',
      allowedAddressPairs: port.allowed_address_pairs?.map((pair) => ({
        ipAddress: pair.ip_address,
        macAddress: pair.mac_address,
      })),
      macAddress: port.mac_address,
      applyPortSecurity: port.port_security_enabled,
      securityGroups: [],
      assignedVm: port.device_id ? port.device_id : null,
    })
    setLoaded(true)
  }, [port, loaded])

  useEffect(() => {
    if (loadingSubnets) {
      return
    }
    updateParams({
      fixedIps: port.fixed_ips?.map((ip) => ({
        fixedIp: ip?.ip_address,
        subnet: subnetsList.find((s) => s.id === ip?.subnet_id),
      })),
    })
  }, [port, loadingSubnets, subnetsList])

  useEffect(() => {
    if (loadingSecurityGroups) {
      return
    }
    const currentSecurityGroups = securityGroupsList.filter((s) =>
      port?.security_groups?.includes(s.id),
    )
    updateParams({ securityGroups: currentSecurityGroups })
  }, [port, loadingSecurityGroups, securityGroupsList])

  const addNewVirtualMachine = useCallback(() => {
    updateParams({
      assignedVm: "",
    })
  }, [params.assignedVm])

  const removeVirtualMachine = useCallback(
    () => {
      updateParams({
        assignedVm: null,
      })
    },
    [params.assignedVm],
  )

  const addNewFixedIp = useCallback(() => {
    updateParams({
      fixedIps: [...params.fixedIps, { subnet: null, fixedIp: '' }],
    })
  }, [params.fixedIps])

  const updateFixedIp = useCallback(
    ({ idx, ip, values }) => {
      const updatedIp = {
        ...ip,
        ...values,
      }
      const updatedIps = update(idx, updatedIp, params.fixedIps)
      updateParams({
        fixedIps: updatedIps,
      })
    },
    [params.fixedIps, updateParams],
  )

  const removeFixedIp = useCallback(
    (index) => {
      updateParams({
        fixedIps: remove(index, 1, params.fixedIps),
      })
    },
    [params.fixedIps],
  )

  const updatePair = useCallback(
    ({ idx, pair, values }) => {
      const updatedPair = {
        ...pair,
        ...values,
      }
      const updatedPairs = update(idx, updatedPair, params.allowedAddressPairs)
      updateParams({
        allowedAddressPairs: updatedPairs,
      })
    },
    [params.allowedAddressPairs, updateParams],
  )

  const addNewPair = useCallback(() => {
    updateParams({
      allowedAddressPairs: [...params.allowedAddressPairs, { ipAddress: '', macAddress: '' }],
    })
  }, [params.allowedAddressPairs])

  const removePair = useCallback(
    (index) => {
      updateParams({
        allowedAddressPairs: remove(index, 1, params.allowedAddressPairs),
      })
    },
    [params.allowedAddressPairs],
  )

  const updatePortFn = async (params, portId) => {
    const addressPairs = params.allowedAddressPairs.map((pair) => {
      return {
        ip_address: pair.ipAddress,
        mac_address: pair.macAddress ? pair.macAddress : undefined,
      }
    })
    const definedFixedIps = params.fixedIps?.filter((ip) => ip.fixedIp || ip.subnet)
    const body = {
      port: {
        name: params.name,
        fixed_ips: definedFixedIps?.length
          ? definedFixedIps.map((ip) => ({
            ip_address: ip?.fixedIp ? ip.fixedIp : undefined,
            subnet_id: ip?.subnet?.id,
          }))
          : undefined,
        mac_address: params.macAddress ? params.macAddress : undefined,
        port_security_enabled: params.applyPortSecurity,
        admin_state_up: params.adminState === 'Up' ? true : false,
        allowed_address_pairs: addressPairs,
        // Only include security groups if port security is enabled
        security_groups: params.applyPortSecurity
          ? params.securityGroups.map((securityGroup) => securityGroup.id)
          : [],
      },
    }
    const { success } = await updateFn({ id: portId, body })
    return { success }
  }

  const submitForm = useCallback(async () => {
    const { success } = await updatePortFn(params, port.id)
    if (success && params.assignedVm) {
      const body = {
        interfaceAttachment: {
          port_id: port?.id,
        },
      }      
      const { success: vmSuccess } = await assignPortToVm({
        id: params.assignedVm,
        body,
      })

      reload(true, true)
      if (vmSuccess) {
        handleClose()
      }
      return
    }
    if (success) {
      reload(true, true)
      handleClose()
    }
  }, [params, port.id])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    vmReset()
    onClose()
  }

  return (
    <ModalForm
      open
      title={`Edit Port`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating || vmUpdating}
      error={error || vmError}
      submitTitle={`Update Port`}
      loading={!loaded}
    >
      <FormFieldSection title="Port Configuration">
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          required
        />
        <AdminStatePicklist value={params.adminState} onChange={getParamsUpdater('adminState')} />
        <TextField
          id="macAddress"
          label="MAC Address"
          onChange={getParamsUpdater('macAddress')}
          value={params.macAddress}
        />
      </FormFieldSection>
      <FormFieldSection title="Assign Private IP Addresses">
        {params.fixedIps?.map((ip, idx) => (
          <div key={idx}>
            <div className={classes.operationLabel} onClick={() => removeFixedIp(idx)}>
              <FontAwesomeIcon className={classes.icon} size="lg" solid>
                circle-minus
              </FontAwesomeIcon>
              <Text variant="subtitle2">Private IP {idx + 1}</Text>
            </div>
            <div className={classes.pairFields}>
              <PicklistField
                DropdownComponent={SubnetPicklist}
                name="subnet"
                id={`fixedIps.${idx}.subnet`}
                label="Subnet"
                compact={false}
                value={ip?.subnet}
                onChange={(value) => {
                  updateFixedIp({ idx, ip, values: { subnet: value } })
                }}
                networkId={port?.network_id}
              />
              <TextField
                id={`fixedIps.${idx}.fixedIp`}
                label="Private IP"
                onChange={(value) => {
                  updateFixedIp({ idx, ip, values: { fixedIp: value } })
                }}
                value={ip?.fixedIp}
                info="IP Address must be within the subnet CIDR"
                validations={[
                  getFixedIpValidator(ip.subnet),
                  getSubnetRequiredValidator(ip.subnet),
                ]}
              />
            </div>
          </div>
        ))}
        <div className={classes.operationLabel} onClick={addNewFixedIp}>
          <FontAwesomeIcon className={classes.icon} size="lg" solid>
            circle-plus
          </FontAwesomeIcon>
          <Text variant="body2">Add Private IP Address</Text>
        </div>
      </FormFieldSection>
      <FormFieldSection title="Security Groups">
        <CheckboxField
          id="applyPortSecurity"
          label="Apply Port Security Groups"
          value={params.applyPortSecurity}
          onChange={getParamsUpdater('applyPortSecurity')}
          info="If checked, enable security groups for this port."
        />
        {params.applyPortSecurity && (
          <ListTableField
            id="securityGroups"
            data={securityGroupsList}
            loading={loadingSecurityGroups}
            columns={securityGroupColumns}
            onChange={getParamsUpdater('securityGroups')}
            value={params.securityGroups}
            uniqueIdentifier="id"
            searchTargets={['name']}
            multiSelection
            required
          />
        )}
      </FormFieldSection>
      <FormFieldSection title="Allowed Address Pairs">
        <Text variant="body2">
          A server connected to this port can send a packet with source address which matches one of
          the specified allowed address pairs.
        </Text>
        {params.allowedAddressPairs?.map((pair, idx) => (
          <div key={idx}>
            <div className={classes.operationLabel} onClick={() => removePair(idx)}>
              <FontAwesomeIcon className={classes.icon} size="lg" solid>
                circle-minus
              </FontAwesomeIcon>
              <Text variant="subtitle2">Pair {idx + 1}</Text>
            </div>
            <div className={classes.pairFields} key={idx}>
              <TextField
                id={`allowedAddressPairs.${idx}.ipAddress`}
                label="IP Address or CIDR"
                onChange={(value) => {
                  updatePair({ idx, pair, values: { ipAddress: value } })
                }}
                value={pair.ipAddress}
                placeholder="eg. 12.12.11.12"
                required
                validations={[ipAddressValidator]}
              />
              <TextField
                id={`allowedAddressPairs.${idx}.macAddress`}
                label="MAC Address"
                onChange={(value) => {
                  updatePair({ idx, pair, values: { macAddress: value } })
                }}
                value={pair.macAddress}
                placeholder="eg. fa:14:2a:b3:cb:f0"
                info="MAC address will be taken from the port if not specified"
                validations={[macAddressValidator]}
              />
            </div>
          </div>
        ))}
        <div className={classes.operationLabel} onClick={addNewPair}>
          <FontAwesomeIcon className={classes.icon} size="lg" solid>
            circle-plus
          </FontAwesomeIcon>
          <Text variant="body2">Add Pair</Text>
        </div>
      </FormFieldSection>
      <FormFieldSection title="Assign Virtual Machine (Optional)">
        {params.assignedVm !== null &&
          <div key={'virtualMachine'}>
            <div className={classes.operationLabel} onClick={removeVirtualMachine}>
              <FontAwesomeIcon className={classes.icon} size="lg" solid>
                circle-minus
              </FontAwesomeIcon>
              <Text variant="subtitle2">Virtual Machine</Text>
            </div>
            <div className={classes.pairFields}>
              <PicklistField
                DropdownComponent={VmPicklist}
                name="virtualMachine"
                id={'assignedVm'}
                label="Virtual Machines"
                compact={false}
                value={params.assignedVm}
                onChange={getParamsUpdater('assignedVm')}
              />
            </div>
          </div>
        }
        {params.assignedVm === null &&
          <div className={classes.operationLabel} onClick={addNewVirtualMachine}>
            <FontAwesomeIcon className={classes.icon} size="lg" solid>
              circle-plus
            </FontAwesomeIcon>
            <Text variant="body2">Add a Virtual Machine</Text>
          </div>
        }
      </FormFieldSection>
    </ModalForm>
  )
}
