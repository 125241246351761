import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import Text from 'core/elements/Text'
import DocumentMeta from 'core/components/DocumentMeta'
import useParams from 'core/hooks/useParams'
// import SubmitButton from 'core/components/buttons/SubmitButton'
import Button from 'core/elements/button'
import Progress from 'core/components/progress/Progress'
// import BlueprintTypeCards from './BlueprintTypeCards'
import TextField from 'core/components/validatedForm/TextField'
import {
  getBlueprints,
  getBlueprint,
  createBlueprint,
  updateBlueprint,
  getHostNetworkConfigs,
  createHostNetworkConfigs,
  updateHostNetworkConfig,
  deleteHostNetworkConfig,
  getResmgrRoleConfig,
  listHostNetworkConfigs,
} from '../actions'
import {
  rangeLabelMap,
  rangeTooltipMap,
  rangePlaceholderMap,
  allSystemTrafficSelected,
} from './helpers'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { BlueprintTypes } from './model'
import { equals, keys, uniq, values, without } from 'ramda'
import { useDispatch } from 'react-redux'
import { sessionActions } from 'core/session/sessionReducers'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { isReadonlyUser } from 'core/utils/helpers'
import ResourceRebalancingCards from './ResourceRebalancingCards'
// import RebalancingConcurrencyPicklist from './RebalancingConcurrencyPicklist'
import RebalancingFrequencyPicklist from './RebalancingFrequencyPicklist'
import StorageVolumeTypeTable from './volume-backend-configs/StorageVolumeTypeTable'
import PicklistField from 'core/components/validatedForm/DropdownField'
import UnderlayNetworkTypesPicklist from './UnderlayNetworkTypesPicklist'
import HostNetworkConfigurations from './host-network-configs/HostNetworkConfigurations'
import { customValidator } from 'core/utils/fieldValidators'
import { getUnderlayInfo } from 'core/containers/AuthContainer'
import StorageTypeLogos from './StorageTypeLogos'
import { listResmgrHosts } from '../actions'
import { resmgrHostsSelector } from '../selectors'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import uuid from 'uuid'

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  cardBody: {
    display: 'grid',
    gap: 12,
  },
  formHeader: {
    display: 'grid',
    gap: 12,
  },
  submitButtonContainer: {
    display: 'flex',
    gap: 16,
  },
  submitWarning: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  warningColor: {
    color: theme.palette.yellow.main,
  },
  formsContainer: {
    display: 'grid',
    gap: 32,
  },
  switchDescription: {
    paddingLeft: 56,
  },
  explicitUpdate: {
    display: 'grid',
    gap: 16,
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'flex-end',
  },
  errors: {
    display: 'grid',
    gap: 8,
  },
  fieldLabel: {
    margin: '0px 4px',
  },
  switchSection: {
    marginLeft: 56,
    display: 'grid',
    gap: 12,
  },
  nameField: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: 8,
    alignItems: 'center',
  },
}))

export const networkingTypeRadioOptions = [
  {
    value: 'ovn',
    label: 'Open Virtual Network (OVN)',
    info: 'Use OVN for networking backend',
  },
  {
    value: 'ovs',
    label: 'Open vSwitch (OVS)',
    info: 'Use OVS for networking backend',
  },
]

const isCustomDriver = (driver) => {
  return ![
    'Ceph',
    'LVM',
    'NFS',
    'NetApp',
    'PureStorageISCSI',
    'PureStorageFC',
    'PureStorageNVME',
    'HuaweiISCSI',
    'HuaweiFC',
    'HPE3PARISCSI',
    'HPE3PARFC',
  ].includes(driver)
}

const imageLibraryLocationValidation = customValidator((str, props) => {
  if (!str) {
    return true
  }
  if (str[0] === '/') {
    return true
  }
  if (props?.volumeTypes?.map((type) => type.type)?.includes(str)) {
    return true
  }
  return false
}, 'Must be a filesystem path or match a volume type name defined above')

const stringToBoolean = (str) => {
  if (str?.toLowerCase() === 'true') {
    return true
  } else if (str?.toLowerCase() === 'false') {
    return false
  }
  return null
}

export default function ClusterBlueprintPage() {
  const classes = useStyles({})
  const defaultParams = {
    name: '',
    blueprintType: BlueprintTypes.MultiTenant,
    dnsDomainName: 'localdomain',
    imageLibraryLocation: '/var/opt/imagelibrary/data',
    vmStoragePath: '/opt/data/instances/',
    // segmentationId: '',
    networkingType: 'ovn',
    enableHighAvailability: false,
    livenessCheckFrequency: 'default',
    enableResourceRebalancing: true,
    rebalancingStrategy: 'vm_workload_consolidation',
    rebalancingConcurrency: '1',
    rebalancingFrequency: '20',
    enableDvr: true,
    enableVirtualNetworking: true,
    segmentationTechnology: 'vlan',
    segmentationRange: '',
    hostNetworkConfigs: [],
    volumeTypes: [],
    volumeBackendConfigs: [],
    newVolumeTypeName: '',
    // physNetMap: [{ interface: '', range: '', label: 'vm-net' }],
  }
  const dispatch = useDispatch()

  const { params, updateParams, getParamsUpdater } = useParams(defaultParams)

  const [blueprintSaved, setBlueprintSaved] = useState(false)
  const [saveFailure, setSaveFailure] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [currentHostNetworkConfigs, setCurrentHostNetworkConfigs] = useState([])
  const [noBlueprint, setNoBlueprint] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [currentBlueprintName, setCurrentBlueprintName] = useState('')
  const [currentBlueprint, setCurrentBlueprint] = useState(null)
  const [usedVolumeBackends, setUsedVolumeBackends] = useState([])
  const { loading: loadingResmgrHosts } = useListAction(listResmgrHosts)
  const { reload: reloadHostNetworkConfigs } = useListAction(listHostNetworkConfigs)
  const hosts = useSelector(resmgrHostsSelector)
  const hypervisorAuthorized = useMemo(() => {
    return hosts?.some((host) => host.roles?.includes('pf9-ostackhost-neutron'))
  }, [hosts])
  const imageLibraryAuthorized = useMemo(() => {
    return hosts?.some((host) => host.roles?.includes('pf9-glance-role'))
  }, [hosts])

  const loading = !loaded || loadingResmgrHosts

  useEffect(() => {
    const storageHosts = hosts?.filter((host) => host.roles?.includes('pf9-cindervolume-config'))
    if (!storageHosts?.length) {
      return
    }
    const getUsedVolumeBackends = async () => {
      const results: any = await Promise.allSettled(
        storageHosts.map((host) => getResmgrRoleConfig({ role: 'pf9-cindervolume-config', host })),
      )
      const values = results?.map((result) => result?.value)
      const backends = values?.reduce((accum, value) => {
        return [...accum, ...keys(value?.backends)]
      }, [])
      const uniqueBackends = uniq(backends)
      setUsedVolumeBackends(uniqueBackends)
    }
    getUsedVolumeBackends()
  }, [hosts])

  const querySettings = async () => {
    const blueprints = await getBlueprints()
    const blueprintSettings = blueprints?.[0]
    const hostNetworkConfigurations: any = await getHostNetworkConfigs()
    if (!blueprintSettings) {
      setNoBlueprint(true)
    } else {
      setCurrentBlueprintName(blueprintSettings?.name)
      setCurrentBlueprint(blueprintSettings)
      // set session data for underlay network & virtual networking
      const virtualNetworking = !!blueprintSettings?.virtualNetworking?.enabled
      const underlayNetwork = getUnderlayInfo(blueprintSettings, hostNetworkConfigurations)
      dispatch(
        sessionActions.updateSession({
          virtualNetworking,
          underlayNetwork,
        }),
      )
    }

    // Only get the host configs for the current blueprint
    const hostNetworkConfigs = hostNetworkConfigurations
      ?.filter((networkConfig) => networkConfig?.clusterName === blueprintSettings?.name)
      ?.map((networkConfig) => {
        const networkInterfacesWithLabels = values(networkConfig?.networkLabels)
        const otherInterfaces = [
          networkConfig?.hostLivenessInterface,
          networkConfig?.imagelibInterface,
          networkConfig?.mgmtInterface,
          networkConfig?.tunnelingInterface,
          networkConfig?.vmConsoleInterface,
        ]
        const uniqueInterfaces = uniq([...networkInterfacesWithLabels, ...otherInterfaces]).filter(
          (iface) => !!iface,
        )
        const networkLabelKeys = keys(networkConfig.networkLabels)
        const networkLabels = networkLabelKeys?.map((key) => ({
          key: key,
          value: networkConfig.networkLabels[key],
        }))
        return {
          name: networkConfig?.name,
          networkInterfaces: uniqueInterfaces.map((iface) => {
            return {
              physnet: iface,
              physicalNetworkLabel: networkLabels.find((pair) => pair.value === iface)?.key,
              hostLiveness: networkConfig.hostLivenessInterface === iface,
              imageLibrary: networkConfig.imagelibInterface === iface,
              management: networkConfig.mgmtInterface === iface,
              tunnel: networkConfig.tunnelingInterface === iface,
              vmConsole: networkConfig.vmConsoleInterface === iface,
            }
          }),
          id: networkConfig?.id,
        }
      })
    setCurrentHostNetworkConfigs(hostNetworkConfigs)
    const volumeTypes = keys(blueprintSettings?.storageBackends)
    const volumeTypesWithId = volumeTypes.map((type) => {
      return {
        type,
        id: uuid.v4(),
      }
    })
    const volumeBackendConfigs = volumeTypesWithId.reduce((accum, { type, id }) => {
      const configs = blueprintSettings?.storageBackends?.[type]
      const configNames = keys(configs)
      const wizardConfigs = configNames.map((name) => {
        const configKeys = keys(configs[name]?.config)
        const keyValues = configKeys.map((key) => ({
          key: key,
          value: String(configs[name]?.config?.[key]),
        }))
        return {
          name: name,
          driver: isCustomDriver(configs[name]?.driver) ? 'Custom' : configs[name]?.driver,
          customDriver: isCustomDriver(configs[name]?.driver) ? configs[name]?.driver : '',
          volumeType: type,
          keyValues: keyValues,
          volumeTypeId: id,
        }
      })
      return [...accum, ...wizardConfigs]
    }, [])

    updateParams({
      name: blueprintSettings?.name || defaultParams.name,
      networkingType: blueprintSettings?.networkingType || defaultParams.networkingType,
      enableDvr:
        blueprintSettings?.enableDistributedRouting === false ? false : defaultParams.enableDvr,
      dnsDomainName: blueprintSettings?.dnsDomainName || defaultParams.dnsDomainName,
      enableVirtualNetworking:
        blueprintSettings?.virtualNetworking?.enabled === false
          ? false
          : defaultParams.enableVirtualNetworking,
      segmentationTechnology:
        blueprintSettings?.virtualNetworking?.underlayType || defaultParams.segmentationTechnology,
      segmentationRange:
        blueprintSettings?.virtualNetworking?.vnidRange?.split(':')?.join('-') ||
        defaultParams.segmentationRange,
      enableHighAvailability:
        blueprintSettings?.vmHighAvailability?.enabled || defaultParams.enableHighAvailability,
      enableResourceRebalancing:
        blueprintSettings?.autoResourceRebalancing?.enabled === false
          ? false
          : defaultParams.enableResourceRebalancing,
      rebalancingStrategy:
        blueprintSettings?.autoResourceRebalancing?.rebalancingStrategy ||
        defaultParams.rebalancingStrategy,
      rebalancingFrequency: blueprintSettings?.autoResourceRebalancing?.rebalancingFrequencyMins
        ? `${blueprintSettings.autoResourceRebalancing.rebalancingFrequencyMins}`
        : defaultParams.rebalancingFrequency,
      imageLibraryLocation:
        blueprintSettings?.imageLibraryStorage || defaultParams.imageLibraryLocation,
      vmStoragePath: blueprintSettings?.vmStorage || defaultParams.vmStoragePath,
      hostNetworkConfigs: hostNetworkConfigs || defaultParams.hostNetworkConfigs,
      volumeTypes: volumeTypesWithId || defaultParams.volumeTypes,
      volumeBackendConfigs: volumeBackendConfigs || defaultParams.volumeBackendConfigs,
    })
    setLoaded(true)
  }

  useEffect(() => {
    querySettings()
  }, [])

  const submitBlueprint = useCallback(() => {
    if (isReadonlyUser()) return
    // Todo: add validation for all the checkboxes being selected for traffic
    if (!allSystemTrafficSelected(params.hostNetworkConfigs)) {
      return
    }

    const asyncCalls = async () => {
      setSubmitting(true)
      // Scroll to top so we can see the loading componentd
      document.querySelector('.content-main').scrollTo(0, 0)
      let errorOccurred = false
      const errMessages = []

      const volumeBackends = params.volumeBackendConfigs.reduce((accum, config) => {
        if (accum[config.volumeType]) {
          return {
            ...accum,
            [config.volumeType]: {
              ...accum[config.volumeType],
              [config.name]: {
                driver: isCustomDriver(config.driver) ? config.customDriver : config.driver,
                config: config.keyValues.reduce((accum, keyValues) => {
                  return {
                    ...accum,
                    [keyValues.key]: ['true', 'false'].includes(keyValues.value?.toLowerCase())
                      ? stringToBoolean(keyValues.value)
                      : keyValues.value,
                  }
                }, {}),
              },
            },
          }
        } else {
          return {
            ...accum,
            [config.volumeType]: {
              [config.name]: {
                driver: isCustomDriver(config.driver) ? config.customDriver : config.driver,
                config: config.keyValues.reduce((accum, keyValues) => {
                  return {
                    ...accum,
                    [keyValues.key]: ['true', 'false'].includes(keyValues.value?.toLowerCase())
                      ? stringToBoolean(keyValues.value)
                      : keyValues.value,
                  }
                }, {}),
              },
            },
          }
        }
      }, {})

      const blueprintConfigBody = {
        name: params.name,
        networkingType: params.networkingType,
        // backend was sending a string
        enableDistributedRouting: Boolean(params.enableDvr),
        dnsDomainName: params.dnsDomainName,
        virtualNetworking: {
          enabled: params.enableVirtualNetworking,
          underlayType: params.segmentationTechnology,
          vnidRange: params.segmentationRange?.split('-')?.join(':'),
        },
        vmHighAvailability: {
          enabled: params.enableHighAvailability,
        },
        autoResourceRebalancing: {
          enabled: params.enableResourceRebalancing,
          rebalancingStrategy: params.enableResourceRebalancing
            ? params.rebalancingStrategy
            : undefined,
          rebalancingFrequencyMins: params.enableResourceRebalancing
            ? parseInt(params.rebalancingFrequency)
            : undefined,
        },
        imageLibraryStorage: params.imageLibraryLocation,
        vmStorage: params.vmStoragePath,
        storageBackends: volumeBackends,
      }

      const hostConfigs = params.hostNetworkConfigs.map((config) => {
        const configProps = config.networkInterfaces.reduce(
          (accum, iface) => {
            const ifaceProps: any = {}
            if (iface.management) {
              ifaceProps.mgmtInterface = iface.physnet
            }
            if (iface.vmConsole) {
              ifaceProps.vmConsoleInterface = iface.physnet
            }
            if (iface.hostLiveness) {
              ifaceProps.hostLivenessInterface = iface.physnet
            }
            if (iface.tunnel) {
              ifaceProps.tunnelingInterface = iface.physnet
            }
            if (iface.imageLibrary) {
              ifaceProps.imagelibInterface = iface.physnet
            }
            return iface.physicalNetworkLabel
              ? {
                  ...accum,
                  ...ifaceProps,
                  networkLabels: {
                    ...accum.networkLabels,
                    [iface.physicalNetworkLabel]: iface.physnet,
                  },
                }
              : {
                  ...accum,
                  ...ifaceProps,
                }
          },
          { networkLabels: {} },
        )
        return {
          name: config.name,
          clusterName: params.name,
          id: config.id,
          ...configProps,
        }
      })

      const blueprintAction = noBlueprint ? createBlueprint : updateBlueprint
      const blueprintResponse = await blueprintAction({
        name: currentBlueprintName ? currentBlueprintName : params.name,
        body: blueprintConfigBody,
      }).catch((err) => new Error(err))
      if (blueprintResponse instanceof Error) {
        // Blueprint only seems to give 500 errors which don't
        // give any info anyways, so just show generic error
        errorOccurred = true
        setSaveFailure(errorOccurred)
        setErrorMessages(['Blueprint failed to save'])
        setSubmitting(false)
        return
      } else {
        setNoBlueprint(false)
      }

      const networkChangeOccurred = !equals(
        {
          enableDistributedRouting: currentBlueprint?.enableDistributedRouting,
          dnsDomainName: currentBlueprint?.dnsDomainName,
          virtualNetworking: {
            enabled: currentBlueprint?.virtualNetworking?.enabled,
            underlayType: currentBlueprint?.virtualNetworking?.underlayType,
            vnidRange: currentBlueprint?.virtualNetworking?.vnidRange,
          },
        },
        {
          enableDistributedRouting: blueprintConfigBody?.enableDistributedRouting,
          dnsDomainName: blueprintConfigBody?.dnsDomainName,
          virtualNetworking: {
            enabled: blueprintConfigBody?.virtualNetworking?.enabled,
            underlayType: blueprintConfigBody?.virtualNetworking?.underlayType,
            vnidRange: blueprintConfigBody?.virtualNetworking?.vnidRange,
          },
        },
      )

      const currentHostConfigIds = currentHostNetworkConfigs.map((config) => config.id)
      const hostConfigsToUpdate = hostConfigs.filter((config) => {
        if (!currentHostConfigIds.includes(config.id)) {
          return false
        }
        if (networkChangeOccurred) {
          return true
        }
        const currentConfig = currentHostNetworkConfigs.find((c) => c.id === config.id)
        const newConfig = params.hostNetworkConfigs.find((c) => c.id === config.id)
        return !equals(currentConfig, newConfig)
      })
      const hostConfigsToAdd = hostConfigs.filter((config) => {
        return !currentHostConfigIds.includes(config.id)
      })
      const hostConfigIds = hostConfigs.map((config) => config.id).filter((id) => !!id)
      const hostConfigsToDelete = without(hostConfigIds, currentHostConfigIds)

      if (hostConfigsToAdd.length) {
        const createResponse = await createHostNetworkConfigs(hostConfigsToAdd).catch((err) => {
          return new Error(err)
        })
        if (createResponse instanceof Error) {
          errMessages.push('Error creating new host configurations')
          errorOccurred = true
        }
      }
      const updateResults = await Promise.allSettled(
        hostConfigsToUpdate.map((config) => {
          return updateHostNetworkConfig({
            id: config.id,
            body: {
              ...config,
              id: undefined,
            },
          })
        }),
      )
      const deleteResults = await Promise.allSettled(
        hostConfigsToDelete.map((id) => {
          return deleteHostNetworkConfig({ id })
        }),
      )

      const rejectedUpdateIndexes = updateResults
        ?.map((result, idx) => (result.status === 'rejected' ? idx : null))
        ?.filter((idx) => idx !== null)
      const rejectedDeleteIndexes = deleteResults
        ?.map((result, idx) => (result.status === 'rejected' ? idx : null))
        ?.filter((idx) => idx !== null)
      if (rejectedUpdateIndexes?.length || rejectedDeleteIndexes?.length) {
        errorOccurred = true
      }
      for (const idx of rejectedUpdateIndexes) {
        const configName = hostConfigsToUpdate?.[idx]?.name
        errMessages.push(`Host Network Configuration ${configName} failed to update`)
      }
      for (const idx of rejectedDeleteIndexes) {
        const configId = hostConfigsToDelete?.[idx]
        const configName = currentHostNetworkConfigs?.find((config) => config.id === configId)?.name
        errMessages.push(`Host Network Configuration ${configName} failed to delete`)
      }
      querySettings()
      setBlueprintSaved(true)
      setSubmitting(false)
      reloadHostNetworkConfigs(true, true)
      setSaveFailure(errorOccurred)
      setErrorMessages(errMessages)
    }
    asyncCalls()
  }, [params, currentHostNetworkConfigs, noBlueprint])

  return (
    <>
      <Progress
        message={
          submitting
            ? 'Updating Blueprint... This may take a few minutes. Please wait until the spinner stops to ensure that all settings are applied.'
            : 'Loading'
        }
        loading={loading || !loaded || submitting}
      >
        <DocumentMeta title="Cluster Blueprint" bodyClasses={['form-view']} />
        <div className={classes.formsContainer}>
          <ValidatedForm
            classes={{ root: classes.validatedFormContainer }}
            elevated={false}
            onSubmit={submitBlueprint}
            formActions={
              !isReadonlyUser() && (
                <div className={classes.submitButtonContainer}>
                  <Button type="submit">
                    {blueprintSaved ? 'Blueprint Saved' : 'Save Blueprint'}
                  </Button>
                  {saveFailure && (
                    <div className={classes.errors}>
                      {errorMessages?.map((message) => (
                        <div className={classes.submitWarning}>
                          <FontAwesomeIcon className={classes.warningColor}>
                            triangle-exclamation
                          </FontAwesomeIcon>
                          <Text variant="body2">{message}</Text>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )
            }
            noEnter // Without this, pressing enter in other modals would make the form submit for some reason
          >
            <div className={classes.formHeader}>
              <Text variant="subtitle1">Cluster Blueprint</Text>
              <Text variant="body2">
                Cluster Blueprint allows you to design and configure your cluster to meet your
                intended use cases.
              </Text>
            </div>
            {/* <FormFieldSection title="Base Blueprint" step={1}>
              <BlueprintTypeCards
                onClick={getParamsUpdater('blueprintType')}
                value={params.blueprintType}
              />
            </FormFieldSection> */}
            <FormFieldSection title="Cluster Resource Management" step={1}>
              <FormFieldCard title="VM High Availability" maxWidth={1100}>
                <div className={classes.cardBody}>
                  <ToggleSwitchField
                    id="enableHighAvailability"
                    value={params.enableHighAvailability}
                    onChange={getParamsUpdater('enableHighAvailability')}
                    label="Automatically detect host failure and recover VMs on other cluster hosts"
                  />
                  {/* <Text variant="body2" className={classes.switchDescription}>
                    Set enabled to activate high availability on hypervisors automatically when
                    there are 4 or more hypervisor hosts authorized.
                  </Text> */}
                  <Text variant="body2" className={classes.switchDescription}>
                    This feature requires at least 4 hosts that are authorized as hypervisors.
                  </Text>
                  {/* {params.enableHighAvailability && (
                    <LivenessCheckPicklist
                      name="livenessCheckFrequency"
                      label="Liveness Check Frequency"
                      value={params.livenessCheckFrequency}
                      onChange={getParamsUpdater('livenessCheckFrequency')}
                    />
                  )} */}
                </div>
              </FormFieldCard>
              <FormFieldCard title="Automatic Resource Rebalancing" maxWidth={1100}>
                <div className={classes.cardBody}>
                  <ToggleSwitchField
                    id="enableResourceRebalancing"
                    value={params.enableResourceRebalancing}
                    onChange={getParamsUpdater('enableResourceRebalancing')}
                    label="Automatically rebalance workloads across hosts in the cluster"
                  />
                  {params.enableResourceRebalancing && (
                    <div className={classes.switchSection}>
                      <Text variant="caption1" className={classes.fieldLabel}>
                        Rebalancing Strategy
                      </Text>
                      <ResourceRebalancingCards
                        onClick={getParamsUpdater('rebalancingStrategy')}
                        value={params.rebalancingStrategy}
                      />
                      {/* <RebalancingConcurrencyPicklist
                        name="rebalancingConcurrency"
                        label="Rebalancing Concurrency"
                        value={params.rebalancingConcurrency}
                        onChange={getParamsUpdater('rebalancingConcurrency')}
                      /> */}
                      {/* <TextField
                        id="rebalancingFrequency"
                        label="Rebalancing Frequency (minutes)"
                        onChange={getParamsUpdater('rebalancingFrequency')}
                        value={params.rebalancingFrequency}
                        info="How often the rebalancing check should be done"
                        type="number"
                        required
                      /> */}
                      <RebalancingFrequencyPicklist
                        name="rebalancingFrequency"
                        label="Rebalancing Frequency"
                        value={params.rebalancingFrequency}
                        onChange={getParamsUpdater('rebalancingFrequency')}
                      />
                    </div>
                  )}
                </div>
              </FormFieldCard>
            </FormFieldSection>
            <FormFieldSection title="Network Configuration" step={2}>
              <FormFieldCard title="Cluster Network Parameters" maxWidth={1100}>
                <div className={classes.cardBody}>
                  {hypervisorAuthorized && (
                    <div className={classes.submitWarning}>
                      <FontAwesomeIcon className={classes.warningColor}>
                        triangle-exclamation
                      </FontAwesomeIcon>
                      <Text variant="body2">
                        Some configurations may not be changed while a hypervisor role is assigned.
                      </Text>
                    </div>
                  )}
                  <TextField
                    id="dnsDomainName"
                    label="DNS Domain Name"
                    onChange={getParamsUpdater('dnsDomainName')}
                    value={params.dnsDomainName}
                    info="Specify the DNS domain name for your organization. A newly created VM's FQDN will be <vm_name>.<dns_domain_name>"
                    required
                  />
                  <ToggleSwitchField
                    id="enableDvr"
                    value={params.enableDvr}
                    onChange={getParamsUpdater('enableDvr')}
                    label="Enable Distributed Virtual Routing (DVR)"
                    disabled={hypervisorAuthorized}
                  />
                  <ToggleSwitchField
                    id="enableVirtualNetworking"
                    value={params.enableVirtualNetworking}
                    onChange={getParamsUpdater('enableVirtualNetworking')}
                    label="Enable Virtual Networking"
                    disabled={hypervisorAuthorized}
                  />
                  {params.enableVirtualNetworking && (
                    <>
                      <PicklistField
                        DropdownComponent={UnderlayNetworkTypesPicklist}
                        id="segmentationTechnology"
                        label="Segmentation Technology"
                        value={params.segmentationTechnology}
                        onChange={getParamsUpdater('segmentationTechnology')}
                        networkingBackend="ovn"
                        disabled={hypervisorAuthorized}
                        required
                      />
                      <TextField
                        id="segmentationRange"
                        label={rangeLabelMap[params?.segmentationTechnology]}
                        onChange={getParamsUpdater('segmentationRange')}
                        value={params.segmentationRange}
                        info={rangeTooltipMap[params?.segmentationTechnology]}
                        placeholder={rangePlaceholderMap[params?.segmentationTechnology]}
                        // validations={[cidrValidator]}
                        disabled={hypervisorAuthorized}
                        required
                      />
                    </>
                  )}
                </div>
              </FormFieldCard>
              <FormFieldCard title="Host Network Configurations" maxWidth={1100}>
                <div className={classes.cardBody}>
                  <Text variant="body2">
                    Hosts in a cluster are automatically configured for you based on a Host Network
                    Configuration.
                  </Text>
                  <Text variant="body2">
                    List each network interface on your hosts, and assign it system traffic if any,
                    and/or a physical network label. You can later create Physical Networks using
                    physical network labels.
                  </Text>
                  <HostNetworkConfigurations
                    params={params}
                    updateParams={updateParams}
                    getParamsUpdater={getParamsUpdater}
                  />
                </div>
              </FormFieldCard>
            </FormFieldSection>
            <FormFieldSection title="Persistent Storage Connectivity" step={3}>
              <Text variant="body2">
                Register Volume Types to enable the use of Persistent Storage Volumes. Supported
                infrastructure integrations include:
              </Text>
              <StorageTypeLogos />
              <FormFieldCard title="Storage Volume Types" maxWidth={1100}>
                <div className={classes.cardBody}>
                  <StorageVolumeTypeTable
                    params={params}
                    updateParams={updateParams}
                    getParamsUpdater={getParamsUpdater}
                    usedVolumeBackends={usedVolumeBackends}
                  />
                </div>
              </FormFieldCard>
            </FormFieldSection>
            <FormFieldSection title="Customize Cluster Defaults (Optional)" step={4}>
              <FormFieldCard title="Image Library" maxWidth={1100}>
                <div className={classes.cardBody}>
                  {imageLibraryAuthorized && (
                    <div className={classes.submitWarning}>
                      <FontAwesomeIcon className={classes.warningColor}>
                        triangle-exclamation
                      </FontAwesomeIcon>
                      <Text variant="body2">
                        Location may not be changed while an image library role is assigned.
                      </Text>
                    </div>
                  )}
                  <TextField
                    id="imageLibraryLocation"
                    label="Location (filesystem path or volume type name)"
                    placeholder="eg. /var/opt/imagelibrary/data or volume-type-name"
                    onChange={getParamsUpdater('imageLibraryLocation')}
                    value={params.imageLibraryLocation}
                    info="Location on your host file system where the image files from the image library will be stored"
                    validations={[imageLibraryLocationValidation]}
                    disabled={imageLibraryAuthorized}
                    required
                  />
                </div>
              </FormFieldCard>
              <FormFieldCard title="Hypervisor" maxWidth={1100}>
                <div className={classes.cardBody}>
                  {hypervisorAuthorized && (
                    <div className={classes.submitWarning}>
                      <FontAwesomeIcon className={classes.warningColor}>
                        triangle-exclamation
                      </FontAwesomeIcon>
                      <Text variant="body2">
                        Virtual machine storage path may not be changed while a hypervisor role is
                        assigned.
                      </Text>
                    </div>
                  )}
                  <TextField
                    id="vmStoragePath"
                    label="Virtual Machine Storage Path"
                    onChange={getParamsUpdater('vmStoragePath')}
                    value={params.vmStoragePath}
                    info="Location on your host file system where the virtual machine files will be stored"
                    disabled={hypervisorAuthorized}
                    required
                  />
                </div>
              </FormFieldCard>
              {/* <FormFieldCard title="Cluster Name" maxWidth={1100}>
                <div className={classes.cardBody}> */}
              <div className={classes.nameField}>
                <Text variant="subtitle2">Cluster Name:</Text>
                <TextField
                  id="name"
                  label=""
                  onChange={getParamsUpdater('name')}
                  value={params.name}
                  disabled={!!currentBlueprintName}
                  required
                />
              </div>
              {/* </div> */}
              {/* </FormFieldCard> */}
            </FormFieldSection>
          </ValidatedForm>
        </div>
      </Progress>
    </>
  )
}
