import { makeStyles } from '@material-ui/styles'
import { flavorsSelector } from 'app/plugins/openstack/components/flavors/selectors'
import { humanReadableSize } from 'app/plugins/openstack/helpers'
import { ArrayElement } from 'core/actions/Action'
import Text from 'core/elements/Text'
import QuantitySelector from 'pf9-ui-components/built/components/QuantitySelector'
import Grid, { GridViewColumn } from 'pf9-ui-components/built/elements/grid/Grid'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React, { useEffect, useState } from 'react'

const VmExpandedRow = ({ quantity = 1, onChange }) => {
  const classes = useVmExpandedRowStyles()
  return (
    <div className={classes.expandedRowContainer}>
      <Text variant="body2">Quantity</Text>
      <QuantitySelector id="quantity" onChange={onChange} value={quantity} />
    </div>
  )
}

const useVmExpandedRowStyles = makeStyles((theme: Theme) => ({
  expandedRowContainer: {
    display: 'grid',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    marginLeft: '42px',
  },
}))

type SelectorModel = ArrayElement<ReturnType<typeof flavorsSelector>>

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'name', label: 'Virtual Machine Flavor' },
  { key: 'vcpus', label: 'vCPUs' },
  {
    key: 'ram',
    label: 'Memory (Gb)',
    render: (ram) => humanReadableSize(ram * 1024 * 1024, false),
  },
  {
    key: 'disk',
    label: 'Storage (Gb)',
    render: (disk) => humanReadableSize(disk * 1024 * 1024 * 1024, false),
  },
]

export default function VirtualMachineFlavorsTable({
  data,
  selectedVmFlavor,
  selectedVmQuantity,
  onChange,
}) {
  const [selectedFlavors, setSelectedVms] = useState([])

  const handleVmSelection = (selectedItems) => {
    setSelectedVms(selectedItems)
    onChange({ flavor: selectedItems[0]?.name, replicas: selectedVmQuantity || 1 })
  }

  const handleVmQuantityChange = (quantity) => {
    onChange({ replicas: quantity })
  }

  useEffect(() => {
    if (selectedFlavors[0]?.name === selectedVmFlavor) return
    setSelectedVms(data.filter((cluster) => cluster.name === selectedVmFlavor))
  }, [selectedVmFlavor, selectedFlavors[0]?.name, data])

  return (
    <Grid<SelectorModel>
      uniqueIdentifier="id"
      columns={columns}
      data={data}
      orderBy="vcpus"
      orderDirection="asc"
      emptyContent="No VM flavors available"
      disableRowSelection={false}
      multiSelection={false}
      selectedItems={selectedFlavors}
      onSelectChange={handleVmSelection}
      expandableRow={() => {
        return <VmExpandedRow quantity={selectedVmQuantity} onChange={handleVmQuantityChange} />
      }}
      expandedByDefault={(vm) => vm.name === selectedVmFlavor}
      expandRowsUponSelection
      disableToolbar
      hidePagination
    />
  )
}
