import { makeStyles } from '@material-ui/styles'
import React from 'react'
import useReactRouter from 'use-react-router'

import DocumentMeta from 'core/components/DocumentMeta'
import Progress from 'core/components/progress/Progress'
import Breadcrumbs from 'core/elements/breadcrumbs'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import useListAction from 'core/hooks/useListAction'
import Theme from 'core/themes/model'
import { useSelector } from 'react-redux'
import { listKaapiClusters } from '../kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import ClustersDashboardHeader from './ClustersDashboardHeader'
import ClustersLandingPage from './ClustersLandingPage'
import DeployNewCluster from './DeployNewCluster'
import ListK8sClusters from './ListK8sClusters'
import PollingData from 'core/components/PollingData'

const ClustersDashboardPage = () => {
  const classes = useStyles({})
  const { history } = useReactRouter()
  const { loading: loadingKaapiClusters, reload } = useListAction(listKaapiClusters)
  const clusters = useSelector(kaapiClustersOverviewSelector)
  return (
    <>
      <HeaderTitlePortal>
        <Breadcrumbs />
      </HeaderTitlePortal>
      <PollingData
        hidden
        loading={loadingKaapiClusters}
        onReload={reload}
        refreshDuration={1000 * 10}
      />
      <Progress loading={loadingKaapiClusters}>
        <DocumentMeta title="Dashboard" />
        <DeployNewCluster />
        {!clusters?.length ? (
          <div>
            <ClustersLandingPage />
          </div>
        ) : (
          <div>
            <ClustersDashboardHeader />
            <div className={classes.headerSubText}>
              <ListK8sClusters />
            </div>
          </div>
        )}
      </Progress>
    </>
  )
}

export default ClustersDashboardPage

const useStyles = makeStyles((theme: Theme) => ({
  customGridContainer: {
    display: 'grid',
    gridTemplateColumns: '370px 1fr',
    gridGap: '24px',
  },
  headerSubText: {
    paddingTop: '24px',
  },
}))
