import ApiService from 'api-client/ApiService'
import config from 'app-config'
import { cleanResource } from 'app/plugins/infrastructure/components/clusters/aws/capi/capiUtils'
import store from 'app/store'
import { sessionStoreKey } from 'core/session/sessionReducers'
import qs from 'qs'
import { trackApiMethodMetadata } from './helpers'
import { DexAuthToken } from './keystone.model'
import { GetClusterUpgradeJobsResponse } from 'app/plugins/infrastructure/components/clusters/capi/upgrade/model'
import { ClusterUpgradeJob } from 'app/plugins/infrastructure/components/clusters/capi/upgrade/model'

class Kaapi extends ApiService {
  public getClassName() {
    return 'kaapi'
  }

  static apiMethodsMetadata = []

  protected async getEndpoint() {
    return Promise.resolve(config.apiHost)
  }

  get baseUrl() {
    const state = store.getState()
    const { activeTenant } = state[sessionStoreKey]
    return `/oidc-proxy/${activeTenant}`
  }

  get kaapiNamespace() {
    const state = store.getState()
    const { activeKaapiTenant } = state[sessionStoreKey]
    return activeKaapiTenant
  }

  // For Kaapi Workload Cluster - get the endpoint stored in the preferences
  get workloadClusterEndpoint() {
    const state = store.getState()
    const { username } = state[sessionStoreKey]
    const { preferences } = state
    return preferences[username]['k8sPluginGlobalParams']?.cluster_endpoint as string
  }

  @trackApiMethodMetadata({
    url: '/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackclusters',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'OpenStackCluster' },
  })
  createOpenStackCluster = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/openstackclusters`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createOpenStackCluster',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  /**
   * @summary: ClusterUpgradeJobs
   */
  @trackApiMethodMetadata({
    url: '/sunpike/apis/pmk.pf9.io/v1beta1/namespaces/:namespace/clusterupgradejobs',
    type: 'GET',
    params: ['namespace'],
  })
  getAllClusterUpgradeJobs = async (namespace = 'default') => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/clusterupgradejobs`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAllClusterUpgradeJobs',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items)
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/pmk.pf9.io/v1beta1/namespaces/:namespace/clusterupgradejobs',
    type: 'POST',
    params: ['clusterName', 'namespace'],
  })
  createKaapiClusterUpgradeJob = async (body, namespace = 'default') => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/clusterupgradejobs`
    return await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createK8sClusterUpgradeJob',
        useJWTToken: true,
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackclusters',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'OpenStackClusters' },
  })
  getOpenStackClusters = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/openstackclusters`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenStackClusters',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackclusters',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Cluster' },
  })
  createCluster = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/clusters`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCluster',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/clusters',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Clusters' },
  })
  getKaapiClusters = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/clusters`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getKaapiClusters',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/clusters',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Clusters' },
  })
  deleteKaapiCluster = async ({ namespace, name }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/clusters/${name}`
    const data = await this.client.basicDelete<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteKaapiCluster',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/controlplane.platform9.io/v1alpha1/namespaces/:namespace/hostedcontrolplanes',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'HostedControlPlane' },
  })
  createHostedControlPlane = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/controlplane.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/hostedcontrolplanes`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createHostedControlPlane',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/controlplane.platform9.io/v1alpha1/namespaces/:namespace/hostedcontrolplanes',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'HostedControlPlane' },
  })
  getHostedControlPlanes = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/controlplane.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/hostedcontrolplanes`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getHostedControlPlanes',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'MachineDeployment' },
  })
  createMachineDeployment = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machinedeployments`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createMachineDeployment',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments/:name',
    type: 'PATCH',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'MachineDeployments' },
  })
  patchMachineDeployment = async (name, customBody = undefined) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machinedeployments/${name}`
    const data = await this.client.basicPatch<any>({
      url,
      body: customBody,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchMachineDeployment',
        useJWTToken: true,
        config: {
          headers: {
            'Content-Type': customBody
              ? 'application/json-patch+json'
              : 'application/merge-patch+json',
          },
        },
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments',
    type: 'DELETE',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'MachineDeployment' },
  })
  deleteKaapiMachineDeployments = async ({ namespace, name }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machinedeployments/${name}`
    const data = await this.client.basicDelete<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteMachineDeployment',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'MachineDeployment' },
  })
  getMachineDeployments = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machinedeployments`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'listMachineDeployments',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/Machines',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Machines' },
  })
  getMachines = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machines`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getMachines',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  @trackApiMethodMetadata({
    url: '/apis/cluster.x-k8s.io/v1beta1/namespaces/:namespace/machinedeployments',
    type: 'DELETE',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Machines' },
  })
  deleteKaapiMachines = async ({ namespace, name }) => {
    const url = `${this.baseUrl}/apis/cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/machines/${name}`
    const data = await this.client.basicDelete<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteMachines',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackmachinetemplates',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'OpenStackMachineTemplate' },
  })
  createOpenStackMachineTemplate = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/openstackmachinetemplates`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createOpenStackMachineTemplate',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url:
      '/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackmachinetemplates',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'OpenStackMachineTemplate' },
  })
  getOpenStackMachineTemplates = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/openstackmachinetemplates`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenStackMachineTemplates',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  @trackApiMethodMetadata({
    url:
      '/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/:namespace/openstackmachinetemplates',
    type: 'DELETE',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'OpenStackMachineTemplate' },
  })
  deleteOpenStackMachineTemplate = async ({ namespace, name }) => {
    const url = `${this.baseUrl}/apis/infrastructure.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/openstackmachinetemplates/${name}`
    const data = await this.client.basicDelete<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteOpenStackMachineTemplates',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/kubeadmconfigtemplates',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'KubeadmConfigTemplate' },
  })
  createKubeadmConfigTemplate = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/kubeadmconfigtemplates`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createKubeadmConfigTemplate',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/kubeadmconfigtemplates',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'KubeadmConfigTemplate' },
  })
  getKubeadmConfigTemplate = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/kubeadmconfigtemplates`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getKubeadmConfigTemplate',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  @trackApiMethodMetadata({
    url: '/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/:namespace/kubeadmconfigtemplates',
    type: 'DELETE',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'KubeadmConfigTemplate' },
  })
  deleteKubeadmConfigTemplate = async ({ namespace, name }) => {
    const url = `${this.baseUrl}/apis/bootstrap.cluster.x-k8s.io/v1beta1/namespaces/${this.kaapiNamespace}/kubeadmconfigtemplates/${name}`
    const data = await this.client.basicDelete<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteKubeadmConfigTemplate',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/namespaces/:namespace/clusteraddons',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'ClusterAddon' },
  })
  createClusterAddon = async (namespace, body) => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/clusteraddons`
    const data = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterAddon',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/namespaces/:namespace/clusteraddons',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'ClusterAddon' },
  })
  getClusterAddons = async ({ namespace }) => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/namespaces/${this.kaapiNamespace}/clusteraddons`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterAddons',
        useJWTToken: true,
      },
    })
    return cleanResource()(data)
  }

  // Tenant Resources
  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/tenants',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Tenant' },
  })
  getAllTenants = async () => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/tenants`
    try {
      const data = await this.client.basicGet<any>({
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getTanants',
          useJWTToken: true,
        },
      })
      return cleanResource()(data)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/tenants',
    type: 'GET',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Tenant' },
  })
  getKaapiTenant = async ({ tenantName }) => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/tenants/${tenantName}`
    try {
      const data = await this.client.basicGet<any>({
        endpoint: config.kaapiURL,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getTanants',
          useJWTToken: true,
        },
      })
      return cleanResource()(data)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/apis/core.barista.platform9.io/v1alpha1/tenants',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Tenant' },
  })
  createTenant = async ({ body }) => {
    const url = `${this.baseUrl}/apis/core.barista.platform9.io/v1alpha1/tenants`
    try {
      const data = await this.client.basicPost<any>({
        endpoint: config.kaapiURL,
        url,
        body,
        options: {
          clsName: this.getClassName(),
          mthdName: 'createTenant',
          useJWTToken: true,
        },
      })
      return cleanResource()(data)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/dex/token',
    type: 'POST',
    params: ['username', 'password', 'domain'],
    error: { isClusterError: true },
  })
  // This is to authenticate the user with the dex server and get the JWT token
  peformDexLogin = async ({
    username,
    password,
    domain = 'default',
  }): Promise<DexAuthToken | null> => {
    const state = store.getState()
    const {
      // TODO: Remove default values once staging is updated
      features: { dex_client_secret = 'BTXO4K60xxQHBvwQ', sso_infra_url },
    } = state[sessionStoreKey]
    //Commented this code to get shalaka unblock for QA testing for now as we are not getting dex_client_secret from /clarity/features.json
    // if (!sso_infra_url || !dex_client_secret) {
    //   return null
    // }

    try {
      const response = await this.client.rawPost<DexAuthToken>({
        endpoint: `https://${sso_infra_url}`,
        url: '/dex/token',
        data: qs.stringify({
          grant_type: 'password',
          client_id: 'kubernetes',
          //TODO: Once get data from /clarity/features.json need to revert the check.
          client_secret: dex_client_secret === '' ? 'JX9yjAZk9oWjc9nI' : dex_client_secret,
          scope: 'openid offline_access groups email',
          username,
          password,
          connector_id: domain,
        }),
        config: {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
        options: {
          clsName: this.getClassName(),
          mthdName: 'performDexLoin',
        },
      })
      return response.data
    } catch (err) {
      console.log(err)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/api/v1/namespaces/:namespace/secrets/:secretname',
    type: 'GET',
    params: ['secretName'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  getSecret = async ({ secretName }) => {
    try {
      const response = await this.client.basicGet<any>({
        url: `${this.baseUrl}/api/v1/namespaces/${this.kaapiNamespace}/secrets/${secretName}`,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getSecret',
          useJWTToken: true,
        },
      })

      return response
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/api/v1/namespaces/:namespace/secrets',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  getKaapiSecrets = async ({ namespace }) => {
    try {
      const data = await this.client.basicGet<any>({
        url: `${this.baseUrl}/api/v1/namespaces/${this.kaapiNamespace}/secrets`,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getKaapiSecrets',
          useJWTToken: true,
        },
      })
      return cleanResource()(data.items || [])
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/api/v1/namespaces/:namespace/secrets',
    type: 'POST',
    params: ['namespace', 'body'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  createKaapiSecret = async ({ body }) => {
    try {
      const response = await this.client.basicPost<any>({
        url: `${this.baseUrl}/api/v1/namespaces/${this.kaapiNamespace}/secrets`,
        body,
        options: {
          clsName: this.getClassName(),
          mthdName: 'createSecret',
          useJWTToken: true,
        },
      })
      return response
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/dex/token',
    type: 'DELETE',
    params: ['namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Secret' },
  })
  deleteKaapiSecret = async ({ name }) => {
    try {
      const response = await this.client.basicDelete<any>({
        url: `${this.baseUrl}/api/v1/namespaces/${this.kaapiNamespace}/secrets/${name}`,
        options: {
          clsName: this.getClassName(),
          mthdName: 'createSecret',
          useJWTToken: true,
        },
      })
      return response
    } catch (e) {
      console.log(e)
      return null
    }
  }

  downloadKubeconfig = async ({ clusterName }) => {
    try {
      const response = await this.client.basicGet<any>({
        url: `${this.baseUrl}/api/v1/namespaces/${this.kaapiNamespace}/secrets/${clusterName}cp-admin-kubeconfig`,
        options: {
          clsName: this.getClassName(),
          mthdName: 'downloadKubeconfig',
          useJWTToken: true,
        },
      })

      return response?.['super-admin.conf']
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @trackApiMethodMetadata({
    url: '/k8sapi/api/v1/namespaces/:namespace/configmaps`/',
    type: 'POST',
    params: ['namespace'],
    error: { isClusterError: true, k8sResource: 'ConfigMaps' },
  })
  getConfigMaps = async ({ namespace }) => {
    const url = `${this.baseUrl}/api/v1/namespaces/${this.kaapiNamespace}/configmaps`
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getConfigMaps',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  // Worker Nodes
  @trackApiMethodMetadata({
    url: '/api/v1/nodes',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'Nodes' },
  })
  getAllWorkerNodes = async () => {
    try {
      const url = ClusterEndpoints.NODES
      const data = await this.client.basicGet<any>({
        endpoint: this.workloadClusterEndpoint,
        url,
        options: {
          clsName: this.getClassName(),
          mthdName: 'getAllWorkerNodes',
          useJWTToken: true,
        },
      })
      return cleanResource()(data.items || [])
    } catch (e) {
      console.log(e)
      return null
    }
  }

  // Pods
  @trackApiMethodMetadata({
    url: '/api/v1/pods',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'Pods' },
  })
  getAllPods = async () => {
    const url = ClusterEndpoints.PODS
    const data = await this.client.basicGet<any>({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAllPods',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }

  // Namespaces
  @trackApiMethodMetadata({
    url: '/api/v1/namespaces',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'Namespaces' },
  })
  getAllNamespaces = async () => {
    const url = ClusterEndpoints.NAMESPACES
    const data = await this.client.basicGet<any>({
      endpoint: this.workloadClusterEndpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAllNamespaces',
        useJWTToken: true,
      },
    })
    return cleanResource()(data.items || [])
  }
}

// Cluster Endpoints for kaapi workload clusters
enum ClusterEndpoints {
  'NODES' = '/api/v1/nodes',
  'PODS' = '/api/v1/pods',
  'NAMESPACES' = '/api/v1/namespaces',
}

export default Kaapi
