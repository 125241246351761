import React, { useMemo } from 'react'
import Dropdown from 'core/elements/dropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listVirtualMachines } from '../../vms/actions'
import { virtualMachinesSelector } from '../../vms/selectors'
import { useAppSelector } from 'app/store'

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function VmPicklist({
  disabled = false,
  name = 'virtualMachine',
  label = 'Virtual Machine',
  ...rest
}) {
  const { loading } = useListAction(listVirtualMachines)
  const vmData = useAppSelector(virtualMachinesSelector)

  const options = useMemo(() => {
    const vmOptions = vmData.map((vm) => {
      return { label: vm?.name, value: vm?.id }
    })

    return vmOptions
  }, [vmData])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact={false}
      disabled={disabled}
      loading={loading}
      items={options}
    />
  )
}
