// TODO: Add correct model for ClusterUpgradeJob once the API gives us the full
// payload. As of now, the payload is missing the statuses

export enum ClusterUpgradeSteps {
  ControlPlane = 'UpgradedControlPlane',
  WorkerNodeGroups = 'UpgradedNodeGroups',
  // Addons = 'UpgradedClusterAddons', // Might need this in future for Addons
}
export interface IClusterUpgradeJobSelector extends ClusterUpgradeJob {
  id: ClusterUpgradeJob['metadata']['uid']
  name: ClusterUpgradeJob['metadata']['name']
  namespace: ClusterUpgradeJob['metadata']['namespace']
  clusterName: ClusterUpgradeJob['spec']['clusterName']
  targetVersion: ClusterUpgradeJob['spec']['targetVersion']
  sourceVersion: ClusterUpgradeJob['spec']['sourceVersion']
  conditions: ClusterUpgradeJob['status']['conditions']
  upgradeCondition: ClusterUpgradeJob['status']['conditions']['Ready']
  controlPlaneCondition: ClusterUpgradeJob['status']['conditions']['UpgradedControlPlane']
  nodeGroupsCondition: ClusterUpgradeJob['status']['conditions']['UpgradedNodeGroups']
  addonsCondition: ClusterUpgradeJob['status']['conditions']['UpgradedClusterAddons']
  nodeGroupStatuses: ClusterUpgradeJob['status']['nodeGroupStatuses']
  phase: ClusterUpgradeJob['status']['phase']
  reason: ClusterUpgradeJob['status']['reason']
  message: ClusterUpgradeJob['status']['message']
  startTime: ClusterUpgradeJob['status']['startTime']
  completionTime: ClusterUpgradeJob['status']['completionTime']
  currentUpgradeStepId: string
  completedSteps: number
  nodeGroups: ClusterUpgradeJob['status']['nodeGroups']
}

export interface GetClusterUpgradeJobsResponse {
  apiVersion: ClusterUpgradeJobAPIVersion
  items: ClusterUpgradeJob[]
  kind: string
  metadata: ClusterUpgradeJobMetadata
}

export enum ClusterUpgradeJobAPIVersion {
  PmkPf9IoV1Beta1 = 'pmk.pf9.io/v1beta1',
}

export interface ClusterUpgradeJob {
  apiVersion: ClusterUpgradeJobAPIVersion
  kind: ItemKind
  metadata: ItemMetadata
  spec: Spec
  status: any // TODO: Add the model for this once the API is complete
}

export enum ItemKind {
  ClusterUpgradeJob = 'ClusterUpgradeJob',
}

export interface ItemMetadata {
  creationTimestamp: string
  generation: number
  managedFields: ManagedField[]
  name: string
  namespace: Namespace
  resourceVersion: string
  uid: string
}

export interface ManagedField {
  apiVersion: ClusterUpgradeJobAPIVersion
  fieldsType: FieldsType
  fieldsV1: FieldsV1
  manager: Manager
  operation: Operation
  time: string
}

export enum FieldsType {
  FieldsV1 = 'FieldsV1',
}

export interface FieldsV1 {
  'f:spec': FSpec
}

export interface FSpec {
  '.': Empty
  'f:addons': Empty
  'f:clusterName': Empty
  'f:nodeGroups': Empty
  'f:strategy': Empty
  'f:targetVersion': Empty
}

export interface Empty {}

export enum Manager {
  Mozilla = 'Mozilla',
}

export enum Operation {
  Update = 'Update',
}

export enum Namespace {
  Default = 'default',
}

export interface Spec {
  addons: Addon[]
  clusterName: string
  nodeGroups: Addon[]
  strategy: Strategy
  targetVersion: string
  sourceVersion: string
}

export interface Addon {
  apiVersion: AddonAPIVersion
  kind: AddonKind
  name: string
}

export enum AddonAPIVersion {
  ClusterXK8SIoV1Beta1 = 'cluster.x-k8s.io/v1beta1',
  InfrastructureClusterXK8SIoV1Beta1 = 'infrastructure.cluster.x-k8s.io/v1beta1',
  SunpikePlatform9COM = 'sunpike.platform9.com', //TODO: update this
}

export enum AddonKind {
  AWSMachinePool = 'AWSMachinePool',
  AWSManagedMachinePool = 'AWSManagedMachinePool',
  ClusterAddon = 'ClusterAddon',
  MachineDeployment = 'MachineDeployment',
}

export enum Strategy {
  Parallel = 'parallel',
}

export interface ClusterUpgradeJobMetadata {
  continue: string
  resourceVersion: string
}

export enum ClusterUpgradeStatus {
  Unknown = 'Unknown',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
  Canceled = 'Canceled',
}
export const clusterUpgradeStatusMap = {
  [ClusterUpgradeStatus.NotStarted]: 'unknown',
  [ClusterUpgradeStatus.InProgress]: 'loading',
  [ClusterUpgradeStatus.Completed]: 'ok',
  [ClusterUpgradeStatus.Failed]: 'fail',
}
