import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { updateFloatingIp } from 'openstack/components/networks/floating-ips/actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import PicklistField from 'core/components/validatedForm/DropdownField'
import UsedFloatingIpsPicklist from 'openstack/components/networks/floating-ips/UsedFloatingIpsPicklist'
import { refreshVirtualMachine } from './actions'
import { waitSeconds } from 'openstack/components/infrastructure/blueprint/helpers'

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    gap: 16,
    display: 'grid',
  },
}))

export default function RemoveFloatingIpDialog({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)

  const defaultParams = {
    floatingIp: '',
  }
  const { params, getParamsUpdater, updateParams, setParams } = useParams(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateFloatingIp)
  const { update: refreshVm, updating: refreshingVm } = useUpdateAction(refreshVirtualMachine)

  const handleSubmit = useCallback(() => {
    setSubmitting(true)
    const asyncCalls = async () => {
      const body = {
        floatingip: {
          port_id: null,
        },
      }
      const { success } = await update({ id: params.floatingIp, body })
      await waitSeconds(3) // wait 3 seconds for VM private IP to be updated first
      await refreshVm({ id: vm.id })
      setSubmitting(false)
      if (success) handleClose()
    }
    asyncCalls()
  }, [params, vm])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose(true)
  }

  return (
    <ModalForm
      title="Remove Public IP"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={submitting}
      submitting={submitting}
      error={error}
      submitTitle={`Remove Public IP`}
      panel="dialog"
      // maxWidth={1200}
      open
    >
      <div className={classes.fields}>
        <Text variant="body2">Select a public IP to remove from the selected VM.</Text>
        <PicklistField
          DropdownComponent={UsedFloatingIpsPicklist}
          id="floatingIp"
          label="Public IP to Remove"
          tooltip="List of public IPs attached to the selected VM"
          onChange={getParamsUpdater('floatingIp')}
          value={params.floatingIp}
          vmId={vm?.id}
          required
        />
      </div>
    </ModalForm>
  )
}
