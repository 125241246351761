import { makeStyles } from '@material-ui/styles'
import Text, { TextVariant } from 'pf9-ui-components/built/elements/Text'
import Tooltip from 'pf9-ui-components/built/elements/tooltip'
import { topMiddle } from 'pf9-ui-components/built/elements/menu/defaults'
import Theme from 'core/themes/model'
import React from 'react'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import clsx from 'clsx'
import colors from 'core/themes/base/colors'
import { getBadgeStyles } from './helpers'

export type BadgeVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'unknown'
  | 'danger'
  | 'error'
interface StyledBulletTextProps {
  label: string | any
  textVariant?: TextVariant
  rightBorder?: boolean
  padding?: boolean
  showTooltip?: boolean
  tooltipLabel?: string
  margin?: boolean
  id?: string
  icon?: string
  variant?: BadgeVariant
}
const defaultVariant = 'default'
export const StatusIconText = ({
  label,
  textVariant = 'body2',
  rightBorder = false,
  padding = false,
  showTooltip = false,
  tooltipLabel = label,
  margin = true,
  id,
  icon,
  variant = defaultVariant,
}: StyledBulletTextProps) => {
  const badgeStyles = { ...getBadgeStyles(variant), variant }
  const classes = useStyles(badgeStyles)

  return (
    <>
      {showTooltip ? (
        <Tooltip align={topMiddle.align} message={tooltipLabel}>
          <div
            className={classes.bulletTextWrapper}
            style={{
              padding: padding ? '0 8px 0 0' : '0',
              margin: margin ? '8px 0' : '0',
            }}
          >
            <FontAwesomeIcon size="md">{icon}</FontAwesomeIcon>
            <Text
              className={classes.customText}
              id={id}
              variant={textVariant}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              {label}
            </Text>
          </div>
        </Tooltip>
      ) : (
        <div
          className={classes.bulletTextWrapper}
          style={{
            padding: padding ? '0 8px 0 0' : '0',
            margin: margin ? '8px 0' : '0',
          }}
        >
          <div className={clsx(classes.badge)}>
            <FontAwesomeIcon size="lg">{icon}</FontAwesomeIcon>
          </div>
          <Text
            className={classes.customText}
            id={id}
            variant={textVariant}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            {label}
          </Text>
          {rightBorder && <div className={classes.separator}></div>}
        </div>
      )}
    </>
  )
}

export default StatusIconText

const useStyles = makeStyles<Theme, ReturnType<typeof getBadgeStyles> & { variant: BadgeVariant }>(
  (theme: Theme) => ({
    badge: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      padding: 0,
      color: ({ badgeColor, badgeColorShade }) => colors[badgeColor][badgeColorShade],
      backgroundColor: ({ badgeBgColor, badgeBgColorShade }) =>
        colors[badgeBgColor][badgeBgColorShade],
      whiteSpace: 'nowrap',
      border: ({ variant }) => `1px solid ${theme.components.badge?.[variant]?.background}`,
      '&:hover': {
        border: ({ variant }) => `1px solid ${theme.components.badge?.[variant]?.color}`,
      },
    },
    bulletTextWrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: `${theme.spacing(1)}px 0`,
      '&:last-child': {
        border: 'none',
      },
    },
    bullet: {
      display: 'inline-block',
      width: 8,
      height: 8,
      borderRadius: 100,
      marginRight: theme.spacing(1),
    },
    customText: {
      color: 'inherit',
      paddingLeft: theme.spacing(1),
    },
    separator: {
      width: '1px',
      height: '24px',
      backgroundColor: theme.components.card.border,
      margin: '8px',
    },
  }),
)
