import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'

import { PropertiesTable } from 'app/plugins/infrastructure/components/clusters/capi/details/PropertyCardTable'
import ClusterStatusSpan from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import { useAppSelector } from 'app/store'
import { getFieldsForCard } from 'core/components/InfoPanel'
import ModalForm from 'core/elements/modal/ModalForm'
import Text from 'core/elements/Text'
import useListAction from 'core/hooks/useListAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { listKaapiClusterUpgradeJobs } from 'k8s/components/kaapi/cluster-upgrade/actions'
import ClusterUpgradeProgressTracker from 'k8s/components/kaapi/cluster-upgrade/ClusterUpgradeProgressTracker'
import { clusterUpgradeStatusMap } from 'k8s/components/kaapi/cluster-upgrade/model'
import TimelineWithStatus from 'k8s/components/kaapi/cluster-upgrade/TimelineWithStatus'
import { listKaapiClusters } from 'k8s/components/kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from 'k8s/components/kaapi/clusters/selectors'
import { formatDate, uncamelizeString } from 'utils/misc'
import { routes } from 'core/utils/routes'
import EllipsisText from 'k8s/components/common/EllipsisText'

const TitleText = ({ title }) => {
  const classes = useStyles()

  return (
    <div className={classes.titleText}>
      View Upgrade Progress&nbsp;
      <Text variant="h4" className={classes.bracket}>
        (
      </Text>
      <EllipsisText
        className={classes.ellipsisText}
        maxWidth={350}
        variant="h4"
        title={title}
        showTooltip
        alignTooltip="bottomMiddle"
      >
        {title}
      </EllipsisText>
      <Text variant="h4" className={classes.bracket}>
        )
      </Text>
    </div>
  )
}

const UpgradeStatus = ({ status }) => {
  if (!status) return null
  return (
    <div>
      <ClusterStatusSpan iconStatus showCircleIcon status={clusterUpgradeStatusMap[status]}>
        <Text variant="caption1">{uncamelizeString(status)}</Text>
      </ClusterStatusSpan>
    </div>
  )
}

const renderUpgradeStatus = (status) => (status ? <UpgradeStatus status={status} /> : '-')

const metadataFields = [
  {
    id: 'targetVersion',
    title: 'Target Version',
    required: true,
    render: (value) => value || '-',
  },
  {
    id: 'phase',
    title: 'Overall Upgrade Status',
    render: renderUpgradeStatus,
    required: true,
  },
  {
    id: 'startTime',
    title: 'Start Time',
    required: true,
    render: (value) => (value ? formatDate(value) : 'N/A'),
  },
  {
    id: 'completionTime',
    title: 'Completion Time',
    required: true,
    render: (value) => (value ? formatDate(value) : 'N/A'),
  },
  {
    id: 'reason',
    title: 'Reason',
    condition: (upgradeJob) => upgradeJob?.phase === 'Failed',
  },
  {
    id: 'message',
    title: 'Message',
    condition: (upgradeJob) => upgradeJob?.phase === 'Failed',
  },
]

const ClusterUpgradeModal = ({ onClose }) => {
  const classes = useStyles({})
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')

  const { loading: loadingCapiClusters } = useListAction(listKaapiClusters)
  const { loading: loadingKaapiClusterUpgradeJobs } = useListAction(listKaapiClusterUpgradeJobs)
  const clusters = useAppSelector(kaapiClustersOverviewSelector)
  const cluster = clusters?.find(
    (cluster) => cluster.metadata.name === k8sPluginGlobalPerfs?.cluster,
  )
  // const clusterUpgradeJob = (cluster?.upgradeJobs || []).find((job) => job.id === jobId)
  const clusterUpgradeJob = (cluster?.upgradeJobs || []).find(
    (job) => job.clusterName === cluster.metadata.name,
  )

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, clusterUpgradeJob)
  }, [clusterUpgradeJob])

  return (
    <ModalForm
      route={routes.manage.upgradeStatus}
      onClose={onClose}
      title={<TitleText title={cluster?.metadata?.name} />}
      loading={loadingKaapiClusterUpgradeJobs || loadingCapiClusters}
    >
      <PropertiesTable fields={metadata} />
      <div className={classes.timelineContainer}>
        <TimelineWithStatus
          currentVersion={clusterUpgradeJob?.sourceVersion}
          targetVersion={clusterUpgradeJob?.targetVersion}
          status={clusterUpgradeStatusMap[clusterUpgradeJob?.phase]}
        />
        <div className={classes.currAndLatestVersions}>
          {clusterUpgradeJob?.phase === 'Completed' ? (
            <Text variant="body2">
              Current K8s Version:{' '}
              <b>
                {clusterUpgradeJob?.sourceVersion || '-'}{' '}
                <Text variant="caption1">(up to date)</Text>
              </b>
            </Text>
          ) : (
            <>
              <Text variant="body2">
                Current K8s Version: <b>{clusterUpgradeJob?.sourceVersion || '-'}</b>
              </Text>
              <Text variant="body2">
                Target K8s Version: <b>{clusterUpgradeJob?.targetVersion || '-'}</b>{' '}
              </Text>
            </>
          )}
        </div>
      </div>
      <ClusterUpgradeProgressTracker clusterUpgradeJob={clusterUpgradeJob} />
    </ModalForm>
  )
}

export default ClusterUpgradeModal

const useStyles = makeStyles((theme: Theme) => ({
  upgradeDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
  },
  timelineContainer: {
    maxWidth: '720px',
    marginTop: theme.spacing(1),
  },
  currAndLatestVersions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(4, 0),
  },
  clusterName: {
    fontWeight: 400,
  },
  titleText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ellipsisText: {
    fontWeight: theme.typography.body1.fontWeight,
  },
  bracket: {
    fontWeight: theme.typography.body1.fontWeight,
  },
}))
