import React, { useEffect, useState, useMemo } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Text from 'core/elements/Text'
import ProgressGraph from 'core/components/graphs/ProgressGraph'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { prop } from 'ramda'
import {
  getCinderQuotaUsage,
  getComputeQuotaUsage,
} from 'account/components/userManagement/tenants/new-actions'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    marginBottom: 16,
  },
  card: {
    padding: '16px 24px',
    background: theme.components.card.background,
    border: `1px solid ${theme.components.card.border}`,
    display: 'grid',
    gap: 12,
  },
  leftCard: {
    borderRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  innerLeftCard: {
    borderLeft: 'none',
  },
  innerRightCard: {
    borderLeft: 'none',
    borderRight: 'none',
  },
  rightCard: {
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icons: {
    display: 'flex',
    gridTemplateColumns: 'max-content max-content max-content max-content max-content',
    gap: 16,
  },
  iconWithCount: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  iconContainer: {
    background: theme.palette.grey[100],
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    width: 32,
  },
  success: {
    background: theme.palette.graphGreen[100],
    color: theme.palette.graphGreen[500],
  },
  warning: {
    background: theme.palette.graphYellow[100],
    color: theme.palette.graphYellow[500],
  },
  error: {
    background: theme.palette.graphRed[100],
    color: theme.palette.graphRed[500],
  },
  icon: {
    fontSize: '18px',
    // color: theme.palette.grey[500],
  },
  divider: {
    width: 1,
    height: 32,
    background: theme.components.card.text,
  },
}))

export default function QuotaUsageData({ vms }) {
  const classes = useStyles()

  const { prefs } = useScopedPreferences()
  const { currentTenant } = prefs
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { displayName, role, id: userId },
    features,
  } = session

  const [quotas, setQuotas] = useState<any>({})

  useEffect(() => {
    if (!userId || !currentTenant) {
      return
    }
    const makeCalls = async () => {
      let computeQuotas = {}
      let cinderQuotas = {}
      try {
        computeQuotas = await getComputeQuotaUsage({ tenantId: currentTenant })
      } catch (err) {
        console.error(err)
      }
      try {
        cinderQuotas = await getCinderQuotaUsage({ tenantId: currentTenant })
      } catch (err) {
        console.error(err)
      }
      setQuotas({
        ...computeQuotas,
        ...cinderQuotas,
      })
    }
    makeCalls()
  }, [userId, currentTenant])

  const vmCounts = useMemo(() => {
    return vms.reduce(
      (accum, vm) => {
        if (['active', 'building'].includes(vm?.state)) {
          return {
            ...accum,
            success: accum.success + 1,
          }
        } else if (['paused', 'suspended', 'stopped'].includes(vm?.state)) {
          return {
            ...accum,
            warning: accum.warning + 1,
          }
        } else {
          return {
            ...accum,
            error: accum.error + 1,
          }
        }
      },
      {
        success: 0,
        warning: 0,
        error: 0,
      },
    )
  }, [vms])

  return (
    <div className={classes.container}>
      <div className={clsx(classes.card, classes.leftCard)}>
        <Text variant="subtitle2">
          {vms?.length ? `VM Instances (${vms.length})` : 'VM Instances (0)'}
        </Text>
        <div className={classes.iconsContainer}>
          <div className={classes.icons}>
            <div className={classes.iconWithCount}>
              <div className={classes.iconContainer}>
                <FontAwesomeIcon className={clsx(classes.icon, classes.success)}>
                  circle-check
                </FontAwesomeIcon>
              </div>
              <Text variant="body2">{vmCounts?.success}</Text>
            </div>
            <div className={classes.divider} />
            <div className={classes.iconWithCount}>
              <div className={classes.iconContainer}>
                <FontAwesomeIcon className={clsx(classes.icon, classes.warning)}>
                  circle-exclamation
                </FontAwesomeIcon>
              </div>
              <Text variant="body2">{vmCounts?.warning}</Text>
            </div>
            <div className={classes.divider} />
            <div className={classes.iconWithCount}>
              <div className={classes.iconContainer}>
                <FontAwesomeIcon className={clsx(classes.icon, classes.error)}>
                  triangle-exclamation
                </FontAwesomeIcon>
              </div>
              <Text variant="body2">{vmCounts?.error}</Text>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(classes.card, classes.innerLeftCard)}>
        <Text variant="subtitle2">VCPUs</Text>
        <ProgressGraph
          label="Cores"
          usage={quotas?.cores?.in_use}
          capacity={quotas?.cores?.limit}
        />
      </div>
      <div className={clsx(classes.card, classes.innerRightCard)}>
        <Text variant="subtitle2">RAM</Text>
        <ProgressGraph label="MiB" usage={quotas?.ram?.in_use} capacity={quotas?.ram?.limit} />
      </div>
      <div className={clsx(classes.card, classes.rightCard)}>
        <Text variant="subtitle2">Volume Storage</Text>
        <ProgressGraph
          label="GB"
          usage={quotas?.gigabytes?.in_use}
          capacity={quotas?.gigabytes?.limit}
        />
      </div>
    </div>
  )
}
