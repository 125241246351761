import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

import Text from 'core/elements/Text'

interface Props {
  title: string
  icon: string
  actions: React.ReactNode[]
  footerTitle?: string
  className?: string
}

export default function ClustersInfoPage({
  title,
  footerTitle = undefined,
  className,
  children,
  icon,
  actions = [],
}: PropsWithChildren<Props>) {
  const classes = useStyles()

  return (
    <div className={classes.productInfoPage}>
      {/* Left Content */}
      <div className={classes.leftContent}>
        <Text
          variant="h2"
          component="header"
          className={clsx('product-info-title', classes.productInfoTitle)}
        >
          {title}
        </Text>
        <div className={clsx('product-info-body', classes.productInfoBody)}>{children}</div>
        <footer className={clsx('product-info-footer', classes.productInfoFooter)}>
          {footerTitle && (
            <Text variant="caption2" className={classes.productInfoFooterTitle}>
              {footerTitle}
            </Text>
          )}
          {actions}
        </footer>
      </div>

      {/* Right Icon */}
      {/* <figure className={clsx('product-info-figure', classes.productInfoFigure)}>{icon}</figure> */}
      <img src={icon} className={classes.productInfoFigure} />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  productInfoPage: {
    display: 'grid',
    gridTemplateColumns: '1fr 400px', // Left takes available space, right is fixed at 400px
    gap: '32px', // Space between left and right content
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto', // Center the content horizontally
    padding: '20px',
  },
  leftContent: {
    display: 'grid',
    gridTemplateRows: 'max-content auto max-content',
    gap: '16px',
  },
  productInfoTitle: {
    maxWidth: 600,
    // gridArea: 'product-info-title',
    marginTop: 16,
  },
  productInfoBody: {
    fontSize: '1rem',
    lineHeight: 1.5,
    maxWidth: 600,
  },
  productInfoFooter: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxWidth: 600,
  },
  productInfoFooterTitle: {
    color: theme.components.typography.passive,
    textTransform: 'uppercase',
  },
  productInfoFigure: {
    width: 400,
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.components.iconButton.background,
    border: `1px solid ${theme.components.iconButton.border}`,
    borderRadius: '100%',
  },
}))
