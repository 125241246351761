import React, { Fragment, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Button from 'core/elements/button/Button'
import AddVolumeType from './AddVolumeType'
import Text from 'core/elements/Text'
import VolumeBackendConfigurationCards from './VolumeBackendConfigurationCards'
import { intersection, without, update } from 'ramda'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import TextField from 'core/components/validatedForm/TextField'
import Tooltip from 'core/elements/tooltip'
import clsx from 'clsx'
import { customValidator } from 'core/utils/fieldValidators'
import { uniq } from 'cypress/types/lodash'

const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
  },
  emptyHeader: {
    borderBottom: `1px solid ${theme.components.table.border}`,
  },
  header: {
    borderBottom: `1px solid ${theme.components.table.border}`,
    padding: '4px 8px',
    marginBottom: 8, // special for storage volume type table
  },
  cell: {
    padding: '8px 8px 16px',
  },
  sameLineCell: {
    display: 'grid',
    gap: 8,
    alignItems: 'center',
    gridTemplateColumns: 'max-content auto',
  },
  remove: {
    color: theme.components.badge.primary.color,
    cursor: 'pointer',
  },
  noCursor: {
    color: `${theme.components.badge.unknown.color} !important`,
    cursor: 'not-allowed',
  },
}))


const canDelete = ({ volumeTypeId, volumeBackendConfigs }) => {
  const volumeTypeConfigs = volumeBackendConfigs?.filter(
    (config) => config?.volumeTypeId === volumeTypeId,
  )
  return volumeTypeConfigs?.length < 1
}

export default function StorageVolumeTypeTable({
  params,
  updateParams,
  getParamsUpdater,
  usedVolumeBackends,
}) {
  const classes = useStyles()

  const removeVolumeTypeFn = useCallback(
    (type) => {
      updateParams({ volumeTypes: without([type], params.volumeTypes) })
    },
    [params.volumeTypes],
  )
  
  const uniqueVolumeTypeNameValidator = customValidator((name) => {
    const matchingVolumeTypeNames = params.volumeTypes
      ?.map((type) => type.type)
      ?.filter((type) => type === name)
    return matchingVolumeTypeNames.length < 2
  }, `Volume Type name must be unique`)

  const updateVolumeTypeName = useCallback(
    (name, id, idx) => {
      // update both the volume type name as well as the property
      // in the associated volume backend configs
      const updatedVolumeBackendConfigs = params.volumeBackendConfigs.map((config) => {
        return config.volumeTypeId === id
          ? {
              ...config,
              volumeType: name,
            }
          : config
      })
      updateParams({
        volumeTypes: update(idx, { type: name, id }, params.volumeTypes),
        volumeBackendConfigs: updatedVolumeBackendConfigs,
      })
    },
    [params.volumeTypes, params.volumeBackendConfigs],
  )

  return (
    <div className={classes.table}>
      <div className={classes.header}>
        <Text variant="caption1">Volume Type</Text>
      </div>
      <div className={classes.header}>
        <Text variant="caption1">Volume Backend Configurations</Text>
      </div>
      {params?.volumeTypes?.map((volumeType, idx) => {
        const deletable = canDelete({
          volumeTypeId: volumeType.id,
          volumeBackendConfigs: params.volumeBackendConfigs,
        })
        return (
          <Fragment key={idx}>
            <div className={classes.cell}>
              <div className={classes.sameLineCell}>
                <Tooltip
                  message={
                    deletable
                      ? ''
                      : 'You must remove the volume backend configurations for this volume type before you can delete it'
                  }
                >
                  <FontAwesomeIcon
                    className={clsx(classes.remove, !deletable && classes.noCursor)}
                    onClick={() => {
                      if (!deletable) {
                        return
                      }
                      removeVolumeTypeFn(volumeType)
                    }}
                    solid
                  >
                    circle-minus
                  </FontAwesomeIcon>
                </Tooltip>
                <TextField
                  id={`volumeTypes.${idx}.type`}
                  label=""
                  onChange={(value) => updateVolumeTypeName(value, volumeType.id, idx)}
                  value={volumeType.type}
                  placeholder="Volume Type Name"
                  validations={[uniqueVolumeTypeNameValidator]}
                  required
                />
              </div>
            </div>
            <div className={classes.cell}>
              <VolumeBackendConfigurationCards
                params={params}
                updateParams={updateParams}
                getParamsUpdater={getParamsUpdater}
                volumeType={volumeType}
                usedVolumeBackends={usedVolumeBackends}
              />
            </div>
          </Fragment>
        )
      })}
      <div className={classes.cell}>
        <AddVolumeType
          params={params}
          updateParams={updateParams}
          getParamsUpdater={getParamsUpdater}
        />
      </div>
    </div>
  )
}
