import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useState } from 'react'

import { RootState } from 'app/store'
import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import Dropdown from 'core/elements/dropdown'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import Theme from 'core/themes/model'
import { validators } from 'core/utils/fieldValidators'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'
import { listKaapiClusters } from '../kaapi/clusters/actions'
import { updateKaapiMachineDeployment } from '../kaapi/machine-deployment/actions'
import { middleLeft } from 'core/elements/menu/defaults'

export const enum NodeUpdateStrategyTypes {
  Count = 'count',
  Percentage = 'percent',
}
const rollingUpdateTypeOptions = [
  { label: 'Count', value: NodeUpdateStrategyTypes.Count },
  { label: 'Percentage', value: NodeUpdateStrategyTypes.Percentage },
]

const convertToPercentageStr = (value: string | number) => {
  if (!value) return value
  return value.toString() + '%'
}

//TODO: Need to refactor as its not working with getParamsUpdater
// function parsePercentageValue(value) {
//   if (typeof value === 'string' && value.includes('%')) {
//     return parseFloat(value.replace('%', ''))
//   } else if (typeof value === 'number') {
//     return value
//   }
//   return null // Default case for invalid values
// }

const useStyles = makeStyles<Theme>((theme) => ({
  fields: {
    display: 'grid',
    gridGap: theme.spacing(4),
  },
}))

interface Props {
  rows: any[]
  onClose: () => void
}

const getValidationRules = (
  type: string,
  params: { maxSurgeValue: number; maxUnavailableValue: number; nodes: number },
) => {
  if (type === 'percent') {
    return [
      params.maxSurgeValue === 0 && params.maxUnavailableValue === 0
        ? validators.rangeValueWithLable(
            params.maxSurgeValue,
            params.maxUnavailableValue,
            'Max Surge Value',
            'Max Unavailable Value',
          )
        : validators.rangeValue(0, 100),
    ]
  } else {
    return [
      params.maxSurgeValue === 0 && params.maxUnavailableValue === 0
        ? validators.rangeValueWithLable(
            params.maxSurgeValue,
            params.maxUnavailableValue,
            'Max Surge Value',
            'Max Unavailable Value',
          )
        : validators.rangeValue(0, params.nodes),
    ]
  }
}

export default function EditClusterNodeGroupsModal({ rows: [nodeGroupDetails], onClose }: Props) {
  const classes = useStyles()
  const { activeKaapiTenant: namespace } = useSelector<RootState, SessionState>(
    prop(sessionStoreKey),
  )
  const defaultParams = {
    name: nodeGroupDetails?.name,
    nodes: nodeGroupDetails?.nodes,
    strategy: nodeGroupDetails?.strategy,
    maxSurgeType: nodeGroupDetails?.maxSurge,
    maxSurgeValue:
      typeof nodeGroupDetails?.maxSurgeValue === 'string' &&
      nodeGroupDetails.maxSurgeValue.includes('%')
        ? parseFloat(nodeGroupDetails.maxSurgeValue.replace('%', ''))
        : typeof nodeGroupDetails?.maxSurgeValue === 'number'
        ? nodeGroupDetails.maxSurgeValue
        : null,
    maxUnavailableType: nodeGroupDetails?.maxUnavailable,
    maxUnavailableValue:
      typeof nodeGroupDetails?.maxUnavailableValue === 'string' &&
      nodeGroupDetails.maxUnavailableValue.includes('%')
        ? parseFloat(nodeGroupDetails.maxUnavailableValue.replace('%', ''))
        : typeof nodeGroupDetails?.maxUnavailableValue === 'number'
        ? nodeGroupDetails.maxUnavailableValue
        : null,
  }

  const [maxSurgeType, setMaxSurgeType] = useState(
    defaultParams?.maxSurgeType.toLowerCase() || NodeUpdateStrategyTypes.Count,
  )
  const [maxUnavailableType, setMaxUnavailableType] = useState(
    defaultParams?.maxUnavailableType.toLowerCase() || NodeUpdateStrategyTypes.Count,
  )
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)
  const { update, updating, error, reset } = useUpdateAction(updateKaapiMachineDeployment)
  const { reload: reloadKaapiClusters } = useListAction(listKaapiClusters)

  const submitForm = useCallback(async (values) => {
    const body = [
      {
        op: 'replace',
        path: '/spec/strategy/type',
        value: values.nodesUpdateStrategy,
      },
      {
        op: 'replace',
        path: '/spec/strategy/rollingUpdate/maxSurge',
        value:
          values.maxSurgeType === 'percent'
            ? convertToPercentageStr(values.maxSurgeValue)
            : values.maxSurgeValue,
      },
      {
        op: 'replace',
        path: '/spec/strategy/rollingUpdate/maxUnavailable',
        value:
          values.maxUnavailableType === 'percent'
            ? convertToPercentageStr(values.maxUnavailableValue)
            : values.maxUnavailableValue,
      },
    ]
    onClose()
    await update({ namespace, name: params.name, customBody: body })
    reloadKaapiClusters(true)
  }, [])

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  return (
    <ModalForm
      title={`Edit`}
      entityName={params.name}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Save Changes`}
      disableSubmit={false}
      open
      maxWidth={464}
    >
      <div className={classes.fields}>
        <DropdownField
          DropdownComponent={Dropdown}
          id="nodesUpdateStrategy"
          label="Strategy"
          value="RollingUpdate"
          disabled
          items={[{ label: 'Rolling Update', value: 'RollingUpdate' }]}
          info={
            <>
              A <b>rolling update</b> allows a Node Group update to take place with zero or
              controlled number of nodes to be down.
            </>
          }
          align={middleLeft.align}
        />
        <DropdownField
          DropdownComponent={Dropdown}
          id="maxSurgeType"
          label="Max Surge Type"
          value={maxSurgeType}
          items={rollingUpdateTypeOptions}
          onChange={(value) => {
            setMaxSurgeType(value)
          }}
          info={
            <>
              The maximum number of machines that can be scheduled above the desired number of
              nodes.
              <br /> Value can be an absolute number (ex: 5) or a percentage of desired Nodes (ex:
              10%).
              <br /> This can not be 0 if MaxUnavailable is 0.
              <br /> Absolute number is calculated from percentage by rounding up.
              <br /> Defaults to 1.
            </>
          }
          align={middleLeft.align}
        />
        <TextField
          id="maxSurgeValue"
          label="Max Surge Value"
          onChange={getParamsUpdater('maxSurgeValue')}
          value={params.maxSurgeValue}
          type="number"
          required
          icon={maxSurgeType === 'percent' ? 'percent' : ''}
          iconProps={{ placement: 'end' }}
          validations={getValidationRules(maxSurgeType, params)}
          info={
            <>
              Maximum number/percentage of nodes that can be scaled up above the desired Node Group
              replica count.
              <br /> Applicable values:
              <br /> Minimum = 0<br /> Maximum = Any number / percentage
              <br /> Default = 0
            </>
          }
          align={middleLeft.align}
        />
        <DropdownField
          DropdownComponent={Dropdown}
          id="maxUnavailableType"
          label="Max Unavailable Type"
          value={maxUnavailableType}
          items={rollingUpdateTypeOptions}
          onChange={(value) => {
            setMaxUnavailableType(value)
          }}
          info={
            <>
              The maximum number of nodes that can be unavailable during the update.
              <br /> Value can be an absolute number (ex: 5) or a percentage of desired nodes (ex:
              10%).
              <br /> Absolute number is calculated from percentage by rounding down.
              <br /> This cannot be 0 if MaxSurge is 0.
              <br /> Defaults to 0.
            </>
          }
          align={middleLeft.align}
        />
        <TextField
          id="maxUnavailableValue"
          label="Max Unavailable Value"
          onChange={getParamsUpdater('maxUnavailableValue')}
          value={params.maxUnavailableValue}
          type="number"
          icon={maxUnavailableType === 'percent' ? 'percent' : ''}
          iconProps={{ placement: 'end' }}
          required
          validations={getValidationRules(maxUnavailableType, params)}
          info={
            <>
              Maximum number/percentage of nodes that can unavailable.
              <br /> Applicable values:
              <br /> Minimum = 0<br /> Maximum = Node group replica count / 100 %<br /> Default = 0
            </>
          }
          align={middleLeft.align}
        />
      </div>
    </ModalForm>
  )
}
