import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import { updateVmMetadata } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'

export default function EditVmSecurityGroupsModal({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const defaultParams = useMemo(
    () => ({
      metadata: Object.entries(vm.metadata).map(([key, value]) => {
        return { key, value }
      }),
    }),
    [vm.metadata],
  )

  const { params, getParamsUpdater, updateParams, setParams } = useParams<{
    metadata: any
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(updateVmMetadata)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose(true)
  }

  const submitForm = useCallback(async () => {
    const metadata = params.metadata.reduce((accum, pair) => {
      return {
        ...accum,
        [pair.key]: pair.value,
      }
    }, {})
    const body = {
      metadata,
    }
    const { success } = await update({
      id: vm.id,
      body,
    })
    if (success) {
      handleClose()
    }
  }, [vm, params, handleClose])

  return (
    <ModalForm
      open
      title={`Edit VM Metadata`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Update Metadata`}
      loading={false}
    >
      <KeyValuesField
        id="metadata"
        label="Metadata"
        value={params.metadata}
        onChange={getParamsUpdater('metadata')}
        addLabel="Add Metadata"
        allowMultipleValues
      />
    </ModalForm>
  )
}
