import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import colors from 'core/themes/base/colors'
import { hexToRgbaCss } from 'core/utils/colorHelpers'

interface StyleProps {
  status?: string
}

interface Props {
  currentVersion?: string
  targetVersion?: string
  status?: 'ok' | 'fail' | 'loading'
}

const TimelineWithStatus = ({
  currentVersion = '',
  targetVersion = '',
  status = 'loading',
}: Props) => {
  const classes = useStyles({ status })
  return (
    <div className={classes.container}>
      <div className={classes.line}></div>
      <div className={classes.point}>
        <Text className={classes.label} variant="body2">
          {currentVersion}
        </Text>
      </div>
      <div className={classes.targetDottedLine}></div>
      <div className={classes.targetPoint}>
        <Text className={classes.label} variant="body2">
          {targetVersion}
        </Text>
      </div>
      <div className={classes.dottedLine}></div>
      <div className={classes.arrow}></div>
    </div>
  )
}

export default TimelineWithStatus
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(5),
  },
  line: {
    background: theme.palette.blue[700],
    height: 2,
    width: '100%',
  },
  point: {
    background: theme.palette.blue[700],
    width: 25,
    height: 8,
    borderRadius: '50%',
    position: 'relative',
    boxShadow: ({ status }) => {
      if (status === 'loading') return `0 0 0 4px ${hexToRgbaCss(theme.palette.blue['700'], 0.1)}`
      return `none`
    },
  },
  targetPoint: {
    width: ({ status }) => (status === 'loading' ? 20 : 25),
    height: ({ status }) => (status === 'loading' ? 6 : 8),
    borderRadius: '50%',
    position: 'relative',
    background: ({ status }) => {
      if (status === 'ok') return colors.teal[100]
      if (status === 'fail') return colors.pink[800]
      if (status === 'loading') return theme.palette.common.white
      return theme.palette.grey[500]
    },
    border: ({ status }) =>
      status === 'loading' ? `2px solid ${theme.palette.blue[700]}` : 'none',
    boxShadow: ({ status }) => {
      if (status === 'ok') return `0 0 0 4px ${hexToRgbaCss(colors.teal[100], 0.1)}`
      if (status === 'fail') return `0 0 0 4px ${hexToRgbaCss(colors.pink[800], 0.1)}`
      if (status === 'loading') return `none`
      return `0 0 0 2px ${theme.palette.grey['500']}`
    },
  },
  label: {
    bottom: '15px',
    position: 'absolute',
    right: '-15px',
  },
  dottedLine: {
    background: `repeating-linear-gradient(to right,${theme.palette.grey['500']} 0,${theme.palette.grey['500']} 10px,transparent 10px,transparent 16px)`,
    height: 2,
    width: '100%',
  },
  targetDottedLine: {
    height: 2,
    width: '100%',
    background: ({ status }) => {
      if (status === 'ok') return `${colors.teal[100]}`
      if (status === 'fail') return `${colors.pink[800]}`
      if (status === 'loading')
        return `repeating-linear-gradient(to right,${theme.palette.blue['700']} 0,${theme.palette.blue['700']} 10px,transparent 10px,transparent 16px)`
      return `repeating-linear-gradient(to right,${theme.palette.grey['500']} 0,${theme.palette.grey['500']} 10px,transparent 10px,transparent 16px)`
    },
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid grey',
    rotate: '-90deg',
  },
}))
