import DocumentMeta from 'core/components/DocumentMeta'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import React, { useEffect, useMemo } from 'react'
import { isNilOrEmpty } from 'utils/fp'
import { listTablePrefs } from 'app/constants'
import SimpleLink from 'core/components/SimpleLink'
import Badge from 'core/elements/badge'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import Text from 'core/elements/Text'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import EllipsisText from '../common/EllipsisText'
import { isClusterDeleting, isKaapiClusterHealthy } from '../dashboard/helpers'
import { listKaapiClusters } from '../kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import DeleteK8sClustersDialog from './DeleteK8sClustersDialog'
import { extractSupportedK8sVersions } from '../cluster/deployment/form-components/helpers'
import { kaapiConfigMapsSelector } from 'k8s/components/kaapi/config-maps/selectors'
import { listKaapiConfigMaps } from '../kaapi/config-maps/actions'
import K8sVersionCellComponent from './K8sVersionCellComponent'

const cellFormatFn = (value) => (isNilOrEmpty(value) ? '-' : value)
const usePrefParams = createUsePrefParamsHook('Clusters', listTablePrefs)

export default function ListK8sClusters() {
  const { getParamsUpdater } = usePrefParams({})
  const { history } = useReactRouter()

  // Load the list of clusters
  const { loading: loadingKaapiClusters, reload: reloadKaapiClusters } = useListAction(
    listKaapiClusters,
  )
  const clusters = useSelector(kaapiClustersOverviewSelector)

  // Load the list of config maps
  const { loading: loadingConfigMaps } = useListAction(listKaapiConfigMaps)
  const configMaps = useSelector(kaapiConfigMapsSelector)
  // Get the supported K8s versions from the config maps
  const supportedK8sVersions = useMemo(() => extractSupportedK8sVersions(configMaps).sort(), [
    configMaps,
  ])

  // Reload the list of clusters on first load
  useEffect(() => {
    reloadKaapiClusters(true, false)
  }, [])

  const columns: GridViewColumn<any>[] = [
    {
      key: 'matadata.name',
      label: 'Name',
      CellComponent: (props) => {
        return (
          <SimpleLink
            src=""
            onClick={() =>
              history.push(routes.manage.overview.path({ cluster: props?.item?.metadata?.name }))
            }
          >
            {props?.item?.metadata?.name}
          </SimpleLink>
        )
      },
    },
    {
      key: 'status.phase',
      label: 'Status',
      CellComponent: (props) => {
        const isClusterHealthy = isKaapiClusterHealthy(props?.item)

        return (
          <Badge
            data-testid={'overview-tab-emp-status'}
            variant={isClusterHealthy && !isClusterDeleting(props?.item) ? 'success' : 'error'}
            text={
              isClusterDeleting(props?.item)
                ? 'Deleting'
                : isClusterHealthy
                ? 'Healthy'
                : 'Unhealthy'
            }
            ellipsisAt={20}
            tooltipBody={''}
          />
        )
      },
    },
    {
      key: 'infrastrcutreProvider',
      label: 'Infrastructure Provider',
    },
    {
      key: 'hostedControlPlane.spec.version',
      label: 'K8s Version',
      CellComponent: ({ item }) => (
        <K8sVersionCellComponent item={item} supportedK8sVersions={supportedK8sVersions} />
      ),
    },

    // // TODO:: Add support period
    // {
    //   key: 'status.replicas',
    //   label: 'Support Period',
    //   formatFn: cellFormatFn,
    // },
    // // TODO:: need to confirm this and update the logic for the same currently added status logic
    // {
    //   key: 'status.readyReplicas',
    //   label: 'Nodes Status',
    //   //  TODO:: need to confirm this and update the logic for the same
    //   CellComponent: (props) => {
    //     const isClusterHealthy = isKaapiClusterHealthy(props?.item)

    //     return (
    //       <Badge
    //         data-testid={'overview-tab-emp-status'}
    //         variant={isClusterHealthy && !isClusterDeleting(props?.item) ? 'success' : 'error'}
    //         text={
    //           isClusterDeleting(props?.item)
    //             ? 'Deleting'
    //             : isClusterHealthy
    //             ? 'Healthy'
    //             : 'Unhealthy'
    //         }
    //         ellipsisAt={20}
    //         tooltipBody={''}
    //       />
    //     )
    //   },
    // },
    {
      key: 'hostedControlPlane.spec.hcpClass',
      label: 'Control Plane Class',
      width: 150,
      CellComponent: (props) => {
        return (
          <div style={{ width: '150px' }}>
            <Text variant="body2">{`Highly available - 3 masters(${props?.item?.hostedControlPlane?.spec?.hcpClass})`}</Text>
          </div>
        )
      },
    },
    {
      key: 'hostedControlPlane.spec.hostname',
      label: 'API Endpoint',
      CellComponent: (props) => {
        return (
          <EllipsisText
            title={props?.item?.hostedControlPlane?.spec?.hostname}
            maxWidth={200}
            showTooltip
            variant="body2"
          >
            {props?.item?.hostedControlPlane?.spec?.hostname}
          </EllipsisText>
        )
      },
    },
    {
      key: 'metadata.creationTimestamp',
      label: 'Created',
      CellComponent: DateAndTime,
    },
  ]
  const batchActions = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      BatchActionButton: getGridDialogButton(DeleteK8sClustersDialog, {
        getDialogProps: (selectedRows) => ({ rows: selectedRows }),
      }),
    },
  ]

  return (
    <>
      <DocumentMeta title="Clusters" />
      <ListContainer<any, any>
        searchTargets={['metadata.name', 'status.phase']}
        uniqueIdentifier="uid"
        loading={loadingKaapiClusters || loadingConfigMaps}
        loadingMessage="Loading Clusters..."
        onRefresh={reloadKaapiClusters}
        multiSelection
        data={clusters}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        label="Clusters"
        showItemsCountInLabel
        showBreadcrumbs={false}
        batchActions={batchActions}
        emptyContent="No clusters found"
      />
    </>
  )
}
