import { setActiveRegion } from 'api-client/helpers'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { propEq, find, pipe, head, prop, isEmpty } from 'ramda'
import { appUrlRoot, loginWithParams, loginWithSsoUrl } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { useSelector } from 'react-redux'
import useListAction from 'core/hooks/useListAction'
import { listRegions } from 'app/plugins/infrastructure/components/regions/actions'
import { regionsSelector } from 'app/plugins/infrastructure/components/regions/selectors'
import withProgress from 'core/components/progress/withProgress'
import Dropdown from 'core/elements/dropdown'
import { projectAs } from 'utils/fp'
import { groupByRegion } from 'api-client/Keystone'
import ApiClient from 'api-client/ApiClient'

const AsyncDropdown = withProgress(Dropdown, { inline: true })
const isDevelopment = process.env.NODE_ENV === 'development'
const currentSectionRegex = new RegExp(`^${appUrlRoot}/[^/]+/?[^/]*`, 'i')

const RegionDropdown = (props) => {
  const { serviceCatalog } = ApiClient.getInstance()
  const { prefs, updatePrefs } = useScopedPreferences()
  const { currentTenant, currentRegion } = prefs

  // Load regions
  const { loading: loadingRegions, reload: reloadRegions } = useListAction(listRegions)
  const regionsData = useSelector(regionsSelector)
  const regions = useMemo(() => {
    if (regionsData?.length === 1) {
      return regionsData
    }
    return regionsData.filter((region) => region.id !== 'Infra')
  }, [regionsData])
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { activeRegion } = session

  const curRegionId = useMemo(() => {
    if (currentRegion && find(propEq('id', currentRegion), regions)) {
      return currentRegion
    }
    return pipe(head, prop('id'))(regions)
  }, [regions, currentRegion])

  useEffect(() => {
    // Reload region when changing the current tenant
    if (isEmpty(regions) && loadingRegions === undefined) {
      reloadRegions(true)
    }
  }, [currentTenant, regions])

  const handleRegionSelect = useCallback(
    async (regionId) => {
      if (regionId === curRegionId && regionId === activeRegion) {
        return
      }
      updateRegion(regionId)
    },
    [curRegionId],
  )

  const redirectToRegionLogin = (regionURL) => {
    const { tokenData, username, unscopedToken, isSsoToken, domain } = session
    const regionEndpoint = new URL(regionURL).origin

    // If the region is the same as the current origin, no need to redirect
    if (regionEndpoint === window.location.origin && !isDevelopment) {
      return
    }

    // Create a redirect URL with the region endpoint and the current token data
    const url = new URL(
      `${regionEndpoint}${isSsoToken ? loginWithSsoUrl : loginWithParams}?issuedAt=${
        tokenData?.issuedAt
      }&username=${username}&unscopedToken=${unscopedToken}&expiresAt=${
        tokenData?.expiresAt
      }&ssoLogin=${isSsoToken}&domain=${domain}`,
    )

    // Open the new region in new tab
    window.open(url)
  }

  // Get region endpoints
  const regionEndpoints = useMemo(() => {
    const servicesByRegion = groupByRegion(serviceCatalog)
    const regionKeys = Object.keys(servicesByRegion)
    return regionKeys.reduce((accum, region) => {
      const resmgrEndpoint = servicesByRegion?.[region]?.resmgr?.public?.url
      const baseUrl = resmgrEndpoint?.split('/resmgr')?.[0]
      return {
        ...accum,
        [region]: baseUrl,
      }
    }, {})
  }, [serviceCatalog])

  // useEffect(() => {
  //   // If the region is already set, don't do anything
  //   if (activeRegion && activeRegion !== 'Infra') {
  //     return
  //   }

  //   const setUpRegion = async () => {
  //     const servicesByRegion = groupByRegion(serviceCatalog)
  //     const regionKeys = Object.keys(servicesByRegion)
  //     const calcCurrentRegion = regionKeys.find((region) => {
  //       return servicesByRegion[region]?.resmgr?.public?.url?.includes(window.location.host)
  //     })
  //     if (calcCurrentRegion) {
  //       updatePrefs({ currentRegion: calcCurrentRegion })
  //       await setActiveRegion(calcCurrentRegion)
  //     } else {
  //       updatePrefs({ currentRegion: curRegionId })
  //       await setActiveRegion(curRegionId)
  //     }
  //   }
  //   setUpRegion()
  // }, [regions, activeRegion, serviceCatalog, curRegionId])

  const updateRegion = useCallback(
    async (regionId) => {
      try {
        updatePrefs({ currentRegion: regionId })
        const redirectUrl = isDevelopment ? window.location.origin : regionEndpoints[regionId]
        redirectToRegionLogin(redirectUrl)
        await setActiveRegion(regionId)
      } catch (err) {
        console.error(err)
      }
    },
    [isDevelopment, regionEndpoints],
  )

  const options = useMemo(() => projectAs({ label: 'id', value: 'id' }, regions), [regions])

  return (
    <AsyncDropdown
      name="region"
      label="Region"
      compact={true}
      value={activeRegion}
      onChange={(region) => handleRegionSelect(region)}
      loading={loadingRegions}
      items={options}
    />
  )
}

export default RegionDropdown
