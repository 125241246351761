import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import ModalForm from 'core/elements/modal/ModalForm'
import { addSecurityGroups, removeSecurityGroups } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import { listSecurityGroups } from '../networks/security-groups/actions'
import { securityGroupsSelector } from '../networks/security-groups/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { difference } from 'ramda'

const securityGroupColumns = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'description',
    label: 'Description',
  },
]

export default function EditVmSecurityGroupsModal({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const [loaded, setLoaded] = useState(false)
  const defaultParams = {
    securityGroups: [],
    currentSecurityGroups: [],
  }

  const { params, getParamsUpdater, updateParams, setParams } = useParams<{
    securityGroups?: any[]
    currentSecurityGroups?: string[]
  }>(defaultParams)

  const { loading: loadingSecurityGroups } = useListAction(listSecurityGroups, {
    params: {},
  })
  const securityGroupsList = useSelectorWithParams(securityGroupsSelector, {})

  const {
    update: addGroups,
    updating: addingGroups,
    error: addGroupsError,
    reset: resetAddGroups,
  } = useUpdateAction(addSecurityGroups)
  const {
    update: removeGroups,
    updating: removingGroups,
    error: removeGroupsError,
    reset: resetRemoveGroups,
  } = useUpdateAction(removeSecurityGroups)

  useEffect(() => {
    // Prevent resetting of form from background reloads
    if (loaded || loadingSecurityGroups) {
      return
    }
    const initialSecurityGroups = vm?.security_groups?.map((group) => group?.name) || []
    const securityGroups = securityGroupsList.filter((group) =>
      initialSecurityGroups.includes(group?.name),
    )
    updateParams({
      securityGroups,
      currentSecurityGroups: initialSecurityGroups,
    })
    setLoaded(true)
  }, [vm, loadingSecurityGroups, securityGroupsList, loaded])

  const handleClose = () => {
    setParams(defaultParams)
    resetAddGroups()
    resetRemoveGroups()
    setLoaded(false)
    onClose(true)
  }

  const submitForm = useCallback(async () => {
    const groupNames = params.securityGroups.map((group) => group?.name)
    const securityGroupsToAdd = difference(groupNames, params.currentSecurityGroups)
    const securityGroupsToRemove = difference(params.currentSecurityGroups, groupNames)
    const { success: addSuccess } = await addGroups({
      id: vm.id,
      securityGroupNames: securityGroupsToAdd,
    })
    const { success: removeSuccess } = await removeGroups({
      id: vm.id,
      securityGroupNames: securityGroupsToRemove,
    })
    if (addSuccess || removeSuccess) {
      handleClose()
    }
  }, [vm, params, handleClose])

  return (
    <ModalForm
      open
      title={`Edit VM Security Groups`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={addingGroups || removingGroups}
      error={addGroupsError || removeGroupsError}
      submitTitle={`Update Security Groups`}
      loading={!loaded}
    >
      <FormFieldSection title="Security Groups">
        <Text variant="body2">
          Add or remove security groups on VM <b>{vm?.name || vm?.id}</b>
        </Text>
        <ListTableField
          id="securityGroups"
          data={securityGroupsList}
          loading={loadingSecurityGroups}
          columns={securityGroupColumns}
          onChange={getParamsUpdater('securityGroups')}
          value={params.securityGroups}
          uniqueIdentifier="id"
          searchTargets={['name', 'description']}
          multiSelection
        />
      </FormFieldSection>
    </ModalForm>
  )
}
