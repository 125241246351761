interface KaapiClusterParams {
  name: string
  namespace: string
  hostedControlPlaneRefName: string
  infrastructureRefName: string
  version: string
}

export const createKaapiClusterBody = ({
  name,
  namespace,
  hostedControlPlaneRefName,
  infrastructureRefName,
  version,
}: KaapiClusterParams) => {
  // Get cas-capi-version from the version
  const versionParts = version.split('.')
  versionParts.pop()
  const casCapiVersion = `v${versionParts.join('.')}`

  return {
    apiVersion: 'cluster.x-k8s.io/v1beta1',
    kind: 'Cluster',
    metadata: {
      name,
      namespace,
      labels: {
        'core-addons': 'enabled',
        'proxy-addons': 'enabled',
        'cas-capi-version': casCapiVersion,
      },
    },
    spec: {
      clusterNetwork: {
        apiServerPort: 443,
        pods: {
          cidrBlocks: ['10.244.0.0/16'],
        },
        services: {
          cidrBlocks: ['10.96.0.0/16'],
        },
      },
      controlPlaneEndpoint: {
        host: `${name}.app.staging-pcd.platform9.com`,
        port: 443,
      },
      controlPlaneRef: {
        apiVersion: 'controlplane.platform9.io/v1alpha1',
        kind: 'HostedControlPlane',
        name: hostedControlPlaneRefName,
      },
      infrastructureRef: {
        apiVersion: 'infrastructure.cluster.x-k8s.io/v1beta1',
        kind: 'OpenStackCluster',
        name: infrastructureRefName,
      },
    },
  }
}
