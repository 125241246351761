import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import React, { useCallback, useState } from 'react'

import Text from 'core/elements/Text'
import CardButton from 'core/elements/button/CardButton'
import { trackEvent } from 'utils/tracking'
import ClusterDeploymentForm from '../cluster/deployment/ClusterDeploymentForm'
import ClustersInfoPage from './ClustersInfoPage'

export default function ClustersLandingPage() {
  const classes = useStyles()
  const handleOpenLinkClick = (url, eventName) =>
    useCallback(() => {
      trackEvent(eventName)
      window.open(url)
    }, [])
  const [showClusterDeploymentModal, setShowClusterDeploymentModal] = useState(false)

  const handleButtonClick = () => {
    setShowClusterDeploymentModal(true) // Or perform any action to render the component
  }
  return (
    <ClustersInfoPage
      className={classes.argoPage}
      title="Get started with Kubernetes!"
      footerTitle="more useful links"
      icon={'/ui/images/kubernetes.png'}
      actions={[
        <>
          <CardButton
            onClick={handleButtonClick}
            key="kubernetes-create-cluster"
            icon="plus"
            title="Create New Cluster"
            message="Start with creating your first cluster."
          />
          <CardButton
            //TODO:: once get doc link need to add the same
            // onClick={handleOpenLinkClick(kubernetesReleaseDocs, 'Clicked kubernetes Help')}
            key="kubernetes-need-help"
            icon="check-double"
            title="Documentation"
            message="Find more helpful links here"
          />
        </>,
      ]}
    >
      <div className={classes.paragraphSection}>
        <Text variant="body1">
          This section of the PCD platform lets you run, manage and scale your Kubernetes clusters
          and containerized applications. Let's create your first Kubernetes cluster with PCD
          Virtual Machines.
        </Text>
        <Text variant="body1">
          Pre-requisite: PCD Virtualization configured with hosts and ready to create Virtual
          Machines.
        </Text>
      </div>
      {showClusterDeploymentModal && (
        <ClusterDeploymentForm onClose={() => setShowClusterDeploymentModal(false)} />
      )}
    </ClustersInfoPage>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  argoPage: {
    maxWidth: 660,
    gridTemplateAreas: `
      "product-info-figure product-info-title"
      "product-info-figure product-info-body"
      "product-info-figure product-info-footer"
    `,

    '& > .product-info-body': {
      gap: 32,
    },
  },
  paragraphSection: {
    display: 'grid',
    gap: 16,
    marginTop: '16px',
  },
}))
