import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'

const { kaapi } = ApiClient.getInstance()

export const kaapiNodesActions = ActionsSet.make<DataKeys.KaapiNodes>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiNodes],
  cacheKey: DataKeys.KaapiNodes,
})

export const deleteKaapiNodes = kaapiNodesActions.add(
  new DeleteAction<DataKeys.KaapiNodes, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Kaapi Nodes', { namespace, name })
      await kaapi.deleteKaapiMachines({ namespace, name })
    },
  ),
)

export const listKaapiNodes = kaapiNodesActions.add(
  new ListAction<DataKeys.KaapiNodes>(async ({ endpoint }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Nodes ')
    return kaapi.getAllWorkerNodes()
  }).addDependency(DataKeys.KaapiNodes),
)
