import React, { useMemo } from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'

export default function KaapiClusterVersionField({
  wizardContext,
  setWizardContext,
  required = true,
  id = 'kubeRoleVersion',
  value = undefined,
  showAwsEksVersions = false,
  selectFirst = true,
  k8sVersions,
  customizeOptionsFn,
  ...props
}) {
  const k8sVersionOptions = useMemo(
    () => k8sVersions.map((version) => ({ label: version, value: version })),
    [k8sVersions],
  )

  const options = useMemo(
    () => (customizeOptionsFn ? customizeOptionsFn(k8sVersionOptions) : k8sVersionOptions),
    [k8sVersionOptions, customizeOptionsFn],
  )

  return (
    <>
      {/*
      // @ts-ignore */}
      <PicklistField
        DropdownComponent={AsyncDropdown}
        id={id}
        items={options}
        label="Select K8s upgrade version"
        onChange={(value) => setWizardContext({ [id]: value })}
        value={value || wizardContext[id]}
        required={required}
        selectFirst={selectFirst}
        {...props}
      />
    </>
  )
}
