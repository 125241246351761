import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { getDeleteConfirmText } from 'core/components/CRUDListContainer'
import React, { useState, useMemo } from 'react'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteKaapiMachineDeployments } from 'k8s/components/kaapi/machine-deployment/actions'
import { deleteKaapiOpenstackMachineTemplates } from '../kaapi/openstack-machine-templates/actions'
import { deleteKaapiKudeadmConfigTemplates } from '../kaapi/kubeadm-config-templates/actions'
import { LAST_RUNNING_WORKER_NODE_DISACLAIMER } from './constant'
import { getDeleteText } from './DeleteWorkerNodesDialog'

const DeleteNodeGroupsDialog = ({ rows, onClose, isLastRunningNode, reloadMachineDeployments }) => {
  const [error, setError] = useState(null)

  const {
    update: deleteMachineDeploymentFn,
    updating: deletingMachineDeployment,
  } = useUpdateAction(deleteKaapiMachineDeployments)
  const {
    update: deleteKaapiOpenstackMachineTemplatesFn,
    updating: deletingKaapiOpenstackMachineTemplates,
  } = useUpdateAction(deleteKaapiOpenstackMachineTemplates)
  const {
    update: deleteKaapiKudeadmConfigTemplatesFn,
    updating: deletingKaapiKudeadmConfigTemplates,
  } = useUpdateAction(deleteKaapiKudeadmConfigTemplates)

  const deleteConfirmText = useMemo(() => {
    const deleteConfirmText = getDeleteConfirmText(rows.flatMap((nodeGroup) => nodeGroup.metadata))
    return getDeleteText(deleteConfirmText, isLastRunningNode)
  }, [rows, isLastRunningNode, LAST_RUNNING_WORKER_NODE_DISACLAIMER])

  const handleSubmit = async () => {
    const promises = []
    for (const nodeGroup of rows) {
      if (!nodeGroup?.kind) return
      // Delete the machine deployment, openstack machine templates, and kubeadm config templates
      promises.push(
        deleteMachineDeploymentFn({
          name: nodeGroup.metadata.name,
          namespace: nodeGroup.metadata.namespace,
        }),
      )
      promises.push(
        deleteKaapiOpenstackMachineTemplatesFn({
          name: nodeGroup.metadata.name,
          namespace: nodeGroup.metadata.namespace,
        }),
      )
      promises.push(
        deleteKaapiKudeadmConfigTemplatesFn({
          name: nodeGroup.metadata.name,
          namespace: nodeGroup.metadata.namespace,
        }),
      )
    }

    // Wait for all promises to resolve
    Promise.all(promises)
      .then(() => {
        reloadMachineDeployments(true)
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({ title: 'Error Deleting Node Group', message: err?.message })
      })
  }

  return (
    <ConfirmationDialog
      text={deleteConfirmText}
      onConfirm={handleSubmit}
      onCancel={onClose}
      error={error}
      loading={
        deletingMachineDeployment ||
        deletingKaapiOpenstackMachineTemplates ||
        deletingKaapiKudeadmConfigTemplates
      }
      open
    />
  )
}

export default DeleteNodeGroupsDialog
