import React, { useCallback, useMemo } from 'react'
import Text from 'core/elements/Text'
import { rebuildVirtualMachine } from './actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ModalForm from 'core/elements/modal/ModalForm'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'

// Todo: Check into how to rebuild vms that used bootable volume
export default function RebuildVirtualMachineDialog({ rows: vms, onClose }) {
  const vm = vms?.[0]
  const classes = useStyles()

  const ipAddresses = useMemo(() => {
    const networkKeys = Object.keys(vm?.addresses)
    const ips = networkKeys.map((key) => vm?.addresses?.[key].map((iface) => iface.addr)).flat()
    return ips
  }, [vm])

  const { update: rebuildVm, updating, error } = useUpdateAction(rebuildVirtualMachine)

  const submitForm = useCallback(async () => {
    const body = {
      rebuild: {
        imageRef: vm?.imageDetails?.id,
      },
    }
    const { success } = await rebuildVm({
      id: vm.id,
      body,
    })
    if (success) onClose(true)
  }, [vm])

  return (
    <ModalForm
      open
      panel="dialog"
      title={`Rebuild VM`}
      onSubmit={submitForm}
      onClose={onClose}
      submitting={updating}
      error={error}
      submitTitle={`Rebuild VM`}
      maxWidth={800}
    >
      <div className={classes.body}>
        <Text variant="body2">
          You are about to rebuild the VM instance <b>{vm?.name || vm?.id}</b>
        </Text>
        <Text variant="body2">
          Rebuilding an instance will redeploy the selected instance based on the instance's image
          and existing flavor, network, and storage configuration. This operation will recreate the
          root disk of the instance. For a volume-backed instance, this operation will keep the
          contents of the volume.
        </Text>
        <Text variant="body2">
          <b>IMPORTANT:</b> This will delete all the data on the instance and replace it with the
          specified image. Only the instance ID, flavor and IP address (fixed and floating) will
          remain the same.
        </Text>
        <div className={classes.properties}>
          <Text variant="caption1">Image:</Text>
          <Text variant="body2">{vm?.imageDetails?.name || vm?.imageDetails?.id || 'unknown'}</Text>
          <Text variant="caption1">Flavor:</Text>
          <Text variant="body2">
            {vm?.flavorName || 'unknown'}{' '}
            {vm?.flavorDetails
              ? `(${vm?.flavorDetails?.vcpus} vCPUs, ${vm?.flavorDetails?.ram} RAM (MB), ${vm?.flavorDetails?.disk} Disk (GB))`
              : ''}
          </Text>
          <Text variant="caption1">IP Addresses:</Text>
          <Text variant="body2">{ipAddresses.join(', ')}</Text>
        </div>
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  body: {
    display: 'grid',
    gap: 12,
  },
  properties: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: '8px 16px',
  },
}))
