import YamlTemplateParser from 'utils/YamlTemplateParser'
import clusterUpgradeJobSchema from './cluster-upgrade-job.json'
import { pick } from 'ramda'

const createUpgradeJobName = (clusterName, targetVersion, upgradeJobs) => {
  const attemptNum = upgradeJobs.reduce((accum, job) => {
    if (job.targetVersion === targetVersion) {
      accum += 1
    }
    return accum
  }, 0)
  return `${clusterName}-${targetVersion}-${attemptNum}`
}

const formatNodeGroups = (nodeGroups = []) => {
  return nodeGroups.map((group) => {
    return pick(['apiVersion', 'kind', 'name'], group)
  })
}

const formatAddons = (addons = [], eksAddons = []) => {
  const formattedAddons = addons.map((addon) => {
    return {
      name: addon.type,
      kind: 'ClusterAddon',
      apiVersion: 'sunpike.platform9.com/v1alpha2',
    }
  })
  const formattedEksAddons = eksAddons.map((addon) => {
    return {
      name: addon.name,
      kind: 'EKSClusterAddon',
      apiVersion: 'controlplane.cluster.x-k8s.io/v1beta1',
    }
  })
  return [...formattedAddons, ...formattedEksAddons]
}

export const createClusterUpgradeJobBody = (params) => {
  const { clusterName, addons, eksAddons, nodeGroups, upgradeJobs, targetVersion } = params
  const jobName = createUpgradeJobName(clusterName, targetVersion, upgradeJobs)
  const templateParser = new YamlTemplateParser(clusterUpgradeJobSchema)
  templateParser.setValues({
    ...params,
    name: jobName,
    nodeGroups: formatNodeGroups(nodeGroups),
    // addons: formatAddons(addons, eksAddons),
  })
  return templateParser.getJson()
}
