import { useLocation } from 'react-router-dom'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import useListAction from 'core/hooks/useListAction'
import { Route } from 'core/plugins/route'
import useScopedPreferences from 'core/session/useScopedPreferences'
import AsyncDropdown from 'pf9-ui-components/built/elements/dropdown/AsyncDropdown'
import { head, isEmpty, prop } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { listKaapiClusters } from './kaapi/clusters/actions'
import { useSelector } from 'react-redux'
import { kaapiClustersSelector } from './kaapi/clusters/selectors'
import { getClusterEndpoint } from './dashboard/helpers'

interface Option {
  label: string
  value: string
}

const isValidOption = (selectedCluster, options) =>
  !!selectedCluster && !!options.find((c) => c.value === selectedCluster)

export const getClusterNameFromUrl = () => {
  const matches = location.pathname.match(/\/clusters\/([^/]+)/)
  return matches?.[1]
}

export default function GlobalClusterDropdown({ className }) {
  const classes = useStyles()
  const { prefs, updatePrefs } = useScopedPreferences('k8sPluginGlobalParams')
  const { history } = useReactRouter() // `location` is now replaced by `useLocation`
  const location = useLocation() // New hook to track location changes

  const { loading: loadingKaapiClusters } = useListAction(listKaapiClusters)
  const clusterList = useSelector(kaapiClustersSelector)

  // Convert the list of clusters to a list of options for the dropdown
  const options = useMemo(
    () =>
      clusterList.map((cluster) => ({
        label: cluster.metadata.name,
        value: cluster.metadata.name,
      })),
    [clusterList],
  )

  // When the cluster is specified in the URL, we should update the global cluster name
  useEffect(() => {
    const route = Route.getCurrentRoute(location.pathname)
    if (!route?.url || !route.url.includes(':cluster') || !clusterList.length) return

    const cluster = getClusterNameFromUrl()
    const matchingCluster = clusterList.find((c) => c.metadata.name === cluster)

    if (matchingCluster) {
      updatePrefs({
        cluster,
        cluster_endpoint: getClusterEndpoint(matchingCluster?.spec?.controlPlaneEndpoint?.host),
      })
    } else {
      updatePrefs({
        cluster: '',
        cluster_endpoint: '',
      })
    }
  }, [location.pathname, clusterList])

  // If a cluster is not already specified in the URL, choose the first cluster in the list
  useEffect(() => {
    if (isEmpty(options) || isValidOption(prefs.cluster, options) || !clusterList.length) return

    const firstCluster = prop<'value', Option>('value', head(options))
    const matchingCluster = clusterList.find((c) => c.metadata.name === firstCluster)

    if (matchingCluster) {
      updatePrefs({
        cluster: firstCluster,
        cluster_endpoint: getClusterEndpoint(matchingCluster?.spec?.controlPlaneEndpoint?.host),
      })
      updateUrl(firstCluster)
    }
  }, [prefs.cluster, options, clusterList])

  // Update the cluster in the URL whenever the global cluster  name has changed
  useEffect(() => {
    if (!prefs.cluster || !clusterList.length) return

    const matchingCluster = clusterList.find((c) => c.metadata.name === prefs.cluster)
    if (matchingCluster) {
      updatePrefs({
        cluster_endpoint: getClusterEndpoint(matchingCluster?.spec?.controlPlaneEndpoint?.host),
      })
      updateUrl(prefs.cluster)
    }
  }, [prefs.cluster, clusterList])

  const updateUrl = (cluster) => {
    const route = Route.getCurrentRoute(location.pathname)
    if (!route?.url || !route.url.includes(':cluster')) return
    history.push(route.path({ cluster }))
  }

  return (
    <AsyncDropdown
      className={clsx(classes.dropdown, className)}
      items={options}
      value={prefs.cluster}
      onChange={(cluster) => updatePrefs({ cluster })}
      compact={false}
      icon="server"
      disabled={clusterList.length === 0}
    />
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  dropdown: {},
}))
