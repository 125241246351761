import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'

import PercentUsageWidget from 'core/components/widgets/PercentUsageWidget'
import Theme from 'core/themes/model'

interface Props {
  percent?: number
}
type UsageWidgetProgressProps = {
  title: string
  units: string
  current: number
  total: number
  primary?: string
  percent?: number
  usedText?: string
}
const UsageWidgetProgress = ({
  title,
  units,
  current,
  total,
  primary,
  percent = 0,
  usedText,
}: UsageWidgetProgressProps) => {
  const classes = useStyles({ percent })
  const usageStats = useMemo(() => {
    return {
      current: current || 0,
      max: Math.max(total - current, 0) || 0,
      percent: (current / total) * 100 || 0,
    }
  }, [current, total])
  return (
    <div className={classes.percentUsageWidgetContainer}>
      <PercentUsageWidget
        title={title}
        stats={usageStats}
        units={units}
        primary={primary}
        usedText={usedText}
      />
    </div>
  )
}
export default UsageWidgetProgress

const useStyles = makeStyles<Theme, Props>((theme) => ({
  percentUsageWidgetContainer: (props) => ({
    '& > div > section > div > svg ': {
      border: 'none',
      height: 140,
      '&:last-child': {
        paddingBottom: '20px',
        justifyItems: 'center',
        h6: {
          paddingLeft: 0,
        },
      },
    },
  }),
}))
