import React from 'react'
import { makeStyles } from '@material-ui/styles'

import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
const StatusCard = ({
  show = true,
  title,
  children,
  count = null,
  onClick = null,
  hideBtn = false,
}) => {
  const classes = useStyles({})

  if (!show) return null

  return (
    <div className={classes.card}>
      <div className={classes.cardHeader}>
        <Text variant="subtitle2">
          {title} {count !== null ? `(${count})` : null}
        </Text>
        {onClick && !hideBtn && (
          <div className={classes.cardHeaderBtn} onClick={onClick}>
            <Text variant="caption1">See All</Text>
            <FontAwesomeIcon regular size="md" className={classes.icon}>
              chevron-right
            </FontAwesomeIcon>
          </div>
        )}
      </div>
      <div className={classes.cardBody}>{children}</div>
    </div>
  )
}

export default StatusCard

const useStyles = makeStyles((theme: Theme) => ({
  cardsRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  card: {
    backgroundColor: theme.components.card.background,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: '16px 24px',
    //  '&:nth-child(3n+1)': {
    //    // First card in each row
    //    borderRadius: '4px 0px 0px 4px',
    //  },
    //  '&:nth-child(3n)': {
    //    // Last card in each row
    //    borderRadius: '0px 4px 4px 0px',
    //  },
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  cardHeaderBtn: {
    cursor: 'pointer',
  },
  cardBody: {
    display: 'flex',
  },
  icon: {
    paddingLeft: '4px',
    fontSize: '12px',
  },
}))
