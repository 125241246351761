import ApiService from 'api-client/ApiService'
import { NetworksResponse, NetworkDetailsResponse } from 'openstack/components/networks/model'

class Neutron extends ApiService {
  public getClassName() {
    return 'neutron'
  }

  protected async getEndpoint() {
    return this.client.keystone.getServiceEndpoint('neutron', 'public')
  }

  networkUrl = () => `/v2.0/networks`

  async setRegionUrls() {
    const services = (await this.client.keystone.getServiceCatalog()).find(
      (x) => x.name === 'neutron',
    ).endpoints
    return services.reduce((accum, service) => {
      accum[service.region] = `${service.url}/v2.0`
      return accum
    }, {})
  }

  async getApis() {
    const response = await this.client.basicGet<any>({
      url: '/',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackNetworkApis',
      },
    })
    return response
  }

  async getNetwork(id) {
    const response = await this.client.basicGet<NetworkDetailsResponse>({
      url: `/v2.0/networks/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackNetwork',
      },
    })
    return response.network
  }

  async getNetworks() {
    const response = await this.client.basicGet<NetworksResponse>({
      url: `/v2.0/networks`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackNetworks',
      },
    })
    return response.networks
  }

  async createNetwork(body) {
    const response = await this.client.basicPost<NetworkDetailsResponse>({
      url: `/v2.0/networks`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createOpenstackNetwork',
      },
    })
    return response.network
  }

  async deleteNetwork(id) {
    return this.client.basicDelete<any>({
      url: `/v2.0/networks/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteOpenstackNetwork',
      },
    })
  }

  async updateNetwork({ id, body }) {
    const url = `/v2.0/networks/${id}`
    const response = await this.client.basicPut<NetworkDetailsResponse>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateOpenstackNetwork',
      },
    })
    return response.network
  }

  async getSubnet(id) {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/subnets/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackSubnet',
      },
    })
    return response.subnet
  }

  async getSubnets() {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/subnets`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackSubnets',
      },
    })
    return response.subnets
  }

  async createSubnet(body) {
    const response = await this.client.basicPost<any>({
      url: `/v2.0/subnets`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createOpenstackSubnet',
      },
    })
    return response.subnet
  }

  async updateSubnet({ id, body }) {
    const url = `/v2.0/subnets/${id}`
    const response = await this.client.basicPut<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateOpenstackSubnet',
      },
    })
    return response.subnet
  }

  async deleteSubnet(id) {
    return this.client.basicDelete<any>({
      url: `/v2.0/subnets/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteOpenstackSubnet',
      },
    })
  }

  async getPorts() {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/ports`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackPorts',
      },
    })
    return response.ports
  }

  async getPort(id) {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/ports/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackPort',
      },
    })
    return response.port
  }

  async createPort(body) {
    const url = `/v2.0/ports`
    const response = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPort',
      },
    })
    return response.port
  }

  async deletePort(id) {
    const url = `/v2.0/ports/${id}`
    await this.client.basicDelete<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePort',
      },
    })
  }

  async updatePort({ id, body }) {
    const url = `/v2.0/ports/${id}`
    const response = await this.client.basicPut<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePort',
      },
    })
    return response.port
  }

  async getFloatingIps() {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/floatingips`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getFloatingIps',
      },
    })
    return response.floatingips
  }

  async getFloatingIp(id) {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/floatingips/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getFloatingIp',
      },
    })
    return response.floatingip
  }

  async createFloatingIp(body) {
    const response = await this.client.basicPost<any>({
      url: `/v2.0/floatingips`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createFloatingIp',
      },
    })
    return response.floatingip
  }

  async updateFloatingIp({ id, body }) {
    const response = await this.client.basicPut<any>({
      url: `/v2.0/floatingips/${id}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateFloatingIp',
      },
    })
    return response.floatingip
  }

  async detachFloatingIp(id) {
    const response = await this.client.basicPut<any>({
      url: `/v2.0/floatingips/${id}`,
      body: {
        floatingip: { port_id: null },
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'detachFloatingIp',
      },
    })
    return response.floatingip
  }

  async deleteFloatingIp(id) {
    return this.client.basicDelete<any>({
      url: `/v2.0/floatingips/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteFloatingIp',
      },
    })
  }

  async getNetworkIpAvailability() {
    const url = `/v2.0/network-ip-availabilities`
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'networkIpAvailabilities',
      },
    })
    return response.network_ip_availabilities
  }

  async getSecurityGroups() {
    const currentTenant = this.client.activeProjectId
    const response = await this.client.basicGet<any>({
      url: `/v2.0/security-groups?project_id=${currentTenant}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSecurityGroups',
      },
    })
    return response.security_groups
  }

  async getSecurityGroup(id) {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/security-groups/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSecurityGroup',
      },
    })
    return response.security_group
  }

  async createSecurityGroup(body) {
    const response = await this.client.basicPost<any>({
      url: `/v2.0/security-groups`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createSecurityGroup',
      },
    })
    return response.security_group
  }

  async updateSecurityGroup({ id, body }) {
    const response = await this.client.basicPut<any>({
      url: `/v2.0/security-groups/${id}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateSecurityGroup',
      },
    })
    return response.security_group
  }

  async deleteSecurityGroup(id) {
    await this.client.basicDelete<any>({
      url: `/v2.0/security-groups/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteSecurityGroup',
      },
    })
  }

  async getSecurityGroupRules() {
    const url = `/v2.0/security-group-rules`
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSecurityGroupRules',
      },
    })
    return response.security_group_rules
  }

  async createSecurityGroupRule(params) {
    const url = `/v2.0/security-group-rules`
    const response = await this.client.basicPost<any>({
      url,
      body: {
        security_group_rule: params,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'createSecurityGroupRule',
      },
    })
    return response.security_group_rule
  }

  async createSecurityGroupRules(body) {
    const response = await this.client.basicPost<any>({
      url: `/v2.0/security-group-rules`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createSecurityGroupRules',
      },
    })
    return response.security_group_rules
  }

  async deleteSecurityGroupRule(id) {
    await this.client.basicDelete<any>({
      url: `/v2.0/security-group-rules/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteSecurityGroupRule',
      },
    })
  }

  async getRouter(id) {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/routers/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackRouter',
      },
    })
    return response.router
  }

  async getRouters() {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/routers`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getOpenstackRouters',
      },
    })
    return response.routers
  }

  async createRouter(body) {
    const response = await this.client.basicPost<any>({
      url: `/v2.0/routers`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createOpenstackRouter',
      },
    })
    return response.router
  }

  async updateRouter({ id, body }) {
    const url = `/v2.0/routers/${id}`
    const response = await this.client.basicPut<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateOpenstackRouter',
      },
    })
    return response.router
  }

  async deleteRouter(id) {
    return this.client.basicDelete<any>({
      url: `/v2.0/routers/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteOpenstackRouter',
      },
    })
  }

  async addInterface({ id, body }) {
    const url = `/v2.0/routers/${id}/add_router_interface`
    const response = await this.client.basicPut<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'addInterface',
      },
    })
    return response.data
  }

  async removeInterface({ id, body }) {
    const url = `/v2.0/routers/${id}/remove_router_interface`
    const response = await this.client.basicPut<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'removeInterface',
      },
    })
    return response.data
  }

  async getAllQuotas() {
    const url = `/v2.0/quotas`
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAllQuotas',
      },
    })
    return response.quotas
  }

  async getNetworkQuotas({ id }) {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/quotas/${id}`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNetworkQuotas',
      },
    })
    return response.quota
  }

  async getNetworkQuotaUsage({ id }) {
    const response = await this.client.basicGet<any>({
      url: `/v2.0/quotas/${id}/details.json`,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNetworkQuotaUsage',
      },
    })
    return response.quota
  }

  async updateNetworkQuotas({ id, body }) {
    const response = await this.client.basicPut<any>({
      url: `/v2.0/quotas/${id}`,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateNetworkQuotas',
      },
    })
    return response.quota
  }

  async getProjectQuotaForRegion(id, region) {
    const endpoint = `${(await this.setRegionUrls())[region]}`
    const url = `/quotas/${id}`
    const response = await this.client.basicGet<any>({
      endpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getProjectQuotaForRegion',
      },
    })
    return response.quota
  }

  async getDefaultQuotasForRegion(region) {
    const projectId = (await this.client.keystone.getProjects())[0]
    const endpoint = `${(await this.setRegionUrls())[region]}`
    const url = `/quotas/${projectId}`
    const response = await this.client.basicGet<any>({
      endpoint,
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getDefaultQuotasForRegion',
      },
    })
    return response.quota
  }

  async setQuotas(projectId, params) {
    const url = `/v2.0/quotas/${projectId}`
    const response = await this.client.basicPut<any>({
      url,
      body: {
        quota: params,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'setQuotas',
      },
    })
    return response.quota
  }

  async setQuotasForRegion(projectId, region, params) {
    const endpoint = `${(await this.setRegionUrls())[region]}`
    const url = `/quotas/${projectId}`
    const response = await this.client.basicPut<any>({
      endpoint,
      url,
      body: {
        quota: params,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'setQuotasForRegion',
      },
    })
    return response.quota
  }
}

export default Neutron
