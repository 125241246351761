import React, { useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { listFlavors, deleteFlavor } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { flavorsSelector } from './selectors'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { routes } from 'core/utils/routes'
import CreateFlavorModal from './CreateFlavorModal'
import { humanReadableSize } from 'openstack/helpers'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import EditFlavorMetadataModal from './EditFlavorMetadataModal'
import EditFlavorTenantsModal from './EditFlavorTenantsModal'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import DeleteFlavor from './DeleteFlavor'

type ModelDataKey = DataKeys.Flavors
type SelectorModel = ArrayElement<ReturnType<typeof flavorsSelector>>
type ActionParams = InferActionParams<typeof listFlavors>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Flavors', listTablePrefs)

const searchTargets = ['name', 'id']

const flavorColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'vcpus',
    label: 'vCPUs',
  },
  {
    key: 'ram',
    label: 'RAM',
    render: (ram) => humanReadableSize(ram * 1024 * 1024),
  },
  {
    key: 'disk',
    label: 'Disk',
    render: (disk) => humanReadableSize(disk * 1024 * 1024 * 1024),
  },
  {
    key: 'extra_specs',
    label: 'Metadata',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'os-flavor-access:is_public',
    label: 'Visibility',
    render: (val) => (val ? 'Public' : 'Private'),
  },
]

export default function FlavorsListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const [selectedFlavor, setSelectedFlavor] = useState<SelectorModel>(null)
  const [showEditFlavorMetadataModal, setShowEditFlavorMetadataModal] = useState<boolean>(false)
  const [showEditFlavorTenantsModal, setShowEditFlavorTenantsModal] = useState<boolean>(false)
  const isAdminUser = isAdmin()

  const { message, loading, reload } = useListAction(listFlavors, {
    params,
  })
  const data = useAppSelector(flavorsSelector)

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      label: 'Edit Metadata',
      icon: 'edit',
      handleAction: ([flavor]) => {
        setSelectedFlavor(flavor)
        setShowEditFlavorMetadataModal(true)
      },
      refreshAfterSuccess: true,
    },
    {
      cond: ([flavor]) => {
        return !flavor?.['os-flavor-access:is_public']
      },
      disabledTooltip: 'Can only edit tenants for private flavors',
      label: 'Edit Tenants',
      icon: 'users',
      handleAction: ([flavor]) => {
        setSelectedFlavor(flavor)
        setShowEditFlavorTenantsModal(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Flavors" />
      <CreateFlavorModal addRoute={routes.openstack.createFlavor} />
      {showEditFlavorMetadataModal && (
        <EditFlavorMetadataModal
          onClose={() => setShowEditFlavorMetadataModal(false)}
          rows={[selectedFlavor]}
        />
      )}
      {showEditFlavorTenantsModal && (
        <EditFlavorTenantsModal
          onClose={() => setShowEditFlavorTenantsModal(false)}
          rows={[selectedFlavor]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Flavors}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={flavorColumns}
        addUrl={routes.openstack.createFlavor.path()}
        addText="Create Flavor"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteFlavor}
        DeleteDialogComponent={DeleteFlavor}
        batchActions={batchActions}
        isReadOnly={!isAdminUser}
        hideCheckboxes
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
