import React, { useCallback } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listPorts } from '../actions'
import { createRouterInterface } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import { Route } from 'core/plugins/route'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import { IRouterDetailsPageTabs } from './model'
import Text from 'core/elements/Text'
import SubnetPickListForRouterInterface from './SubnetPickListForRouterInterface'
import PicklistField from 'core/components/validatedForm/DropdownField'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  addRoute: Route
  router: any
}

export default function CreateRouterInterfaceModal({ addRoute, router }: Props) {
  const classes = useStyles()
  const { history, match } = useReactRouter()

  const defaultParams = {
    subnet: null,
  }
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const { reload } = useListAction(listPorts, {})
  const { update, updating, error, reset } = useUpdateAction(createRouterInterface)

  const createInterfaceFn = async (params, routerId) => {
    const body = {
      subnet_id: params?.subnet?.id,
    }
    const { success } = await update({ id: routerId, body })
    return { success }
  }

  const submitForm = useCallback(async () => {
    const { success } = await createInterfaceFn(params, router.id)
    if (success) {
      reload(true, true)
      handleClose()
    }
  }, [params, router])

  const handleClose = useCallback(() => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.openstack.routerDetails.path({
        id: router.id,
        tab: IRouterDetailsPageTabs.Interfaces,
      }),
    )
  }, [router])

  return (
    <ModalForm
      route={addRoute}
      title={`Create Interface`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Create Interface`}
      panel="dialog"
    >
      <PicklistField
        id="subnet"
        DropdownComponent={SubnetPickListForRouterInterface}
        value={params.subnet}
        onChange={getParamsUpdater('subnet')}
        tooltip="Select the subnet to create the router interface on"
        required
      />
    </ModalForm>
  )
}
