import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import AddNodeGroups from 'k8s/components/capacity-and-health/AddNodeGroups'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React, { useEffect, useMemo, useState } from 'react'

interface NodePoolConfigCardProps {
  nodePool: any
  vmFlavors: any[]
  storagePools: any[]
  networks: any[]
  onChange: (value: Record<string, any>) => void
  onDeleteNodePool: (id: number) => void
  showDeleteButton?: boolean
  errors?: Errors
}

interface Errors {
  [fieldId: string]: string
}

export default function NodePoolConfigCard({
  nodePool,
  onChange,
  vmFlavors,
  storagePools,
  networks,
  showDeleteButton = false,
  onDeleteNodePool,
  errors = undefined,
}: NodePoolConfigCardProps) {
  const [collapseCard, setCollapseCard] = useState(false)
  const classes = useStyles({ collapseCard, showDeleteButton })

  return (
    <div className={classes.nodePoolContainer}>
      <div className={classes.header} onClick={() => setCollapseCard(!collapseCard)}>
        <Text variant="subtitle2">{`#${nodePool?.id} Node Pool`}</Text>
        <FontAwesomeIcon solid size="xs" className={classes.toggleIcon}>
          chevron-up
        </FontAwesomeIcon>
      </div>
      {!collapseCard && (
        <div className={classes.content}>
          <AddNodeGroups
            nodePool={nodePool}
            onChange={onChange}
            vmFlavors={vmFlavors}
            storagePools={storagePools}
            networks={networks}
            showDeleteButton={showDeleteButton}
            onDeleteNodePool={onDeleteNodePool}
            errors={errors}
          />
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles<Theme, { collapseCard: boolean; showDeleteButton: boolean }>(
  (theme) => ({
    nodePoolContainer: {
      display: 'grid',
      border: `1px solid ${theme.components.card.border}`,
      borderRadius: '4px',
      marginBottom: theme.spacing(2),
      '& .accordionTopBar': {
        height: '48px',
      },
    },
    header: {
      backgroundColor: ({ collapseCard }) =>
        collapseCard
          ? `${theme.components.accordion.background}`
          : `${theme.components.accordion.activeBackground}`,
      borderTop: `1px solid  ${theme.components.accordion.border}`,
      color: `${theme?.components?.typography?.default}`,
      cursor: 'pointer',
      padding: '0 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: 'none',
      transition: 'background-color 0.6s ease',
      height: '48px',
    },
    toggleIcon: {
      transform: ({ collapseCard }) => (collapseCard ? 'rotate(180deg)' : 'rotate(0deg)'),
      transition: 'transform 0.2s ease',
    },
    content: {
      margin: theme.spacing(4, 3),
    },
  }),
)
