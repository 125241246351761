import { makeStyles } from '@material-ui/core/styles'
import Text from 'core/elements/Text'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
  valueText: {
    marginRight: theme.spacing(2),
  },
}))

const DetailRow = ({ label, value }) => {
  const classes = useStyles()

  return (
    <div className={classes.row}>
      <Text className={classes.labelText} variant="body2">
        {label}
        {label && ':'}
      </Text>
      <Text className={classes.valueText} variant="caption1">
        {value}
      </Text>
    </div>
  )
}

const StatusDetails = ({ statusDetails }) => {
  return (
    <>
      {statusDetails?.subtitles?.map((subtitle) => (
        <DetailRow key={subtitle.label} label={subtitle.label} value={subtitle.value} />
      ))}
    </>
  )
}

export default StatusDetails
