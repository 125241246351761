import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { getDeleteConfirmText } from 'core/components/CRUDListContainer'
import React, { useState, useMemo } from 'react'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteKaapiMachines } from 'k8s/components/kaapi/machines/actions'
import { LAST_RUNNING_WORKER_NODE_DISACLAIMER } from './constant'

// Helper function to get the delete text
export const getDeleteText = (
  deleteConfirmText: JSX.Element | string,
  isLastRunningNode: boolean,
): JSX.Element | string => {
  if (isLastRunningNode) {
    return (
      <>
        <strong>{LAST_RUNNING_WORKER_NODE_DISACLAIMER}</strong>
        <br />
        <br />
        {deleteConfirmText}
      </>
    )
  }
  return <>{deleteConfirmText}</>
}

const DeleteWorkerNodesDialog = ({ rows, onClose, isLastRunningNode, reloadKaapiMachines }) => {
  const [error, setError] = useState(null)
  const { update: deletingMachinesFn, updating: deletingMachines } = useUpdateAction(
    deleteKaapiMachines,
  )
  const deleteConfirmText = useMemo(() => {
    const deleteConfirmText = getDeleteConfirmText(rows.flatMap((node) => node.metadata))
    return getDeleteText(deleteConfirmText, isLastRunningNode)
  }, [rows, isLastRunningNode, LAST_RUNNING_WORKER_NODE_DISACLAIMER])

  const handleSubmit = async () => {
    const promises = []
    for (const node of rows) {
      if (!node?.kind) return
      promises.push(
        deletingMachinesFn({
          name: node.metadata.name,
          namespace: node.metadata.namespace,
        }),
      )
    }

    Promise.all(promises)
      .then(() => {
        reloadKaapiMachines(true)
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        setError({ title: 'Error Deleting Node Group', message: err?.message })
      })
  }

  return (
    <ConfirmationDialog
      text={deleteConfirmText}
      onConfirm={handleSubmit}
      onCancel={onClose}
      error={error}
      loading={deletingMachines}
      open
    />
  )
}

export default DeleteWorkerNodesDialog
