import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { hostedControlPlaneSelector } from '../hosted-control-plane/selectors'
import { openStackClustersSelector } from '../openstack-clusters/selectors'
import { kaapiMachineDeploymentsSelector } from '../machine-deployment/selectors'
import { kaapiMachinesSelector } from '../machines/selectors'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'
import { compareVersions } from 'k8s/util/helpers'
import { descend, head, prop, sortWith } from 'ramda'
import { IClusterUpgradeJobSelector } from 'app/plugins/infrastructure/components/clusters/capi/upgrade/model'
import { isNilOrEmpty } from 'utils/fp'
import { kaapiClusterUpgradeJobsByClusterSelector } from '../cluster-upgrade/selectors'

export const kaapiClustersSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiClusters>(DataKeys.KaapiClusters),
  (kaapiClusters): any[] => {
    return kaapiClusters.map((cluster) => {
      return cluster
    })
  },
)

//TODO:: Once upgradejob api impleneted need to refactor following logic
// export const clusterUpgradeJobsByClusterSelector = createSharedSelector(
// 	clusterUpgradeJobsSelector,
// 	(upgradeJobs) =>
// 		upgradeJobs.reduce((accum, job) => {
// 			const clusterName = job?.spec?.clusterName || ''
// 			const existingUpgradeJobs = accum[clusterName] || []
// 			// Sort by job creation timestamp in descending order
// 			const allUpgradeJobs = [...existingUpgradeJobs, job]
// 				.sort(compareByCreationTimestamp)
// 				.reverse()
// 			return assocPath([clusterName], allUpgradeJobs, accum)
// 		}, emptyObj),
// )

// export const eksVersionsSelector = createSharedSelector(
//   getDataSelector<DataKeys.EksVersions>(DataKeys.EksVersions),
//   (versions: EKSClusterVersion[]): IEksVersionsSelector[] => {
//     return versions.map((version) => ({
//       ...version,
//       deprecated: version.spec?.deprecated,
//       name: version.metadata?.name,
//     }))
//   },
// )

export const clusterVersionsSelector = createSharedSelector(
  getDataSelector<DataKeys.ClusterVersions>(DataKeys.ClusterVersions),
  (rawClusterVersions): any[] => {
    return rawClusterVersions.map((rawClusterVersion) => {
      const latest = rawClusterVersion?.metadata?.labels?.['pmk.pf9.io/latest'] === 'true'
      return {
        ...rawClusterVersion,
        latest: latest,
        clusterCreationAllowed:
          rawClusterVersion?.phase === 'EarlyAccess' ||
          (rawClusterVersion?.phase === 'Active' && latest) ||
          (rawClusterVersion?.phase === 'Deprecated' && latest),
        // Cluster can be upgraded to this version from a lower version or patch
        clusterUpgradePossible:
          rawClusterVersion?.phase === 'EarlyAccess' ||
          rawClusterVersion?.phase === 'Active' ||
          (rawClusterVersion?.phase === 'Deprecated' && latest),
      }
    })
  },
)

const getLatestClusterVersion = (versions, versionPropKey): string => {
  if (isNilOrEmpty(versions)) return null
  const sortedVersions = sortWith([descend(prop(versionPropKey))])(versions)
  return sortedVersions[0][versionPropKey] as string
}

const filterClusterUpgradePossible = (clusterVersion) => clusterVersion?.clusterUpgradePossible

export const kaapiClustersOverviewSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiClusters>(DataKeys.KaapiClusters),
  hostedControlPlaneSelector,
  openStackClustersSelector,
  kaapiMachineDeploymentsSelector,
  kaapiMachinesSelector,
  kaapiClusterUpgradeJobsByClusterSelector,
  //  clusterUpgradeJobsByClusterSelector,
  clusterVersionsSelector,
  (
    kaapiClusters,
    hostedControlPlanes,
    openStackClusters,
    kaapiMachineDeployments,
    kaapiMachines,
    kaapiClusterUpgradeJobs,
    //  clusterUpgradeJobsByCluster,
    clusterVersions,
  ): any[] => {
    return kaapiClusters.map((cluster) => {
      const name = cluster?.metadata?.name
      // Find the hosted control plane for the cluster
      const hostedControlPlane = hostedControlPlanes?.find(
        (h) => h?.metadata?.name === cluster?.spec?.controlPlaneRef?.name,
      )
      // Find the openstack cluster for the cluster
      const openStackCluster = openStackClusters?.find(
        (h) => h?.metadata?.name === cluster?.spec?.infrastructureRef?.name,
      )
      // Find the machine deployments for the cluster
      const machineDeployments = kaapiMachineDeployments?.filter(
        (md) => md?.spec?.clusterName === cluster?.metadata?.name,
      )

      // Find the machines for the cluster
      const machines = kaapiMachines?.filter(
        (h) => h?.spec?.clusterName === cluster?.metadata?.name,
      )

      // Upgrade
      const infrastrcutreProvider = cluster?.spec?.infrastructureRef?.dummy || 'PCD Virtualization'
      const uid = cluster?.metadata?.uid
      const upgradeJobs = kaapiClusterUpgradeJobs[name] || []
      const clusterName = cluster?.metadata?.name
      const clusterType = ClusterTypes.Capi
      const version = hostedControlPlane?.spec?.version
      // const canUpgrade =
      //   compareVersions(version, version ? latestEksClusterVersion : latestAwsClusterVersion) === -1
      const canUpgrade = true
      //  const upgradeJobs =
      //    clusterUpgradeJobsByCluster[hostedControlPlane?.metadata?.name] || []
      // upgrade jobs are sorted in descending order by creating timestamp
      // in clusterUpgradeJobsByCluster selector. Newest job is at the top
      const recentUpgradeJob =
        upgradeJobs.length > 0 ? head<IClusterUpgradeJobSelector>(upgradeJobs) : null
      const upgradeFailed = recentUpgradeJob?.phase === 'Failed'
      const upgradeCompleted = recentUpgradeJob?.phase === 'Completed'
      const currentUpgradeJob = upgradeFailed || upgradeCompleted ? null : recentUpgradeJob
      const upgrading = !!currentUpgradeJob
      const upgradeSucessCount = 13
      const upgradeFailedCount = 12
      const strategy = machineDeployments[0]?.spec?.strategy?.type
      const maxSurgeValue = machineDeployments[0]?.spec?.strategy?.rollingUpdate?.maxSurge
      const maxSurge = 'Count'
      const maxUnavailableValue =
        machineDeployments[0]?.spec?.strategy?.rollingUpdate?.maxUnavailable
      const maxUnavailable = 'Count'

      return {
        ...cluster,
        uid,
        clusterType,
        version,
        clusterName,
        canUpgrade,
        recentUpgradeJob,
        upgradeFailed,
        upgradeCompleted,
        currentUpgradeJob,
        upgrading,
        upgradeSucessCount,
        upgradeFailedCount,
        strategy,
        maxSurgeValue,
        maxUnavailableValue,
        maxSurge,
        maxUnavailable,
        hostedControlPlane,
        openStackCluster,
        machineDeployments,
        machines,
        infrastrcutreProvider,
        upgradeJobs,
      }
    })
  },
)
