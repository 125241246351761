import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import CardBody from 'core/elements/card/CardBody'
import PercentSemiCircleGraph from '../graphs/PercentSemiCircleGraph'
import colors from 'core/themes/base/colors'

const defaultStats = { current: 0, max: 0, percent: 0 }

interface Props {
  title: string
  units: string
  stats: typeof defaultStats
  precision?: number
  usedText?: string
  primary?: string
}
export const getFillColor = (value: number) => {
  if (value < 40) {
    return colors.teal[200]
  } else if (value < 70) {
    return colors.beige[100]
  } else {
    return colors.palette[700]
  }
}
export const getStrokeColor = (value: number) => {
  if (value < 40) {
    return colors.teal[100]
  } else if (value < 70) {
    return colors.yellow[500]
  } else {
    return colors.pink[800]
  }
}
export default function PercentUsageWidget({
  title,
  precision = 1,
  units = '',
  usedText = 'used',
  stats = defaultStats,
  primary,
  ...rest
}: Props) {
  const classes = useStyles()
  const { current, max, percent } = stats

  const curStr = current.toFixed(precision) + units
  const maxStr = max.toFixed(precision) + units
  const percentage = Math.round(percent)

  const fillColor = getFillColor(percentage)
  const strokeColor = getStrokeColor(percentage)
  const data = useMemo(
    () => [
      {
        value: 100 - percentage,
        name: '',
        color: '#e6e6ea' as const,
      },
      {
        value: percentage,
        name: '',
        color: fillColor,
        strokeColor: strokeColor,
      },
    ],
    [percentage],
  )
  return (
    <div>
      <CardBody className={classes.cardBody}>
        <PercentSemiCircleGraph data={data} percent={percent / 100} primary={primary} {...rest} />
        <div className={classes.rightContent}>
          <div className={classes.cardsRow}>
            <Text variant="subtitle2" className={classes.title}>
              <b>{curStr}</b>
            </Text>{' '}
            <Text variant="body2" className={classes.modifier}>
              {usedText}
            </Text>
          </div>

          <div className={classes.cardsRow}>
            <Text variant="subtitle2" className={classes.title}>
              <b>{maxStr}</b>
            </Text>{' '}
            <Text variant="body2" className={classes.modifier}>
              available
            </Text>
          </div>
        </div>
      </CardBody>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontWeight: 300,
    textAlign: 'center',
    color: theme.components.card.text,
  },
  modifier: {
    fontWeight: 300,
    textAlign: 'left',
    color: theme.components.card.text,
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(0.5),
    paddingTop: 4,
  },
  card: {
    display: 'grid',
    gridTemplateRows: 'max-content max-content',
  },
  cardTitle: {
    padding: '24px 24px 0px 24px',
  },
  cardBody: {
    display: 'grid',
    justifyItems: 'center',
    padding: 0,
    paddingLeft: '0.5rem',
    '& path[name="unknown"]': {
      stroke: theme.components.graph.stroke,
      fill: theme.components.graph.tray,
    },
  },
  rightContent: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Ensures vertical alignment
    gap: '6.5rem',
  },
  cardsRow: {
    display: 'grid',
    gap: '0',
  },
}))
