import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'pf9-ui-components/built/elements/Text'
import React from 'react'
import UsageProgressBar from './UsageProgressBar'

const UsageProgress = ({ value }) => {
  const classes = useStyles()
  if (!value || value?.current === undefined || value?.total === undefined) return null
  const percent = (value?.current / value?.total) * 100
  const label = `(${value?.current} / ${value?.total})`
  return (
    <div className={classes.cardsRow}>
      <div className={classes.rightContent}>
        <Text variant="caption1" nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
          {percent?.toFixed(2)}%
        </Text>{' '}
        <Text
          className={classes.subText}
          variant="body2"
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          {label}
        </Text>
      </div>
      <UsageProgressBar percent={percent} />
    </div>
  )
}
export default UsageProgress

const useStyles = makeStyles((theme: Theme) => ({
  rightContent: {
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center', // Ensures vertical alignment
  },
  cardsRow: {
    display: 'grid',
    gap: '16px',
  },
  subText: {
    paddingLeft: '8px',
  },
}))
