import { makeStyles } from '@material-ui/styles'
import { listFlavors } from 'app/plugins/openstack/components/flavors/actions'
import { flavorsSelector } from 'app/plugins/openstack/components/flavors/selectors'
import { listNetworks } from 'app/plugins/openstack/components/networks/actions'
import { networksSelector } from 'app/plugins/openstack/components/networks/selectors'
import { listStoragePools } from 'app/plugins/openstack/components/storage/pools/actions'
import { storagePoolsSelector } from 'app/plugins/openstack/components/storage/pools/selectors'
import { RootState, useAppSelector } from 'app/store'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import AddNodeGroups from 'k8s/components/capacity-and-health/AddNodeGroups'
import { NodePoolErrors } from 'k8s/components/cluster/deployment/form-components/ServerConfigurationFields'
import { createKaapiKubeadmConfigTemplateBody } from 'k8s/components/kaapi/kubeadm-config-templates/action-helpers'
import { createKaapiKubeadmConfigTemplate } from 'k8s/components/kaapi/kubeadm-config-templates/actions'
import { createKaapiMachineDeploymentBody } from 'k8s/components/kaapi/machine-deployment/action-helpers'
import {
  createKaapiMachineDeployment,
  listKaapiMachineDeployments,
} from 'k8s/components/kaapi/machine-deployment/actions'
import { createKaapiOpenStackMachineTemplateBody } from 'k8s/components/kaapi/openstack-machine-templates/action-helpers'
import { createKaapiOpenStackMachineTemplate } from 'k8s/components/kaapi/openstack-machine-templates/actions'
import ModalForm from 'pf9-ui-components/built/elements/modal/ModalForm'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import { prop } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { isNilOrEmpty } from 'utils/fp'
import { v4 as uuidv4 } from 'uuid'

const validateNodePools = (nodePools) => {
  const errors = {}
  nodePools.forEach(({ id, flavor, replicas }) => {
    if (!flavor || !replicas) {
      errors[id] = errors[id] || {}
      if (!flavor) {
        errors[id]['flavor'] = 'A VM flavor selection is required.'
      }
      if (!replicas) {
        errors[id]['replicas'] = 'The value must be greater or equal than 1'
      }
    }
  })
  return errors
}

const initialValues = {
  nodePools: [{ id: 1 }],
}

const AddNodeGroupsModal = (props) => {
  const classes = useStyles()
  const { history } = useReactRouter()
  const { params, getParamsUpdater } = useParams<Record<string, any>>(initialValues)

  const { updatePrefs: updateK8sPluginGlobalParams } = useScopedPreferences('k8sPluginGlobalParams')
  const { activeKaapiTenant } = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const namespace = activeKaapiTenant
  const [nodePoolErrors, setNodePoolErrors] = useState<NodePoolErrors>({})
  const [error, setError] = useState(null)

  // Get cluster name from global preferences
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')
  const clusterName: string = useMemo(() => k8sPluginGlobalPerfs?.cluster as string, [
    k8sPluginGlobalPerfs.cluster,
  ])

  // Fetch existing Machine Deployments
  const { reload: reloadMachineDeployments } = useListAction(listKaapiMachineDeployments)

  // VM Flavors
  const { loading: loadingVmFlavors } = useListAction(listFlavors, {
    params,
  })
  const vmFlavors = useAppSelector(flavorsSelector)

  // Storage Pools
  const { loading: loadingStoragePools } = useListAction(listStoragePools)
  const storagePools = useAppSelector(storagePoolsSelector)

  // Networks
  const { loading: loadingNetworks } = useListAction(listNetworks)
  const networks = useAppSelector(networksSelector)

  // Create Actions
  const {
    update: createKubeadmConfigTemplate,
    updating: creatingKubeadmConfigTemplate,
    error: createKubeadmConfigTemplateError,
  } = useUpdateAction(createKaapiKubeadmConfigTemplate)
  const {
    update: createOpenStackMachineTemplate,
    updating: creatingOpenStackMachineTemplate,
    error: createOpenStackMachineTemplateError,
  } = useUpdateAction(createKaapiOpenStackMachineTemplate)
  const {
    update: createMachineDeployment,
    updating: creatingMachindeDeployment,
    error: createMachineDeploymentError,
  } = useUpdateAction(createKaapiMachineDeployment)

  const hasError =
    !!createKubeadmConfigTemplateError ||
    !!createOpenStackMachineTemplateError ||
    !!createMachineDeploymentError

  useEffect(() => {
    if (!hasError) return
    // Show first error
    const error =
      createKubeadmConfigTemplateError ||
      createOpenStackMachineTemplateError ||
      createMachineDeploymentError

    setError({
      title: error?.title || 'Error creating cluster',
      message: error?.message || 'An error occurred while creating the cluster. Please try again.',
    })
  }, [
    hasError,
    createKubeadmConfigTemplateError,
    createOpenStackMachineTemplateError,
    createMachineDeploymentError,
  ])

  const handleSubmit = async () => {
    setError(null)

    // TODO: Before calling the APIs, validate the node pools. Ensure that all fields are filled out
    const nodePoolErrors = validateNodePools(params.nodePools)
    setNodePoolErrors(nodePoolErrors)
    if (!isNilOrEmpty(nodePoolErrors)) return

    // For each nodepool, create KubeadmConfigTemplate, MachineDeployment and OpenStackMachineTemplate
    for (const [idx, nodePool] of params.nodePools.entries()) {
      // Generate a short UUID
      const shortUUID = uuidv4().slice(0, 8)
      const name = `${clusterName}-md-${shortUUID}`

      // Create KubeadmConfigTemplate
      const kubeadmConfigTemplateBody = createKaapiKubeadmConfigTemplateBody({
        name,
        namespace,
      })
      await createKubeadmConfigTemplate({ namespace, body: kubeadmConfigTemplateBody })

      // Create OpenStackMachineTemplate
      const openStackMachineTemplateBody = createKaapiOpenStackMachineTemplateBody({
        name,
        namespace,
        flavor: nodePool.flavor,
        clusterName,
        storage: nodePool.storage,
        network: nodePool.network,
      })
      await createOpenStackMachineTemplate({ namespace, body: openStackMachineTemplateBody })

      // Create MachineDeployment
      const machineDeploymentCreateBody = createKaapiMachineDeploymentBody({
        name,
        namespace,
        clusterName,
        replicas: nodePool.replicas,
        kubeadmConfigTemplateRefName: name,
        openStackMachineTemplateRefName: name,
        k8sVersion: params.k8sVersion,
        autoscaling: nodePool.autoscaling,
      })
      await createMachineDeployment({ namespace, body: machineDeploymentCreateBody })
    }

    if (!hasError) {
      // Reload the machine deployments and machines
      reloadMachineDeployments(true, false)
      props.onClose()
    }
  }

  const submitting =
    creatingKubeadmConfigTemplate || creatingOpenStackMachineTemplate || creatingMachindeDeployment

  const handleNodePoolChange = (id) => (newValues) => {
    const updatedNodePools = params.nodePools.map((nodePool) => {
      if (nodePool.id === id) {
        return { ...nodePool, ...newValues }
      }
      return nodePool
    })
    getParamsUpdater('nodePools')(updatedNodePools)
  }

  return (
    <ModalForm
      {...props}
      open={props.open}
      title="Add Node Group1"
      maxWidth={820}
      className={classes.modal}
      onSubmit={handleSubmit}
      submitTitle="Add Node Group"
      error={error}
      submitting={submitting}
      onClose={props.onClose}
    >
      <AddNodeGroups
        nodePool={params.nodePools[0]}
        onChange={handleNodePoolChange(params.nodePools[0].id)}
        vmFlavors={vmFlavors}
        storagePools={storagePools}
        networks={networks}
        showDeleteButton={false}
        onDeleteNodePool={() => {}}
        errors={nodePoolErrors[params.nodePools[0].id]}
      />
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  modal: {
    '& .modal-body': {
      padding: '32px 24px 24px 32px',
    },
    '& .modal-body .progress-root .progressContent > form': {
      marginBottom: 0,
    },
  },
}))

export default AddNodeGroupsModal
