import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import useReactRouter from 'use-react-router'
import { Popover } from '@material-ui/core'
import { path, prop } from 'ramda'
import { useSelector } from 'react-redux'

import { ICON_STATUS_COLOR_MAP, ICON_STATUS_MAP } from 'app/constants'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import Badge from 'core/elements/badge'
import colors from 'core/themes/base/colors'
import Theme from 'core/themes/model'
import Progress from 'core/components/progress/Progress'

import useListAction from 'core/hooks/useListAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { listKaapiClusters } from '../kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'

import ClusterStatusSpan from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import ExternalLink from 'core/components/ExternalLink'
import { routes } from 'core/utils/routes'
import EllipsisText from 'k8s/components/common/EllipsisText'
import { ClusterUpgradeStatus } from 'k8s/components/kaapi/cluster-upgrade/model'
import IconButton from 'pf9-ui-components/built/elements/button/IconButton'
import {
  getClusterEndpoint,
  isClusterDeleting,
  isKaapiClusterHealthy,
  isTimeExpired,
} from './helpers'
import AvailableUpgrade from 'k8s/components/dashboard/AvailableUpgrade'
import { getNextUpgradeVersion } from 'core/utils/helpers'
import { extractSupportedK8sVersions } from 'k8s/components/cluster/deployment/form-components/helpers'
import { listKaapiConfigMaps } from 'k8s/components/kaapi/config-maps/actions'
import { kaapiConfigMapsSelector } from 'k8s/components/kaapi/config-maps/selectors'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { BadgeVariant } from 'core/elements/badge/Badge'
import { isNilOrEmpty } from 'utils/fp'
import InfoTooltip from 'core/components/InfoTooltip'
import PollingData from 'core/components/PollingData'

interface Props {
  deleteClusterAction: () => void
}

const UpgradeStatus = ({ value, cluster }) => {
  const isClusterHealthy = isKaapiClusterHealthy(cluster)

  const classes = useStyles({ isClusterHealthy })
  const { history } = useReactRouter()
  const { activeKaapiTenant: namespace } = useSelector<RootState, SessionState>(
    prop(sessionStoreKey),
  )
  useListAction(listKaapiConfigMaps, {
    params: {
      namespace,
    },
    requiredParams: ['namespace'],
  })
  const configMaps = useSelector(kaapiConfigMapsSelector)
  const supportedK8sVersions = useMemo(() => extractSupportedK8sVersions(configMaps), [
    configMaps,
  ]).sort()

  const clusterUpgradeJob = (cluster?.upgradeJobs || []).find(
    (job) => job?.clusterName === cluster?.metadata?.name,
  )

  const isTimeWithinTwoDays = useMemo(
    () => isTimeExpired(clusterUpgradeJob?.status?.completionTime),
    [clusterUpgradeJob?.status?.completionTime],
  )

  const clusterUpgradeBadgeMap = {
    [ClusterUpgradeStatus.Completed]: { label: 'Completed', variant: 'success' },
    [ClusterUpgradeStatus.InProgress]: { label: 'In Progress', variant: '' },
    [ClusterUpgradeStatus.Failed]: { label: 'Failed', variant: 'error' },
    [ClusterUpgradeStatus.Unknown]: { label: 'Unknown', variant: 'unknown' },
    Latest: { label: 'Latest stable', variant: 'unknown' },
  }

  const phase = clusterUpgradeJob?.phase || ClusterUpgradeStatus.Unknown
  const currentVersion = value

  const nextUpgradeVersion = useMemo(
    () => getNextUpgradeVersion(currentVersion, supportedK8sVersions),
    [currentVersion, supportedK8sVersions],
  )

  const isUpgradeJobAvailable = !isNilOrEmpty(clusterUpgradeJob)

  const isLatestStable = isUpgradeJobAvailable
    ? currentVersion === nextUpgradeVersion && !isTimeWithinTwoDays
    : currentVersion === nextUpgradeVersion
  const isUpgradeInProgress = phase === ClusterUpgradeStatus.InProgress
  const isUpgradeComplete =
    phase === ClusterUpgradeStatus.Completed &&
    currentVersion === nextUpgradeVersion &&
    isTimeWithinTwoDays
  const isAvailableUpgrade = currentVersion !== nextUpgradeVersion && !isUpgradeInProgress

  const isUpgradeFailed = phase === ClusterUpgradeStatus.Failed
  const isCompletedAndWithhinTwoDays =
    phase === ClusterUpgradeStatus.Completed && isTimeWithinTwoDays
  let content

  if (isUpgradeFailed) {
    content = (
      <>
        <div className={classes.statusContainer}>
          <Text variant="caption1">{value}</Text>
          <Badge
            variant={clusterUpgradeBadgeMap[phase]?.variant}
            text={`Upgrade ${clusterUpgradeBadgeMap[phase]?.label}`}
          />
          <ExternalLink
            url=""
            className={classes.seeDetailsLink}
            onClick={() => {
              history.push(routes.manage.upgradeStatus.path({ cluster: cluster?.metadata?.name }))
            }}
          >
            See Details
          </ExternalLink>
        </div>
        <ExternalLink
          url=""
          className={classes.retryUpgrade}
          onClick={() => {
            history.push(routes.manage.clusterUpgrade.path({ cluster: cluster?.metadata?.name }))
          }}
        >
          <FontAwesomeIcon size="md">refresh</FontAwesomeIcon>
          Retry Upgrade
        </ExternalLink>
      </>
    )
  } else if (isUpgradeComplete) {
    content = (
      <div className={classes.statusContainer}>
        <Text variant="caption1">{value}</Text>
        <Badge
          variant={clusterUpgradeBadgeMap['Completed']?.variant as BadgeVariant}
          text={`Upgrade ${clusterUpgradeBadgeMap['Completed']?.label}`}
        />
        <ExternalLink
          url=""
          className={classes.seeDetailsLink}
          onClick={() => {
            history.push(routes.manage.upgradeStatus.path({ cluster: cluster?.metadata?.name }))
          }}
        >
          See Details
        </ExternalLink>
      </div>
    )
  } else if (isAvailableUpgrade) {
    content = (
      <>
        <div className={classes.statusContainer}>
          <Text variant="caption1">{value}</Text>
          {isCompletedAndWithhinTwoDays && (
            <Badge
              variant={clusterUpgradeBadgeMap['Completed']?.variant as BadgeVariant}
              text={`Upgrade ${clusterUpgradeBadgeMap['Completed']?.label}`}
            />
          )}
          {isCompletedAndWithhinTwoDays && (
            <ExternalLink
              url=""
              className={classes.seeDetailsLink}
              onClick={() => {
                history.push(routes.manage.upgradeStatus.path({ cluster: cluster?.metadata?.name }))
              }}
            >
              See Details
            </ExternalLink>
          )}
          {!isCompletedAndWithhinTwoDays &&
            (!isClusterHealthy ? (
              <InfoTooltip info="Upgrades can only be performed when cluster status is healthy.">
                <AvailableUpgrade className={classes.disableSelection} cluster={cluster} />
              </InfoTooltip>
            ) : (
              <AvailableUpgrade className={classes.availableUpgrade} cluster={cluster} />
            ))}
        </div>
        {isUpgradeJobAvailable && (
          <AvailableUpgrade className={classes.availableUpgrade} cluster={cluster} />
        )}
      </>
    )
  } else if (isLatestStable) {
    content = (
      <div className={classes.cardContent}>
        <Text variant="caption1">{value}</Text>
        <Badge
          variant={clusterUpgradeBadgeMap['Latest']?.variant as BadgeVariant}
          text={`${clusterUpgradeBadgeMap['Latest']?.label}`}
          className={classes.latestBadge}
        />
      </div>
    )
  } else if (isUpgradeInProgress) {
    content = (
      <div className={classes.statusContainer}>
        <Text variant="caption1">{value}</Text>
        <ClusterStatusSpan iconStatus status="loading">
          <Text variant="caption1">Upgrade In Progress</Text>
        </ClusterStatusSpan>
        <ExternalLink
          url=""
          className={classes.seeDetailsLink}
          onClick={() => {
            history.push(routes.manage.upgradeStatus.path({ cluster: cluster?.metadata?.name }))
          }}
        >
          See Details
        </ExternalLink>
      </div>
    )
  } else {
    content = <Text variant="caption1">{value}</Text>
  }

  return <>{content}</>
}

const K8sVersion = ({ value, cluster }) => {
  return <UpgradeStatus value={value} cluster={cluster} />
}

const ClusterOverview = ({ deleteClusterAction }: Props) => {
  const classes = useStyles({})
  const { history } = useReactRouter()

  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')
  const { loading: loadingKaapiClusters, reload: reloadKaapiClusters } = useListAction(
    listKaapiClusters,
  )
  const clusters = useSelector(kaapiClustersOverviewSelector)

  const [openSettingPopover, setOpenSettingPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const popoverRef = React.useRef(null)

  const handleSettingsClick = useCallback((event) => {
    setOpenSettingPopover(true)
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setOpenSettingPopover(false)
    setAnchorEl(null)
  }, [])

  const handleDeleteCluster = useCallback(() => {
    deleteClusterAction()
    handleClose()
  }, [deleteClusterAction, handleClose])

  // Fetch the list of clusters and filter the one that is currently selected
  const cluster = clusters?.find(
    (cluster) => cluster.metadata.name === k8sPluginGlobalPerfs?.cluster,
  )

  const clusterUpgradeJob = (cluster?.upgradeJobs || []).find(
    (job) => job.clusterName === cluster.metadata.name,
  )

  const overviewBody = [
    {
      label: 'CONTROL PLANE',
      key: 'spec.controlPlaneRef.kind',
      defaultValue: 'Hosted Control Plane',
      badge: '',
    },
    {
      label: 'CONTROL PLANE CLASS',
      key: 'hostedControlPlane.spec.hcpClass',
      defaultValue: 'Highly Available - 3 Masters',
      badge: '',
    },
    {
      label: 'API ENDPOINT',
      key: 'spec.controlPlaneEndpoint.host',
      defaultValue: 'cluster.x-k8s.io/v1beta1',
      badge: '',
    },
    {
      label: 'INFRASTRUCTURE PROVIDER',
      // key: 'spec.infrastructureRef.kind',
      key: 'spec.infrastructureRef.dummy',
      labelsTooltipDescription: 'INFRASTRUCTURE PROVIDER',
      defaultValue: 'PCD Virtualization',
      badge: '',
    },
    {
      label: 'K8S VERSION',
      key: 'hostedControlPlane.spec.version',
      defaultValue: 'v1.29.5',
      badge: '',
      Component: (value, cluster) => (
        <K8sVersion
          value={
            clusterUpgradeJob?.spec?.sourceVersion || cluster?.hostedControlPlane?.spec?.version
          }
          cluster={cluster}
        />
      ),
    },
    {
      label: 'MANAGEMENT CLUSTER NAMESPACE',
      key: 'metadata.namespace',
      defaultValue: 'default',
      badge: '',
    },
  ]

  const isClusterHealthy = isKaapiClusterHealthy(cluster)

  return (
    <>
      <PollingData
        hidden
        loading={loadingKaapiClusters}
        onReload={reloadKaapiClusters}
        refreshDuration={1000 * 30}
      />
      <Progress loading={loadingKaapiClusters}>
        {cluster ? (
          <div className={classes.cardContainer}>
            <div className={classes.cardContent}>
              <div className={classes.badge}>
                <FontAwesomeIcon light size="lg" className="fa-regular">
                  server
                </FontAwesomeIcon>
              </div>
              <Text variant="caption1" className={clsx(classes.headerText, classes.headerSubText)}>
                CONFIGURATION OVERVIEW
              </Text>
              {/* Cluster Settings */}
              <IconButton
                size="md"
                icon="cog"
                solid={false}
                className={classes.settingsMenuButton}
                onClick={handleSettingsClick}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
              />
              {/* Settings Menu */}
              <Popover
                open={openSettingPopover}
                onClose={handleClose}
                anchorEl={anchorEl}
                ref={popoverRef}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className={classes.settingsMenuContainer}>
                  <Text
                    variant="body2"
                    className={clsx(classes.settingsMenuItem)}
                    onClick={() => {}}
                  >
                    <FontAwesomeIcon size="md">edit</FontAwesomeIcon>
                    Edit
                  </Text>
                  <Text
                    variant="body2"
                    className={clsx(classes.settingsMenuItem)}
                    onClick={handleDeleteCluster}
                  >
                    <FontAwesomeIcon size="md">trash-can</FontAwesomeIcon>
                    Delete
                  </Text>
                </div>
              </Popover>
            </div>
            <div className={clsx(classes.cardContent, classes.content)}>
              <EllipsisText
                variant="subtitle1"
                title={cluster?.metadata?.name}
                maxWidth={230}
                showTooltip
              >
                {cluster?.metadata?.name}
              </EllipsisText>
              <div className={classes.headerSubText}>
                <Badge
                  data-testid={'overview-tab-emp-status'}
                  variant={isClusterHealthy && !isClusterDeleting(cluster) ? 'success' : 'error'}
                  text={
                    isClusterDeleting(cluster)
                      ? 'Deleting'
                      : isClusterHealthy
                      ? 'Healthy'
                      : 'Unhealthy'
                  }
                  ellipsisAt={20}
                  // tooltipBody={cluster?.status?.phase}
                />
              </div>
            </div>
            <div className={classes.cardsRow}>
              {overviewBody.map(({ key, label, defaultValue, badge, Component }, index) => {
                const value = path(key.split('.'), cluster) || defaultValue

                return (
                  <div key={`key-${index}`}>
                    <Text variant="body2" className={clsx(classes.headerText, classes.customText)}>
                      {label}
                    </Text>
                    {Component ? (
                      Component(value, cluster)
                    ) : (
                      <div className={classes.cardContent}>
                        <Text variant="caption1">
                          {label === 'API ENDPOINT' ? getClusterEndpoint(value) : value}
                        </Text>
                        {badge && (
                          <div className={classes.headerSubText}>
                            <Badge
                              data-testid={'overview-tab-emp-status'}
                              variant={ICON_STATUS_COLOR_MAP[badge] || 'unknown'}
                              text={ICON_STATUS_MAP[badge]}
                              ellipsisAt={20}
                              tooltipBody={badge?.length > 20 && ICON_STATUS_MAP[badge]}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}
      </Progress>
    </>
  )
}

export default ClusterOverview

const useStyles = makeStyles((theme: Theme) => ({
  cardsRow: {
    display: 'grid',
    marginTop: '8px',
    gridColumnGap: '32px',
    gridRowGap: '24px',
    alignItems: 'center',
    paddingTop: '48px',
  },
  cardContainer: {
    backgroundColor: theme.components.card.background,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px 4px 4px 4px',
    padding: '24px',
  },
  headerText: {
    color: theme.components.card.passiveText,
  },
  customText: {
    fontSize: '12px',
    paddingBottom: '8px',
  },
  headerSubText: {
    paddingLeft: '8px',
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    backgroundColor: colors.blue[900],
    borderRadius: '4px 4px 4px 4px',
    padding: '3px',
    '& i': {
      color: 'white',
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    paddingTop: '16px',
  },
  settingsMenuButton: {
    marginLeft: 'auto',
  },
  settingsMenuContainer: {
    padding: '8px 0',
    width: '154px',
  },
  settingsMenuItem: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    alignItems: 'center',
    gap: 6,
    padding: '8px 16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  availableUpgrade: {
    textAlign: 'center',
  },
  seeDetailsLink: {
    '& span': {
      fontWeight: 600,
    },
  },
  retryUpgrade: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '8px',
    '& i': {
      marginRight: '4px',
      color: theme.palette.primary.main,
    },
    '& span': {
      fontWeight: 600,
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  latestBadge: {
    marginLeft: '16px',
  },
  disableSelection: {
    cursor: 'not-allowed',
    '& > a': {
      textDecoration: 'none',
      pointerEvents: 'none',
      '& > span': {
        textDecoration: 'none',
        color: theme.palette.grey[300],
      },
    },
  },
}))
